import { makeStyles } from "@kuva/ui-components";

export const useColumMenuStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(0.5),
    "& .MuiPopover-paper": {
      background: "#222",
      border: "0.5px solid #fff"
    }
  }
}));

export const useUnitsOptionStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    background: "#222",
    paddingBottom: spacing(1.5),
    width: "174px",
    "& .MuiAccordionDetails-root": {
      display: "inherit",
      padding: "0px 0px"
    },
    "& .MuiButtonBase-root": {
      justifyContent: "start"
    },
    "& .MuiIconButton-edgeEnd": {
      marginLeft: "-14px"
    },
    ".MuiAccordionSummary-root.Mui-expanded": {
      minHeigth: "unset"
    },
    "& .MuiTypography-body1": {
      fontSize: "14px"
    },
    "& .MuiFormControlLabel-root": {
      height: "36px"
    }
  },
  accordion: {
    background: "inherit"
  },
  accordionSummary: {
    minHeight: "20px",
    maxHeight: "20px",
    "& .MuiAccordionSummary-content": {
      flexGrow: "unset"
    },
    "&.Mui-expanded": {
      minHeight: "20px",
      maxHeight: "20px"
    }
  },

  heading: {
    width: "max-content",
    fontSize: "14px",
    marginLeft: "-16px",
    flexShrink: 0
  },
  formControlLabel: {
    fontSize: "14px",
    "& .Mui-checked:not(.Mui-disabled) > svg": {
      fill: palette.common.white
    }
  },
  checkbox: {
    transform: "scale(0.7)"
  }
}));

export const useVisibilityOptionStyles = makeStyles()(({ spacing }) => ({
  root: {
    background: "#222",
    marginBottom: spacing(2),
    "& .MuiAccordionDetails-root": {
      display: "inherit",
      padding: "0px 0px"
    },
    "& .MuiButtonBase-root": {
      justifyContent: "start"
    },
    "& .MuiIconButton-edgeEnd": {
      marginLeft: "-14px"
    },
    ".MuiAccordionSummary-root.Mui-expanded": {
      minHeigth: "unset"
    }
  },
  accordion: {
    background: "inherit",
    paddingTop: spacing(2.5)
  },
  accordionSummary: {
    minHeight: "20px",
    maxHeight: "20px",
    "& .MuiAccordionSummary-content": {
      flexGrow: "unset"
    },
    "&.Mui-expanded": {
      minHeight: "20px",
      maxHeight: "20px"
    }
  },
  heading: {
    fontSize: "14px",
    marginLeft: "-16px",
    flexShrink: 0
  },
  actionText: {
    color: "#a6a6a6",
    cursor: "pointer",
    fontSize: "12px",
    textAlign: "center",
    marginRight: spacing(3.5)
  },
  arrowButtonsContainer: {
    marginRight: spacing(3.5),
    display: "flex",
    justifyContent: "center"
  },
  defaultArrowIcon: {
    cursor: "pointer",
    width: "24px",
    height: "24px",
    transform: "rotate(90deg)",
    padding: spacing(0.5)
  },
  arrowDisabled: {
    width: "24px",
    height: "24px",
    transform: "rotate(-90deg)",
    padding: spacing(0.5)
  }
}));
