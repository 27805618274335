import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(({ spacing }) => ({
  cameras: {
    maxHeight: "70vh",
    overflowY: "scroll",
    marginBottom: spacing(1.25),
    marginRight: spacing(-1.25)
  }
}));

export { useStyles };
