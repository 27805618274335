import { Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

import {
  Asterisk,
  LabelWrapper,
  StyledBox,
  StyledErrorMessage
} from "../styled-components";

const StyledTextField = styled(TextField)(
  ({ theme: { spacing, palette }, error, disabled, width }) => ({
    "& .MuiInputBase-root": {
      width,
      paddingTop: "unset"
    },
    "& input": {
      color: disabled ? palette.text.secondary : "white"
    },
    "& .MuiOutlinedInput-root": {
      height: 40,
      background: disabled ? "#424242" : "transparent",
      "& fieldset": {
        border: error
          ? `1px solid ${palette.custom.orange[400]}`
          : `1px solid ${palette.text.secondary}`
      },
      "&:hover fieldset": {
        border: "1px solid white"
      },
      "&.Mui-focused fieldset": {
        border: error
          ? `1px solid ${palette.custom.orange[400]}`
          : "1px solid white"
      },
      "&.Mui-error.MuiOutlinedInput-root": {
        border: `1px solid ${palette.custom.orange[400]}`
      }
    },
    "& .MuiFormHelperText-root": {
      marginTop: spacing(1)
    }
  })
);

export const LabelAutocomplete = ({
  title,
  required = false,
  name,
  formik,
  disabled = false,
  width = 260,
  labelWidth = "100%",
  rootWidth,
  fontSize = 14,
  options = [],
  ...props
}) => {
  const handleChange = (_event, value) => {
    formik.setFieldValue(name, value);
  };

  const hasError = formik.touched[name] && Boolean(formik.errors[name]);

  return (
    <StyledBox width={rootWidth}>
      <LabelWrapper variant="h4" fontSize={fontSize} width={labelWidth}>
        {title}
        {required && <Asterisk>*</Asterisk>}
      </LabelWrapper>

      <Autocomplete
        sx={{ width: "100%" }}
        options={options}
        getOptionLabel={option => option.label || option}
        onChange={handleChange}
        value={formik.values[name]}
        onBlur={formik.handleBlur}
        disabled={disabled}
        renderInput={params => (
          <StyledTextField
            {...params}
            name={name}
            variant="outlined"
            width={width}
            error={hasError}
            helperText={
              hasError ? (
                <StyledErrorMessage>
                  {formik.touched[name] && formik.errors[name]}
                </StyledErrorMessage>
              ) : (
                ""
              )
            }
          />
        )}
        {...props}
      />
    </StyledBox>
  );
};

LabelAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  formik: PropTypes.object,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rootWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default LabelAutocomplete;
