import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: { error: false },
  eventTable: {
    loading: false,
    error: { error: false },
    columns: [],
    units: "imperial",
    sortSetting: {
      columnToSort: "start",
      sortDirection: "desc"
    },
    selectedCameras: {}
  }
};

const slice = createSlice({
  name: "Event",
  initialState,
  reducers: {
    setTableColums: (state, action) => {
      state.eventTable.columns = action.payload;
    },
    setSortSetting: (state, action) => {
      state.eventTable.sortSetting = action.payload;
    },
    setSelectedCameras: (state, action) => {
      state.eventTable.selectedCameras = {
        ...state.eventTable.selectedCameras,
        ...action.payload
      };
    }
  }
});

export const EventsPageActions = {
  ...slice.actions
};

export const EventsPageReducer = slice.reducer;
