import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";

import { MenuContainer } from "../styled-components";

import { useColumMenuStyles } from "./styles";

export const ColumnMenu = ({ children, ...props }) => {
  const { classes } = useColumMenuStyles();
  return (
    <Popover
      className={classes.root}
      {...props}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <MenuContainer>{children}</MenuContainer>
    </Popover>
  );
};

ColumnMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)
  ])
};
