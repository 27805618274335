import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(({ spacing }) => ({
  root: {
    background: "#222",
    width: "280px",
    "& .MuiAccordionDetails-root": {
      display: "inherit",
      padding: "0px 0px"
    },
    "& .MuiButtonBase-root": {
      justifyContent: "start"
    },
    "& .MuiIconButton-edgeEnd": {
      marginLeft: "-14px"
    },
    ".MuiAccordionSummary-root.Mui-expanded": {
      minHeigth: "unset"
    }
  },
  accordion: {
    background: "inherit"
  },
  accordionSummary: {
    minHeight: "20px",
    maxHeight: "20px",
    "& .MuiAccordionSummary-content": {
      flexGrow: "unset"
    },
    "&.Mui-expanded": {
      minHeight: "20px",
      maxHeight: "20px"
    }
  },
  heading: {
    fontSize: "14px",
    marginLeft: "-16px",
    flexShrink: 0
  },
  actionText: {
    fontSize: "12px",
    color: "#a6a6a6"
  },
  searchBar: {
    width: "auto",
    marginTop: "5px",
    marginBottom: "12px"
  }
}));

export { useStyles };
