import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

export const getEmailNotificationSubscribersByOrg = createAsyncThunk(
  "alarms/getEmailNotificationSubscribersByOrg",
  async orgId => {
    const { data } = await AlarmAPI.getEmailNotificationSubscribersByOrg(orgId);

    return data.find(config => config.subjectId === orgId);
  }
);
