import {
  findOrganizationAndDescendant,
  getFlattenOrgIds
} from "~/utils/organization";

export function getLatestAlarmsByDeviceId(latestAlarms) {
  return Object.values(latestAlarms).reduce((acc, alarm) => {
    const { deviceId, start } = alarm;
    if (!acc[deviceId] || start > acc[deviceId].start) {
      acc[deviceId] = alarm;
    }
    return acc;
  }, {});
}

export function filterCamerasByOrganization(cameras, organizations, orgId) {
  const organizationWithDescendant = findOrganizationAndDescendant(
    organizations,
    orgId
  );

  const orgIds = getFlattenOrgIds(organizationWithDescendant);

  const selectedOrgCameras = cameras.filter(camera => {
    if (!orgId) return true;
    return orgIds.includes(camera.orgID);
  });

  return selectedOrgCameras;
}

export function updatedCameraWithSerialNumber(state, payload) {
  const { deviceId, serialNumber = "" } = payload;
  const cameraToUpdate = state.cameras.find(
    camera => camera.deviceId === deviceId
  );

  if (!cameraToUpdate) return;

  const updatedCamera = {
    ...cameraToUpdate,
    serialNumber
  };
  const updatedNormalizedCameras = {
    ...state.normalizedCameras,
    [deviceId]: {
      ...state.normalizedCameras[deviceId],
      serialNumber
    }
  };

  if (serialNumber === "") {
    delete updatedCamera.serialNumber;
    delete updatedNormalizedCameras[deviceId].serialNumber;
  }

  return {
    ...state,
    cameras: state.cameras.map(camera =>
      camera === cameraToUpdate ? updatedCamera : camera
    ),
    normalizedCameras: updatedNormalizedCameras
  };
}
