import {
  MET_PER_SEC_TO_KMH,
  MET_PER_SEC_TO_MPH,
  MET_PER_SEC_TO_MPS
} from "@kuva/units";

export const metPerSecToMph = (value, round = false) => {
  const result = value * MET_PER_SEC_TO_MPH;
  return round ? Math.round(result * 10) / 10 : result;
};

export const metPerSecToKmh = (value, round = false) => {
  const result = value * MET_PER_SEC_TO_KMH;
  return round ? Math.round(result * 10) / 10 : result;
};

export const metPerSecToMps = (value, round = false) => {
  const result = value * MET_PER_SEC_TO_MPS;
  return round ? Math.round(result * 10) / 10 : result;
};

export const formatValue = (value, defaultValue = "N/A") => {
  if (isNaN(value) || value < 0) {
    return defaultValue;
  }
  return value;
};

export const setDefaultUnits = (value, unit = "", defaultValue = "N/A") => {
  if (isNaN(value) || value < 0 || value === undefined) {
    return defaultValue;
  }
  return `${value} ${unit}`;
};

export const calculateVolume = (duration, avgRate, avgRateUnits) => {
  switch (avgRateUnits) {
    case "SCFH":
      return (avgRate * duration) / 60; // SCFH * hours
    case "m3/d":
      return (avgRate * duration) / 1440; // m3/d * days
    default:
      return null;
  }
};
