import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    "& .MuiTypography-body1": {
      fontSize: "14px"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: palette.common.white
    },
    "& .MuiFormControlLabel-root": {
      height: "36px"
    },
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: palette.common.white
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: palette.action.disabled
    },
    "& .Mui-checked:not(.Mui-disabled) > svg": {
      fill: palette.common.white
    }
  },
  checkbox: {
    transform: "scale(0.7)"
  },
  activeControl: {
    float: "right",
    verticalAlign: "middle",
    margin: "5px 3px",
    cursor: "pointer"
  },
  inactiveControl: {
    float: "right",
    verticalAlign: "middle",
    margin: "5px 3px"
  }
}));

export { useStyles };
