import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

/**
 * @param {string} deviceId
 * @param {number} alarmId
 */
export const getActiveAlarm = createAsyncThunk(
  "alarms/getActiveAlarm",
  async ({ deviceId, alarmId }) => {
    const { data } = await AlarmAPI.get(deviceId, alarmId);
    return data;
  }
);
