import PropTypes from "prop-types";
import MaterialToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { styled } from "@kuva/ui-components";

const getCustomTheme = theme => {
  switch (theme) {
    case "primary":
      return {
        toggleButtonSelected: {
          color: "#FFFFFF",
          backgroundColor: "#ce6641"
        }
      };

    case "secondary":
      return {
        toggleButtonSelected: {
          color: "#FFFFFF",
          backgroundColor: "#333"
        }
      };

    default:
      return {
        toggleButtonSelected: {
          color: "#FFFFFF",
          backgroundColor: "#222"
        }
      };
  }
};

const CustomToggleButtonGroup = styled(MaterialToggleButtonGroup)(
  ({ customTheme, textTransform = "capitalize" }) => ({
    "& .MuiToggleButton-root": {
      color: "#a6a6a6",
      borderColor: "#222",
      height: "36px",
      padding: "0 10px 0 10px",
      textTransform
    },
    "& .MuiToggleButton-root.Mui-selected": {
      ...getCustomTheme(customTheme).toggleButtonSelected
    }
  })
);

export const ToggleButtonGroup = ({
  children,
  value,
  onToggleChange,
  customTheme,
  ...props
}) => {
  return (
    <CustomToggleButtonGroup
      value={value}
      exclusive
      onChange={onToggleChange}
      aria-label="text alignment"
      customTheme={customTheme}
      {...props}
    >
      {children}
    </CustomToggleButtonGroup>
  );
};

ToggleButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  customTheme: PropTypes.object,
  onToggleChange: PropTypes.func,
  textTransform: PropTypes.oneOf(["default", "customTheme"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
