import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import {
  dateDisplays,
  DateTime,
  KuvaHalo,
  makeStyles
} from "@kuva/ui-components";

import { CustomFieldContainer } from "../styled-components";

const useStyles = makeStyles()(({ palette, spacing }) => ({
  icons: {
    margin: "6.5px 5px 0px 0px"
  },
  textBox: { textTransform: "uppercase" },
  hasReportProblemOutlinedIcon: {
    color: palette.secondary.main,
    paddingRight: spacing(1)
  }
}));

export const CustomFieldWithStatus = ({
  displayUtc,
  text = "",
  format = "MMMM d yyyy, h:mm:ss a",
  isConnected,
  isDateTime,
  camera,
  uppercase = false,
  hasWarningIcon = false,
  capabilityClass,
  isImgPicQualityFeatureOn,
  isScanning,
  ...props
}) => {
  const { classes } = useStyles();
  let fieldValue = text;
  if (isDateTime) fieldValue = new Date(text);

  return (
    <CustomFieldContainer
      className={hasWarningIcon && classes.hasReportProblemOutlinedIcon}
      {...props}
    >
      {!isNil(isConnected) && text && (
        <KuvaHalo
          isConnected={isConnected}
          isError={camera?.isError}
          isArchived={camera?.archived}
          capabilityClass={capabilityClass}
          isImgPicQualityFeatureOn={isImgPicQualityFeatureOn}
          isScanning={isScanning}
        />
      )}
      {hasWarningIcon && text && (
        <ReportProblemOutlinedIcon
          fontSize={"medium"}
          className={classes.hasReportProblemOutlinedIcon}
        />
      )}
      <div className={uppercase && classes.textBox}>
        {isDateTime && !isNaN(fieldValue) ? (
          <DateTime
            date={fieldValue}
            display={displayUtc ? dateDisplays.UTC : dateDisplays.USER}
            timezone={camera?.timezone}
            format={format}
          />
        ) : (
          <>{!text ? "N/A" : text}</>
        )}
      </div>
    </CustomFieldContainer>
  );
};

CustomFieldWithStatus.propTypes = {
  displayUtc: PropTypes.bool,
  text: PropTypes.string,
  format: PropTypes.string,
  isConnected: PropTypes.bool,
  isDateTime: PropTypes.bool,
  camera: PropTypes.object,
  uppercase: PropTypes.bool,
  hasWarningIcon: PropTypes.bool,
  capabilityClass: PropTypes.number,
  isImgPicQualityFeatureOn: PropTypes.bool,
  isScanning: PropTypes.bool
};
