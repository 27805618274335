import Box from "@mui/material/Box";

export const ArchivedIcon = props => {
  return (
    <Box {...props}>
      <svg
        data-testid="archived-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path
          d="M13,16V8H15V16H13M9,16V8H11V16H9M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z"
          fill="#A6A6A6"
        />
      </svg>
    </Box>
  );
};
