import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const getStreams = createAsyncThunk(
  "cameras/getStreams",
  async ({ orgId, showArchived = false }) => {
    const response = await CameraAPI.getStreams({ orgId, showArchived });

    return { streams: response.data };
  }
);
