// This function returns an object and adds keys, startDateTimes, and endDateTimes.
export const prepareNotificationObject = ({ notifications, state }) => {
  let newUserNotifications = { ...state };
  notifications.forEach(item => {
    const key = `${item?.id}-${item?.updatedAt}`;

    const startDate = new Date(item?.startDate?.replace(/-/g, "/"));
    const endDate = new Date(item?.endDate?.replace(/-/g, "/"));

    const startTime = item?.startTime?.split(":");
    const startDateTime = startDate.setHours(startTime[0], startTime[1]);

    const endTime = item?.endTime?.split(":");
    const endDateTime = endDate.setHours(endTime[0], endTime[1]);

    const notif = {
      key,
      ...item,
      startDateTime: new Date(startDateTime),
      endDateTime: new Date(endDateTime),
      isRead: false
    };
    newUserNotifications = { [key]: { ...notif }, ...newUserNotifications };
  });
  return newUserNotifications;
};

export const notExpired = (start, end, today) => {
  const startDateTime = new Date(start);
  const endDateTime = new Date(end);
  return startDateTime <= new Date(today) && endDateTime >= new Date(today);
};

export const getActiveNotification = (notifications, today = new Date()) => {
  let notif = {};

  const keys = Object.keys(notifications);
  // iterate over object
  keys.forEach(key => {
    if (
      notifications[key].isRead === false &&
      notExpired(
        notifications[key].startDateTime,
        notifications[key].endDateTime,
        today
      )
    ) {
      notif = notifications[key];
      return;
    }
  });
  return notif;
};
