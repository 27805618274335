import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

const thunk = async ({ year, month, ...rest }) => {
  const { data } = await AlarmAPI.getAlarmsPerMonth({ year, month, ...rest });
  return { ...data, year, month };
};

export const getAlarmsByMonth = createAsyncThunk(
  "alarms/getAlarmsByMonth",
  thunk
);
