import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { flags } from "~/constants/feature-flags";
import {
  getNormalizeLatestAlarm,
  selectIsLoadingCameraEvents
} from "~/selectors/CameraSelector";
import { CameraActions } from "~/store/slices/cameras";

import { useOrganization } from "./use-organization";

export const useCameraWithLatestEvent = () => {
  const streamIdIsEnabled = useFeatureIsOn(flags.STREAM_ID_MANAGEMENT_TAB);

  const dispatch = useDispatch();
  const { selectedOrg } = useOrganization();

  const latestAlarms = useSelector(getNormalizeLatestAlarm, shallowEqual);
  const isLoadingCameraEvents = useSelector(selectIsLoadingCameraEvents);

  const orgId = selectedOrg?.id;

  const action = streamIdIsEnabled
    ? CameraActions.getLatestAlarmsPerStream
    : CameraActions.getLatestAlarmsPerDevice;

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(
          action({
            orgId
          })
        ).unwrap();
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return {
    latestAlarms,
    isLoadingCameraEvents
  };
};
