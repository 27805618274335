import { uniqueId } from "lodash";
import { useState } from "react";

export const usePopover = idPrefix => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? uniqueId(`${idPrefix}-`) : undefined;

  const onTriggerClick = event => setAnchorEl(event.currentTarget);
  const closePopover = () => setAnchorEl(null);

  return {
    anchorEl,
    open,
    id,
    onTriggerClick,
    closePopover
  };
};
