import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { AscIcon, DescIcon } from "./SvgIcons";

export const TableHead = props => {
  const { order, orderBy, onRequestSort, columns } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {columns?.map(headCell => {
          return headCell?.columnVisibility ? (
            <TableCell
              key={headCell?.field}
              align={"left"}
              sortDirection={orderBy === headCell?.field ? order : false}
            >
              {headCell?.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell?.field}
                  direction={orderBy === headCell?.field ? order : "asc"}
                  onClick={createSortHandler(headCell?.field)}
                  IconComponent={order === "asc" ? AscIcon : DescIcon}
                  hideSortIcon={false}
                  style={{ flexDirection: "row-reverse" }}
                >
                  {headCell?.label}
                </TableSortLabel>
              ) : (
                <span>{headCell?.label}</span>
              )}
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool
    })
  ).isRequired
};
