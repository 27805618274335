import { makeStyles, styled } from "@kuva/ui-components";

export const StyledBlockquote = styled("blockquote")({
  position: "absolute",
  zIndex: 99,
  "-webkit-font-smoothing:": "antialiased",
  color: "#fff",
  fontSize: "1rem",
  wordBreak: "break-word",
  fontFamily: '"Roboto, "Helvetica", "Arial", "sans-serif"',
  fontWeight: 400,
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
  boxSizing: "inherit",
  margin: "24px 0",
  padding: "4px 24px",
  borderLeft: "5px solid #ffe564",
  backgroundColor: "rgba(255,229,100,0.2)",
  left: 0,
  bottom: 0
});

export const GlobalStyle = makeStyles()(({ spacing }) => ({
  font12: {
    fontSize: 12
  },
  textField: {
    marginTop: "1%",
    width: "100%",
    height: "5%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
    "& .MuiFormHelperText-root": {
      fontSize: 11,
      color: "white"
    }
  },
  preferencesInput: {
    width: "30%",
    height: 35,
    marginTop: 5,
    display: "flex",
    justifySelf: "end"
  },
  preferencesDisplay: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 15,
    paddingTop: 10
  },
  preferencesDisplayUnits: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10
  },
  scheduleField: {
    display: "flex",
    marginTop: "20px",
    minWidth: "50%",
    maxWidth: "75%",
    alignItems: "center",
    fontSize: 14,
    "& input": {
      textAlign: "right",
      paddingRight: 10
    }
  },
  noEventDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    height: 150,
    width: 200,
    opacity: ".33"
  },
  containerInputLabel: {
    marginLeft: "0.5%",
    marginTop: "-2.7%",
    fontSize: "13px"
  },
  navLinkDivSecond: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: spacing(1)
  },
  navLink: {
    textTransform: "none",
    fontSize: 14,
    color: "white",
    width: "auto",
    fontWeight: 500,
    padding: spacing(1, 2),
    "& svg:first-of-type": {
      fontSize: 12
    }
  },
  navLinkNext: {
    "& svg": {
      position: "relative",
      left: 5
    }
  }
}));
