import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

const thunk = async payload => {
  const { deviceId, start, end, max } = payload;

  return await CameraAPI.getFrames(deviceId, start, end, max);
};

export const getCameraScans = createAsyncThunk("cameras/getCameraScans", thunk);
