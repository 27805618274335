import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3)
    },
    "& .MuiChip-deleteIcon": {
      color: "#FF4500"
    },
    "& .MuiChip-deleteIcon:hover": {
      color: "#FFF"
    }
  }
}));

export { useStyles };
