import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    alignItems: "center",
    backgroundColor: "#000",
    borderRadius: "4px",
    boxShadow:
      "0 12px 24px -6px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.08)",
    color: "#fff",
    display: "inline-grid",
    fontSize: "14px",
    gap: "8px",
    gridTemplateColumns: "auto auto",
    padding: "4px 8px",
    pointerEvents: "none",
    opacity: 0.8
  },
  icon: {
    alignItems: "center",
    display: "flex"
  },
  label: {
    alignItems: "center",
    display: "flex",
    color: "#ff5100"
  },
  avatar: {
    height: 30,
    width: 30,
    margin: "2px"
  }
});

export { useStyles };
