import PropTypes from "prop-types";

import { PopoverMenu } from "@kuva/ui-components";

import { MenuContainer } from "../styled-components";

export const TableMenu = ({ children, ...props }) => {
  return (
    <PopoverMenu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      {...props}
    >
      <MenuContainer isTableMenu={true}>{children}</MenuContainer>
    </PopoverMenu>
  );
};

TableMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};
