import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

export const getAlarmById = createAsyncThunk(
  "alarms/getAlarmById",
  async ({ deviceId, alarmId }, { rejectWithValue }) => {
    try {
      const { data } = await AlarmAPI.get(deviceId, alarmId);

      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
