import Box from "@mui/material/Box";
import PropTypes from "prop-types";

export const TrashCanIcon = props => {
  return (
    <Box {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
          fill={props.color ?? "#A6A6A6"}
        />
      </svg>
    </Box>
  );
};

TrashCanIcon.propTypes = {
  color: PropTypes.string
};
