import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { makeStyles } from "@kuva/ui-components";

import { WarningIcon } from "~/components/Table/SvgIcons";

import { CustomFieldContainer } from "../styled-components";

const useStyles = makeStyles()({
  icons: {
    margin: "6.5px 5px 0px 0px"
  }
});

export const VoltsAvgField = ({ voltsAvg, unit, ...props }) => {
  const { classes } = useStyles();
  const VOLTAGE_THRESHOLD = 20.5;

  const volts = voltsAvg ?? 0;
  const formattedNumber = volts !== 0 ? volts.toFixed(1) : volts;

  const checkThreshold = () => {
    // error state when less than 20.5V
    if (volts < VOLTAGE_THRESHOLD) {
      return "error";
    }
    return "";
  };

  let status = checkThreshold();

  const ErrorIcon = {
    error: <WarningIcon className={classes.icons} />
  };
  return (
    <CustomFieldContainer status={status} {...props}>
      {ErrorIcon[status]}
      <Box>
        {formattedNumber} {unit}
      </Box>
    </CustomFieldContainer>
  );
};

VoltsAvgField.propTypes = {
  voltsAvg: PropTypes.number,
  status: PropTypes.string,
  unit: PropTypes.string
};
