import PropTypes from "prop-types";

import { useStyles } from "./styles";

export const RadiantProgress = ({ value = 0 }) => {
  const progress = (value / 20) * 100;
  const classes = useStyles(Math.min(progress, 100));
  return (
    <div data-cy="progress" className={classes.progress}>
      <div />
    </div>
  );
};

RadiantProgress.propTypes = {
  value: PropTypes.number
};

export default RadiantProgress;
