import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    "& .MuiButton-outlined.Mui-disabled": {
      border: "1px solid #696969",
      color: "#696969"
    }
  },
  textField: {
    minWidth: "316px",
    fontSize: "16px",
    label: {
      fontSize: "16px"
    },
    margin: "10px 0 0 0"
  },
  btnSubmit: {
    minWidth: "316px",
    minHeight: "38px",
    margin: "10px 0 0 0"
  }
});

export { useStyles };
