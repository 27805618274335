import { useAuth0 } from "@auth0/auth0-react";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { ConfirmProvider } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import { buildAbilityFor } from "~/configuration/ability";
import { AbilityContext } from "~/contextProviders/Can";
import { useOrganization } from "~/hooks";
import EmailVerification from "~/pages/EmailVerification";
import Landing from "~/pages/landing/Landing";

import { StyledBlockquote } from "../GlobalStyle";

import AppInitialize from "./AppInitialize";
import MainContent from "./MainContent";

const user = { "kcc/roles": "user" };

const ability = buildAbilityFor(user);
if (process.env.NODE_ENV !== "production") {
  // expose ability to play around with it in devtools
  window.ability = ability;
}

const App = () => {
  const featureFlagClient = useGrowthBook();

  const { isAuthenticated, error, logout, user } = useAuth0();
  const { selectedOrg } = useOrganization();
  const [appInitialized, setAppInitialized] = useState(false);
  const [appInitializing, setAppInitializing] = useState(false);
  const [appError, setAppError] = useState(null);

  useEffect(() => {
    if (
      error?.toString() === "Error: Please verify your email before logging in."
    ) {
      window.location.replace("/#/verify/user");
    }
  }, [error]);

  useEffect(() => {
    if (!user) return;

    featureFlagClient.setAttributes({
      id: user.sub,
      email: user.email,
      organizationId: selectedOrg?.id
    });
  }, [featureFlagClient, selectedOrg?.id, user]);

  return (
    <AbilityContext.Provider value={ability}>
      <ConfirmProvider>
        <HashRouter>
          <Switch>
            <Route
              path="/logout"
              render={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin }
                })
              }
            />
            <Route path="/verify/user" component={EmailVerification} />
            {!isAuthenticated || error || appError ? (
              <>
                {(error || appError) && (
                  <StyledBlockquote>
                    <p>⚠️ {error?.message ?? appError?.message}</p>
                  </StyledBlockquote>
                )}
                <Landing />
              </>
            ) : !appInitialized || appInitializing ? (
              <AppInitialize
                setAppError={setAppError}
                appInitialized={appInitialized}
                setAppInitialized={setAppInitialized}
                setAppInitializing={setAppInitializing}
              />
            ) : (
              <MainContent setAppError={setAppError} />
            )}
          </Switch>
        </HashRouter>
      </ConfirmProvider>
    </AbilityContext.Provider>
  );
};

export default App;
