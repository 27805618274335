export const selectIsLoading = state => state.deviceAttribute?.isLoading;

export const selectAttrReported = state => state.deviceAttribute?.reported;

export const selectAttrReportedByDeviceId = deviceId => state =>
  state.deviceAttribute?.reported?.find(r => r.deviceId === deviceId) || {};

export const selectTelemetry = state => state.deviceAttribute?.telemetry;

export const selectDeviceStatus = state => state.deviceAttribute?.deviceStatus;

export const selectDeviceStatusByDeviceId = (state, deviceId) =>
  state.deviceAttribute?.deviceStatus[deviceId] || {};

export const selectScanActionStatus = state =>
  state.deviceAttribute?.deviceAction.scan;

export const selectIlluminateActionStatus = state =>
  state.deviceAttribute?.deviceAction.illuminate;
