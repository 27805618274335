const CUB_METERS_PER_DAY_TO_SCFH = 1.471;
const CUB_METERS_PER_DAY_TO_SCMD = 1;
const MET_PER_SEC_TO_MPH = 2.23694;
const MET_PER_SEC_TO_KMH = 3.6;
const MET_PER_SEC_TO_MPS = 1;

const units = Object.freeze({
  TEMPERATURE: {
    FAHRENHEIT: "°F",
    CELCIUS: "°C"
  },
  WIND: {
    MILES_PER_HOUR: "mph",
    KILOMETERS_PER_HOUR: "km/h",
    METERS_PER_SECOND: "m/s"
  },
  LEAKRATE: {
    STANDARD_CUBIC_FEET_PER_HOUR: "SCFH",
    STANDARD_CUBIC_METERS_PER_HOUR: "SCMH",
    STANDARD_CUBIC_METERS_PER_DAY: "Sm³/d"
  }
});

const MILES_PER_HOUR = "MILES_PER_HOUR";
const KILOMETERS_PER_HOUR = "KILOMETERS_PER_HOUR";
const METERS_PER_SECOND = "METERS_PER_SECOND";
const CUBIC_METERS_PER_DAY = "CUBIC_METERS_PER_DAY";
const ALGO_CUBIC_METERS_PER_DAY = "ALGO_CUBIC_METERS_PER_DAY";

const STANDARD_CUBIC_FEET_PER_HOUR = "STANDARD_CUBIC_FEET_PER_HOUR";
const STANDARD_CUBIC_METERS_PER_HOUR = "STANDARD_CUBIC_METERS_PER_HOUR";
const STANDARD_CUBIC_METERS_PER_DAY = "STANDARD_CUBIC_METERS_PER_DAY";

const FAHRENHEIT = "FAHRENHEIT";
const CELCIUS = "CELCIUS";

const UnitNames = Object.freeze({
  [MILES_PER_HOUR]: "mph",
  [KILOMETERS_PER_HOUR]: "km/h",
  [METERS_PER_SECOND]: "m/s",
  [STANDARD_CUBIC_METERS_PER_DAY]: "Sm³/d",
  [STANDARD_CUBIC_FEET_PER_HOUR]: "SCFH",
  [FAHRENHEIT]: "°F",
  [CELCIUS]: "°C",
  // Units of Measurement names used for Quantification Results:
  [ALGO_CUBIC_METERS_PER_DAY]: "m3/d"
});

// base wind speed unit is m/s
const WindSpeedMultiplier = {
  [UnitNames.MILES_PER_HOUR]: MET_PER_SEC_TO_MPH,
  [UnitNames.KILOMETERS_PER_HOUR]: MET_PER_SEC_TO_KMH,
  [UnitNames.METERS_PER_SECOND]: MET_PER_SEC_TO_MPS
};

// base leak rate unit is m3/d
const LeakRateMultiplier = {
  [UnitNames.STANDARD_CUBIC_FEET_PER_HOUR]: CUB_METERS_PER_DAY_TO_SCFH,
  [UnitNames.STANDARD_CUBIC_METERS_PER_DAY]: CUB_METERS_PER_DAY_TO_SCMD,
  [UnitNames.ALGO_CUBIC_METERS_PER_DAY]: CUB_METERS_PER_DAY_TO_SCMD
};

// base temperature unit is °C
const TemperatureMultiplier = {
  [UnitNames.FAHRENHEIT]: celsius => (celsius * 9) / 5 + 32,
  [UnitNames.CELCIUS]: celsius => celsius
};

module.exports = {
  // Imperial units
  MILES_PER_HOUR,
  STANDARD_CUBIC_FEET_PER_HOUR,
  FAHRENHEIT,
  // Metric units
  KILOMETERS_PER_HOUR,
  METERS_PER_SECOND,
  CUBIC_METERS_PER_DAY,
  STANDARD_CUBIC_METERS_PER_HOUR,
  STANDARD_CUBIC_METERS_PER_DAY,
  CELCIUS,

  CUB_METERS_PER_DAY_TO_SCFH,
  CUB_METERS_PER_DAY_TO_SCMD,
  MET_PER_SEC_TO_MPH,
  MET_PER_SEC_TO_KMH,
  MET_PER_SEC_TO_MPS,
  units,
  UnitNames,
  WindSpeedMultiplier,
  LeakRateMultiplier,
  TemperatureMultiplier
};
