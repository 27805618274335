import { HubConnectionBuilder } from "@microsoft/signalr";

export class SignalRService {
  constructor(baseURL) {
    this.baseTime = new Date();
    this.signalRInstance = {
      baseURL,
      accessTokenFactory: null,
      connection: null,
      timeout: 60000
    };
  }

  static instance = null;

  static Instance = baseURL => this.instance ?? new SignalRService(baseURL);

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.signalRInstance.accessTokenFactory = token;
  };

  getBaseURL = () => this.signalRInstance.baseURL;

  setBaseURI = url => {
    if (this.signalRInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.signalRInstance.baseURL = `${url}/v1`;
    }
  };

  createSignalRConnection = async () => {
    try {
      this.signalRInstance.connection = await new HubConnectionBuilder()
        .withUrl(`${this.signalRInstance.baseURL}`, {
          accessTokenFactory: () => {
            return this.signalRInstance.accessTokenFactory;
          }
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.elapsedMilliseconds < 60000) {
              // If we've been reconnecting for less than 60 seconds so far,
              // wait between 0 and 10 seconds before the next reconnect attempt.
              return Math.random() * 10000;
            } else {
              // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
              return null;
            }
          }
        })
        .build();
    } catch (e) {
      console.error("createSignalRConnection error:", e);
    }
  };

  start = connection => {
    connection.start().catch(e => {
      console.error("SignalRService start error:", e);
    });
  };

  unsubscribeOrgSubscription = (connection, subscriptionName) => {
    try {
      connection.invoke(
        "unsubscribeorganization",
        this.signalRInstance.accessTokenFactory,
        subscriptionName
      );
    } catch (e) {
      console.error("unsubscribeOrgSubscription error:", e);
    }
  };

  subscribeOrganization = (connection, subscriptionName) => {
    try {
      connection.invoke(
        "subscribeorganization",
        this.signalRInstance.accessTokenFactory,
        subscriptionName
      );
    } catch (e) {
      console.error("unsubscribeOrgSubscription error:", e);
    }
  };

  unsubscribeDevice = (connection, subscriptionName) => {
    try {
      connection.invoke(
        "unsubscribedevice",
        this.signalRInstance.accessTokenFactory,
        subscriptionName
      );
    } catch (e) {
      console.error("subscribeDevice error:", e);
    }
  };

  subscribeDevice = (connection, subscriptionName) => {
    try {
      connection.invoke(
        "subscribedevice",
        this.signalRInstance.accessTokenFactory,
        subscriptionName
      );
    } catch (e) {
      console.error("SignalRService subscribeDevice error:", e);
    }
  };

  subscribe = (connection, subscriptionName, subscriptionType) => {
    switch (subscriptionType) {
      case "device":
        this.subscribeDevice(connection, subscriptionName);
        break;
      case "organization":
        this.subscribeOrganization(connection, subscriptionName);
        break;
      default:
        console.error("Subscription type not found.");
    }
  };

  unsubscribePreviousSubscription = (
    connection,
    subscriptionName,
    subscriptionType
  ) => {
    switch (subscriptionType) {
      case "device":
        this.unsubscribeDevice(connection, subscriptionName);
        break;
      case "organization":
        this.unsubscribeOrgSubscription(connection, subscriptionName);
        break;
      default:
        console.error("Subscription type not found.");
    }
  };
}

export const signalRServiceInstance = SignalRService.Instance();
