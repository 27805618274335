import { createAsyncThunk } from "@reduxjs/toolkit";

import UserNotificationAPI from "~/apis/UserNotificationAPI";

const thunk = async startDate => {
  const response = await UserNotificationAPI.getUserNotifications(startDate);
  return response;
};

export const fetchUserNotificationsByDate = createAsyncThunk(
  "users/fetchUserNotificationsByDate",
  thunk
);
