import { Divider, Typography } from "@mui/material";
import PropTypes from "prop-types";

import MultipleSelect from "../MultipleSelect";

import { useStyles } from "./styles";

const FormEmail = ({ orgUsers, orgRecipients, handleRecipientsOnChange }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
        Email Subscribers
      </Typography>
      <Divider style={{ margin: "10px 0 10px 0" }} />
      <form>
        <MultipleSelect
          value={orgRecipients}
          orgUsers={orgUsers}
          orgRecipients={orgRecipients}
          handleRecipientsOnChange={handleRecipientsOnChange}
        />
      </form>
    </div>
  );
};

FormEmail.defaultProps = {
  orgUsers: []
};

FormEmail.propTypes = {
  clear: PropTypes.func.isRequired,
  orgUsers: PropTypes.array.isRequired,
  orgRecipients: PropTypes.array.isRequired,
  handleRecipientsOnChange: PropTypes.func.isRequired
};

export default FormEmail;
