import { debounce } from "lodash";
import { useEffect, useState } from "react";

export const useSearch = (array, propertyName) => {
  const [filteredArray, setFilteredArray] = useState(array);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = debounce(
    searchValue => setSearchValue(searchValue),
    500
  );

  useEffect(() => {
    setFilteredArray(
      searchValue
        ? array.filter(item =>
            item[propertyName].toLowerCase().includes(searchValue.toLowerCase())
          )
        : array
    );
  }, [searchValue, array, propertyName]);

  return { filteredArray, handleSearch };
};
