import { FileCopy } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { useCopyClipboard } from "../../hooks";
import { Tooltip } from "../Tooltip";

const useStyles = makeStyles(({ spacing, palette }) => ({
  copyText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: spacing(-0.5),
    color: palette.text.primary
  },
  iconButton: {
    padding: 0,
    marginLeft: spacing(1),
    color: palette.text.secondary,
    "&:hover": {
      color: palette.text.primary
    }
  }
}));

export const CopyValue = ({ isValueHidden = false, units = null, value }) => {
  const classes = useStyles();

  const displayedValue = value ? (units ? `${value} ${units}` : value) : "N/A";

  const [isCopied, copyToClipboard] = useCopyClipboard();

  const handleCopy = () => {
    copyToClipboard(displayedValue);
  };

  return (
    <div className={classes.copyText}>
      {!isValueHidden && <Typography>{displayedValue}</Typography>}
      <Tooltip title="Copied to clipboard!" placement="top" open={isCopied}>
        <IconButton
          className={classes.iconButton}
          onClick={handleCopy}
          size="large"
        >
          <FileCopy />
        </IconButton>
      </Tooltip>
    </div>
  );
};

CopyValue.propTypes = {
  isValueHidden: PropTypes.bool,
  units: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
