import { Auth0Provider } from "@auth0/auth0-react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import {
  ThemeProvider,
  createTheme,
  styled,
  theme,
  MaterialDesignContent,
  SnackbarProvider
} from "@kuva/ui-components";

import { featureFlagClient } from "~/configuration/feature-flag-client";
import * as serviceWorker from "~/serviceWorker";
import { appPersistStore, store } from "~/store/configure-store";

import App from "./common/App";
import "./index.css";
import { getSubDomain } from "./utils/getSubDomain";

const onRedirectCallback = appState => {
  window.location.href = appState?.returnTo ?? window.location.pathname;
};
const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme: { typography } }) => ({
    "&.notistack-MuiContent": {
      fontsize: typography.body1.fontSize,
      fontFamily: typography.fontFamily,
      "&.notistack-MuiContent-info": {
        backgroundColor: "grey"
      }
    }
  })
);

const Application = () => {
  useEffect(() => {
    featureFlagClient.loadFeatures();
  }, []);

  const subDomain = getSubDomain(window.location.href);
  return (
    <StrictMode>
      <GrowthBookProvider growthbook={featureFlagClient}>
        <Provider store={store}>
          <PersistGate persistor={appPersistStore}>
            <ThemeProvider theme={createTheme(theme)}>
              <SnackbarProvider
                maxSnack={7}
                hideIconVariant
                Components={{
                  success: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                  warning: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent
                }}
              >
                <Auth0Provider
                  domain={String(process.env.REACT_APP_AUTH0_DOMAIN_URL)}
                  clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
                  onRedirectCallback={onRedirectCallback}
                  authorizationParams={{
                    ...(subDomain && { organization: subDomain }),
                    redirect_uri: window.location.origin,
                    audience: process.env.REACT_APP_AUTH0_API_ID
                  }}
                >
                  <App />
                </Auth0Provider>
              </SnackbarProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </GrowthBookProvider>
    </StrictMode>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
