import { Tabs as MaterialTabs } from "@mui/material";
import noop from "lodash/noop";
import PropTypes from "prop-types";

import {
  ActionsSpacer,
  AppBar,
  Container,
  useTabContainerStyles
} from "./styled-components";

export const Tabs = ({
  tabIndex = 0,
  onTabChange = noop,
  renderActions = noop,
  children
}) => {
  const actions = renderActions({ tabIndex });
  const classes = useTabContainerStyles();

  return (
    <AppBar position="static">
      <Container>
        {actions ?? <ActionsSpacer />}
        <MaterialTabs
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
          value={tabIndex}
          onChange={(event, idx) => {
            onTabChange(event, idx);
          }}
          classes={classes}
        >
          {children}
        </MaterialTabs>
      </Container>
    </AppBar>
  );
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  tabIndex: PropTypes.number,
  onTabChange: PropTypes.func,
  renderActions: PropTypes.func
};

Tabs.displayName = "TabbedNavigation.Tabs";
