import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useConfirm } from "material-ui-confirm";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useLogout } from "~/hooks";

const isTokenExpired = token => {
  try {
    const decodedToken = jwtDecode(token);

    if (!decodedToken.exp) {
      return true;
    }

    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
};

const checkTokenAndLogout = async (token, logout, confirm) => {
  if (isTokenExpired(token)) {
    try {
      await confirm({
        title: "Session Expired",
        description: `Your session has expired. You will be logged out!`,
        confirmationText: "Log out",
        cancellationText: "Stay logged in"
      });

      logout();
    } catch (error) {
      window.location.reload();
    }
  }
};

const RouteChangeListener = () => {
  const { logout } = useLogout();
  const history = useHistory();
  const confirm = useConfirm();

  useEffect(() => {
    const token = Cookies.get("kuva.auth.jwt");

    checkTokenAndLogout(token, logout, confirm);

    const unlisten = history.listen(() => {
      checkTokenAndLogout(token, logout, confirm);
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  return null;
};

export default RouteChangeListener;
