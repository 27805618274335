import { arrayOf, bool, func, shape, string } from "prop-types";

export const multiSelectItemType = shape({
  value: string.isRequired,
  chipValue: string,
  label: string.isRequired,
  selected: bool
});

export const multiSelectItemsType = arrayOf(multiSelectItemType);

export const multiSelectPropsType = shape({
  isSearchable: bool.isRequired,
  hasSelected: bool.isRequired,
  selectedItems: multiSelectItemsType,
  hasOtherItems: bool.isRequired,
  otherItems: multiSelectItemsType,
  handleSearch: func.isRequired,
  showClearAll: bool.isRequired,
  onSelect: func.isRequired,
  onClearAll: func.isRequired,
  onSelectAll: func.isRequired
});
