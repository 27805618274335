import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

const thunk = async payload => await AlarmAPI.getLatestAlarmPerStream(payload);

export const getLatestAlarmsPerStream = createAsyncThunk(
  "cameras/getLatestAlarmsPerStream",
  thunk
);
