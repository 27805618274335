import { styled } from "@mui/styles";

export const UnsavedStateBadge = styled("div")(({ theme }) => ({
  display: "block",
  position: "absolute",
  width: theme.spacing(1),
  height: theme.spacing(1),
  background: theme.palette.custom.green[400],
  borderRadius: "50%",
  top: theme.spacing(1),
  left: theme.spacing(1)
}));
