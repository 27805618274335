import makeStyles from "@mui/styles/makeStyles";

const rad =
  "linear-gradient(90deg, rgba(116,174,62,1) 0%, rgba(206,102,65,1) 30%)";

const useStyles = makeStyles(({ spacing }) => ({
  progress: {
    height: spacing(1),
    width: 100,
    borderRadius: 100,
    background:
      "linear-gradient(90deg,#636C46 12.5%,#6A5D42 60.42%, #785946 100%)",
    boxShadow:
      "0px 0px 20px rgba(18,18,18,0.35), 0px 1px 2px 1px rgba(34,34,34,0.12)",
    margin: "10px auto",
    "& div": {
      width: value => `${value}%`,
      height: "100%",
      borderRadius: 10,
      backgroundImage: rad,
      backgroundSize: "360px 100%",
      transition: "width 200ms"
    }
  }
}));

export { useStyles };
