import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    backgroundColor: "#242E3B",
    textAlign: "center",
    padding: "50px"
  },
  logo: {
    width: "200px",
    marginBottom: "10px"
  },
  btnContinue: {
    marginTop: "10px",
    color: "#FF5100"
  }
});

export { useStyles };
