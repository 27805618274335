import { CameraActions } from "~/store/slices/cameras";
import { DeviceAttributeActions } from "~/store/slices/device-attribute";

import CameraAPI from "../apis/CameraAPI";

/**
 * @export function updateCamera
 * @param {string} deviceId - the camera's identifier
 * @param {ICamera} attributes - the tags to be updated
 *
 * This function updates a camera with the provided attributes
 */
export const updateCamera = (deviceId, attributes) => {
  const body = { ...attributes, deviceId };

  return dispatch => {
    return CameraAPI.updateDeviceAttributes(body)
      .then(response => {
        if (response.data) {
          dispatch(
            CameraActions.updateCamerasSuccess({ deviceId, attributes })
          );
          return response;
        } else {
          dispatch(CameraActions.error({ errorOn: "UPDATE_CAMERA" }));
          throw new Error("Update failed"); // Ensure error handling
        }
      })
      .catch(error => {
        dispatch(CameraActions.error({ errorOn: "UPDATE_CAMERA" }));
        throw error;
      });
  };
};

export const pauseOnPoiPut = (deviceId, attributes) => {
  return () =>
    CameraAPI.pauseOnPoi(deviceId, attributes)
      .then(response => {
        if (response.status === 200) return true;
        return false;
      })
      .catch(() => {
        return false;
      });
};

/**
 * @export function addLatestAlarm
 * @param {object} attr - add latest alarm to camera object
 *
 * creates it from the actual cameras state
 */
export function addLatestAlarm(alarms) {
  return function (dispatch) {
    dispatch(CameraActions.addLatestAlarm(alarms));
  };
}

/**
 * @export function setPoiNames
 * @param {object} attr - add poiNames to camera object
 *
 */
export function setPoiNames(poiNames) {
  return function (dispatch) {
    dispatch(CameraActions.setPoiNames(poiNames));
  };
}

/**
 * @export function setCameraLastHeartbeat
 * @param {object} attr - the attrributes to update sent from the iot hub
 *
 * finds the camera object instance  these properties belong too
 * updates the camera object instance properties
 * sends the camera object instance  to redux to update the store
 */
export function setCameraLastHeartbeat(attr) {
  return function (dispatch, getState) {
    console.log("setCameraLastHeartbeat", attr);

    // get the data from the message
    const deviceId = attr.deviceId;
    // find the camera instance to update
    const cameras = getState().cameras;
    if (cameras?.length) {
      const toUpdate = cameras.find(cam => cam.deviceId === deviceId);
      if (toUpdate !== undefined && toUpdate !== null) {
        // update the camera instance
        const attributes = toUpdate.updateHeartbeat(attr);
        // const updated = { ...toUpdate, ...attributes };
        //send the updated camera instance back to redux to handle
        dispatch(CameraActions.updateCamerasSuccess({ deviceId, attributes }));
      }
    }
  };
}

/**
 *
 * @description this function is called when we want to update the name, location, orgId etc...
 * @author Sean W.
 * @date 16/10/2021
 * @export function setCameraReportedAttributes
 * @param {object} newAttributes - the attrributes to update sent from the iot hub
 *
 * Check if the reported properties changed
 * finds the camera object instance  these properties belong too
 * updates the camera object instance properties
 * sends the camera object instance  to redux to update the store
 *
 */
export function setCameraReportedAttributes(newAttributes) {
  return function (dispatch) {
    // get the data from the message
    console.log(
      "saving new camera attributes for device: ",
      newAttributes.deviceId
    );
    //send the updated camera instance back to redux to handle
    dispatch(DeviceAttributeActions.upsertReportedDeviceTwin(newAttributes));
  };
}

/**
 * @name toggleCameraScanAction
 * @description this function is a middleware function that
 * 1. calls the toggleCameraScan api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Jan A.
 * @date 27/09/2022
 *
 * @param deviceId - the id of the camera
 *
 * @returns {function}
 */
export const toggleCameraScanAction = deviceId => {
  return async dispatch => {
    dispatch(
      DeviceAttributeActions.setScanActionStatus({
        [deviceId]: { isLoading: true, awaitingResponse: false }
      })
    );

    return CameraAPI.toggleCameraScan(deviceId)
      .then(response => {
        if (response.status === 200) {
          dispatch(
            DeviceAttributeActions.setScanActionStatus({
              [deviceId]: { isLoading: false, awaitingResponse: true }
            })
          );

          return response;
        }
      })
      .catch(error => {
        console.log("toggleCameraScan:", error);
      })
      .finally(() => {
        dispatch(
          DeviceAttributeActions.setScanActionStatus({
            [deviceId]: { isLoading: false }
          })
        );
      });
  };
};

/**
 * @name toggleIlluminatorAction
 * @description this function is a middleware function that
 * 1. calls the toggleIlluminatorAction api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Jan A.
 * @date 26/09/2022
 *
 * @param deviceId - the id of the camera
 *
 * @returns {function}
 */
export const toggleIlluminatorAction = deviceId => {
  return async dispatch => {
    dispatch(
      DeviceAttributeActions.setIlluminateActionStatus({
        [deviceId]: { isLoading: true, awaitingResponse: false }
      })
    );

    return CameraAPI.toggleIlluminator(deviceId)
      .then(response => {
        if (response.status === 200) {
          dispatch(
            DeviceAttributeActions.setIlluminateActionStatus({
              [deviceId]: { isLoading: false, awaitingResponse: true }
            })
          );

          return response;
        }
      })
      .catch(error => {
        console.log("toggleIlluminator:", error);
      })
      .finally(() => {
        dispatch(
          DeviceAttributeActions.setIlluminateActionStatus({
            [deviceId]: { isLoading: false }
          })
        );
      });
  };
};

export const getScanResultsForPoi = async (
  deviceId,
  max_live_frames,
  cancelToken
) => {
  try {
    const response = await CameraAPI.getScanResultsForPoi({
      deviceId,
      max_live_frames,
      cancelToken
    });

    if (response.data) {
      const data = response.data;
      return data;
    } else {
      throw new Error("empty response");
    }
  } catch (error) {
    console.error("error", error);
    throw error;
  }
};
