import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import PropTypes from "prop-types";

import {
  ArrowCircleDownIcon,
  ArrowCircleDownOutlineIcon,
  ArrowCircleUpIcon,
  ArrowCircleUpOutlineIcon
} from "~/components/Table/SvgIcons";
import { swapColumns } from "~/components/Table/table-utils";

import { useStyles } from "./styles";

export default function FieldSelection({ columns, setColumns }) {
  const { classes } = useStyles();
  const handleChange = event => {
    const newColumns = columns.map(col =>
      col.field === event?.target?.name
        ? { ...col, columnVisibility: event.target.checked }
        : col
    );
    setColumns(newColumns);
  };

  // Keeping two separate functions makes it easier to understand what each of them does
  const handleClickMoveLeft = (columns, index) => {
    const newColumns = swapColumns(columns, index, index - 1);
    setColumns(newColumns);
  };

  const handleClickMoveRight = (columns, index) => {
    const newColumns = swapColumns(columns, index, index + 1);
    setColumns(newColumns);
  };
  return (
    <Box className={classes.root}>
      <FormGroup>
        {columns?.map((col, index) => (
          <Box key={col?.field}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={col?.columnVisibility}
                  onChange={handleChange}
                  name={col?.field}
                  disabled={col?.withVisibilityOptions === false}
                />
              }
              label={col.headerName}
            />
            {index === columns?.length - 1 ? (
              <ArrowCircleDownOutlineIcon className={classes.inactiveControl} />
            ) : (
              <ArrowCircleDownIcon
                className={classes.activeControl}
                onClick={() => handleClickMoveRight(columns, index)}
              />
            )}
            {index !== 0 ? (
              <ArrowCircleUpIcon
                className={classes.activeControl}
                onClick={() => handleClickMoveLeft(columns, index)}
              />
            ) : (
              <ArrowCircleUpOutlineIcon className={classes.inactiveControl} />
            )}
          </Box>
        ))}
      </FormGroup>
    </Box>
  );
}

FieldSelection.propTypes = {
  columns: PropTypes.array,
  setColumns: PropTypes.func
};
