import PropTypes from "prop-types";
import { useState, useContext, createContext } from "react";
import { withRouter } from "react-router-dom";

export const FrameSettingsContext = createContext({});
export const useFrameSettings = () => useContext(FrameSettingsContext);

const FrameSettingsContextProvider = props => {
  // const { deviceId } = useParams();
  const [loadedImages, setLoadedImages] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDetections, setShowDetections] = useState(false);
  const [showUIDetections, setShowUIDetections] = useState(true);

  const onSetShowUIDetections = show => {
    setShowUIDetections(show);
  };

  const onSetRedThreshold = value => {
    setRedThreshold(value);
    localStorage.setItem("redThresh", value);
  };

  const [minThreshold, setMinThreshold] = useState(
    localStorage.getItem("minThresh")
      ? parseInt(localStorage.getItem("minThresh"))
      : 0
  );

  const [redThreshold, setRedThreshold] = useState(
    // if localstorage redThresh is a number use it otherwise set to 100
    !isNaN(Number(localStorage.getItem("redThresh")))
      ? Number(localStorage.getItem("redThresh"))
      : 100
  );

  return (
    <FrameSettingsContext.Provider
      value={{
        loadedImages,
        setLoadedImages,
        startDate,
        setStartDate,
        currentIndex,
        setCurrentIndex,
        endDate,
        setEndDate,
        showDetections,
        setShowDetections,
        dataLoading,
        setDataLoading,
        minThreshold,
        setMinThreshold,
        showUIDetections,
        setShowUIDetections: onSetShowUIDetections,
        redThreshold,
        setRedThreshold: onSetRedThreshold
      }}
    >
      {props.children}
    </FrameSettingsContext.Provider>
  );
};

FrameSettingsContextProvider.propTypes = {
  children: PropTypes.node
};

export const FrameSettingsProvider = withRouter(FrameSettingsContextProvider);
