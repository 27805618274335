import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCameraModuleById } from "~/selectors/CameraSelector";
import CameraAPI from "~/apis/CameraAPI";

// Cache condition. If the module already exists in application
// state, don't make another request.
const options = {
  condition: (deviceId, { getState }) => {
    const state = getState();
    const cached = getCameraModuleById(deviceId);

    return !cached(state);
  }
};

const thunk = async deviceId => {
  const { data } = await CameraAPI.getModuleTwinForCamera(
    deviceId,
    "CameraApp"
  );

  return {
    ...data,
    deviceId
  };
};

export const getCameraModule = createAsyncThunk(
  "cameras/getCameraModule",
  thunk,
  options
);
