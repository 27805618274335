import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "auto auto 1fr auto",
    height: "40px",
    paddingInlineEnd: "8px",
    borderBottom: "dotted 1px #9ad000",
    borderLeft: "double 1px #9ad000",
    maxWidth: "400px",
    cursor: "pointer"
  },
  expandIconWrapper: {
    alignItems: "center",
    fontSize: 0,
    cursor: "pointer",
    display: "flex",
    height: "24px",
    justifyContent: "center",
    width: "24px",
    transition: "transform linear 0.1s",
    transform: "rotate(0deg)"
  },
  isOpen: {
    transform: "rotate(180deg)"
  },
  labelGridItem: {
    paddingInlineStart: "8px"
  },
  avatar: {
    height: 30,
    width: 30,
    margin: "2px"
  }
});

export { useStyles };
