import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    backgroundColor: "#ff5100",
    height: "2px",
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translateY(-50%)"
  }
});

export { useStyles };
