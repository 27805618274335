const escapeRegExp = value => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const getSearchRegex = searchValue =>
  new RegExp(escapeRegExp(searchValue), "i");

export const requestSearch = ({ searchValue, rows }) => {
  // Check if searchValue is an empty string or contains only whitespaces
  if (!searchValue.trim()) {
    return rows; // Return the original rows without filtering
  }

  const searchReg = getSearchRegex(searchValue);
  const filteredRows = rows.filter(row => {
    return Object.keys(row).some(field => {
      return searchReg.test(row[field]?.toString());
    });
  });

  return filteredRows;
};
