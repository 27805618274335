import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { makeStyles } from "@kuva/ui-components";

import { useOrganization } from "~/hooks";

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginLeft: 4
  },
  nestedListItem: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "inherit",
    textDecoration: "none",
    color: theme.palette.common.white
  },
  selected: {
    color: theme.palette.primary.main
  }
}));

const OrganizationTree = ({ onSelect }) => {
  const { classes } = useStyles();
  const { organizations, selectedOrg } = useOrganization();

  const renderTree = nodes => (
    <TreeItem
      component={ListItem}
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <Link
          onClick={() => onSelect(nodes.id)}
          to={`/${nodes.id}`}
          className={clsx(classes.nestedListItem, {
            [classes.selected]: nodes.id === selectedOrg?.id
          })}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
          selected={nodes.id === selectedOrg?.id}
        >
          <ListItemAvatar>
            <Avatar
              style={{
                height: "30px",
                width: "30px"
              }}
              alt={nodes.orgname}
              src={nodes.logoURL}
            />
          </ListItemAvatar>

          <ListItemText primaryTypographyProps={{ variant: "body1" }}>
            {nodes.orgname || nodes.id}
          </ListItemText>
        </Link>
      }
    >
      {nodes.children?.length > 0 &&
        nodes.children.map(node => renderTree(node))}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={organizations.map(o => o.id)}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {organizations.map(o => renderTree(o))}
    </TreeView>
  );
};

OrganizationTree.propTypes = {
  onSelect: PropTypes.func
};

export default OrganizationTree;
