import axios from "axios";

import AlarmAPI from "~/apis/AlarmAPI";
import CameraAPI from "~/apis/CameraAPI";
import DeviceAPI from "~/apis/DeviceAPI";
import StrapiAPI from "~/apis/StrapiAPI";
import TelemetryAPI from "~/apis/TelemetryAPI";
import { request } from "~/request";
import { SignalRService } from "~/services";

export const setSignalRURL = async user => {
  const baseOrg = user.org_name ?? user["kcc/base_org"];
  // FIXME: (S.W) this is a temporary solution to get the signalR URL for the specific organization now that we're using appConfigs
  const { data: signalRBaseURL } = await request(
    `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/config/${baseOrg}/signalr/url`
  );
  if (!signalRBaseURL) throw "SignalR URL not found";
  SignalRService.setBaseURI(signalRBaseURL);
};

export const getStrapiToken = async user => {
  const baseOrg = user.org_name ?? user["kcc/base_org"];

  const { data: strapiConfig } = await request(
    `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/config/${baseOrg}/strapi`
  );

  const url = `${strapiConfig.url}/api/auth/local`;
  const body = {
    identifier: strapiConfig.identifier,
    password: strapiConfig.password
  };

  const {
    data: { jwt }
  } = await axios.post(url, body);

  StrapiAPI.setBaseURI(strapiConfig.url);
  StrapiAPI.setStrapiToken(jwt);
};

export const setAuthToken = token => {
  CameraAPI.setAuthToken(token);
  TelemetryAPI.setAuthToken(token);
  AlarmAPI.setAuthToken(token);
  SignalRService.setAuthToken(token);
  DeviceAPI.setAuthToken(token);
};
