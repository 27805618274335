import Cookies from "js-cookie";
import { useAuth0 } from "@auth0/auth0-react";

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    Cookies.remove("kuva.auth.jwt");
    auth0Logout({
      logoutParams: { returnTo: window.location.origin }
    });
  };

  return { logout };
};
