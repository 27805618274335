import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(() => ({
  ellipsis: {
    cursor: "pointer"
  }
}));

// TODO Jan: move to storybook->ui-components and add option to change ellipsis (text or icon)
const ReadMore = ({
  children,
  maxNumberOfChar = 150,
  truncateText = true,
  readMore = true,
  ...props
}) => {
  const { classes } = useStyles();
  const [isReadMore, setIsReadMore] = useState(true);

  const text = children;
  const textLength = text?.length ?? 0;
  const isMaxChar = textLength >= maxNumberOfChar;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const truncate = (text, maxNumberOfChar) => {
    if (truncateText && textLength >= maxNumberOfChar) {
      return text.slice(0, maxNumberOfChar);
    }
    return text;
  };

  return (
    <Box>
      <span {...props}>
        {isReadMore ? truncate(text, maxNumberOfChar) : text}
        {readMore && isMaxChar ? (
          <span onClick={toggleReadMore}>
            {isReadMore ? (
              <span className={classes?.ellipsis}>...Show more</span>
            ) : (
              <div className={classes?.ellipsis}> -Show less</div>
            )}
          </span>
        ) : (
          truncateText && isMaxChar && "..."
        )}
      </span>
    </Box>
  );
};

ReadMore.propTypes = {
  children: PropTypes.node,
  maxNumberOfChar: PropTypes.number,
  truncateText: PropTypes.bool,
  readMore: PropTypes.bool
};

export default ReadMore;
