import { styled } from "@kuva/ui-components";

export const MenuContainer = styled("div", {
  shouldForwardProp: prop => prop !== "isTableMenu"
})(({ theme: { spacing }, isTableMenu }) => ({
  padding: isTableMenu ? spacing(2, 2, 2.5) : spacing(0, 1, 0, 2.5),
  minWidth: "175px",
  minHeight: "80px",
  height: "auto",
  maxHeight: "555px",
  backgroundColor: "#222",
  borderRadius: "5px",
  "& .MuiPaper-root": {
    boxShadow: "unset"
  }
}));

export const CustomFieldContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center"
}));
