import Box from "@mui/material/Box";

const AlphaUpIcon = props => {
  return (
    <Box {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 7H22L18 3L14 7H17V21H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 4.46957 10.7893 3.96086 10.4142 3.58579C10.0391 3.21071 9.53043 3 9 3ZM9 7H7V5H9V7Z"
          fill="#A6A6A6"
        />
      </svg>
    </Box>
  );
};

const AlphaDownIcon = props => {
  return (
    <Box {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 17H22L18 21L14 17H17V3H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 4.46957 10.7893 3.96086 10.4142 3.58579C10.0391 3.21071 9.53043 3 9 3ZM9 7H7V5H9V7Z"
          fill="#A6A6A6"
        />
      </svg>
    </Box>
  );
};

const NumericDownIcon = props => {
  return (
    <Box {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 17H22L18 21L14 17H17V3H19V17ZM9 13H7C5.9 13 5 13.9 5 15V16C5 16.5304 5.21071 17.0391 5.58579 17.4142C5.96086 17.7893 6.46957 18 7 18H9V19H5V21H9C10.11 21 11 20.11 11 19V15C11 14.4696 10.7893 13.9609 10.4142 13.5858C10.0391 13.2107 9.53043 13 9 13ZM9 16H7V15H9V16ZM9 3H7C5.9 3 5 3.9 5 5V9C5 9.53043 5.21071 10.0391 5.58579 10.4142C5.96086 10.7893 6.46957 11 7 11H9C10.11 11 11 10.11 11 9V5C11 4.46957 10.7893 3.96086 10.4142 3.58579C10.0391 3.21071 9.53043 3 9 3ZM9 9H7V5H9V9Z"
          fill="#A6A6A6"
        />
      </svg>
    </Box>
  );
};

const NumericUpIcon = props => {
  return (
    <Box {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 7H22L18 3L14 7H17V21H19M9 21H5V19H9V18H7C6.46957 18 5.96086 17.7893 5.58579 17.4142C5.21071 17.0391 5 16.5304 5 16V15C5 13.9 5.9 13 7 13H9C9.53043 13 10.0391 13.2107 10.4142 13.5858C10.7893 13.9609 11 14.4696 11 15V19C11 20.11 10.11 21 9 21ZM9 15H7V16H9M7 3H9C9.53043 3 10.0391 3.21071 10.4142 3.58579C10.7893 3.96086 11 4.46957 11 5V9C11 10.11 10.11 11 9 11H7C6.46957 11 5.96086 10.7893 5.58579 10.4142C5.21071 10.0391 5 9.53043 5 9V5C5 3.9 5.9 3 7 3ZM7 9H9V5H7V9Z"
          fill="#A6A6A6"
        />
      </svg>
    </Box>
  );
};

export { AlphaDownIcon, AlphaUpIcon, NumericDownIcon, NumericUpIcon };
