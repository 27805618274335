import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./signalr-initial-state";

const slice = createSlice({
  name: "signalr",
  initialState,
  reducers: {
    setSignalRIsLoading: (state, action) => {
      state.isLoading = action.isLoading;
    },
    setSignalRConnection: (state, action) => {
      state.connection = action.connection;
    },
    setSubscriptionDetails: (state, action) => {
      state.subscriptionDetails = action.subscriptionDetails;
    }
  }
});

export const SignalRActions = {
  ...slice.actions
};

export const SignalRReducer = slice.reducer;
