import { createSlice } from "@reduxjs/toolkit";

import {
  normalizeOrgs,
  getFlattenOrgIds,
  findOrganizationAndDescendant
} from "~/utils/organization";

import { updateOrganization } from "./thunks/update-organization";

const initialState = {
  organizations: [],
  selectedOrg: null,
  normalizedOrgs: {},
  selectedOrgWithDescendants: [],
  selectedOrgIDs: [],
  loading: false
};

const slice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      const newOrganizations = [...action.payload];
      state.organizations = newOrganizations;
      state.normalizedOrgs = normalizeOrgs(newOrganizations);
      state.loading = false;
    },
    setSelectedOrg: (state, action) => {
      const newSelectedOrg = action.payload;
      state.selectedOrg = newSelectedOrg;
      /* Find organization and descendant and return as an Object.*/
      const newSelectedOrgWithDescendants = findOrganizationAndDescendant(
        state.organizations,
        newSelectedOrg?.id
      );
      /* Return org ids in a flatten array from selected org w/ descendants object
       * e.g(['kuva-usa', 'kuva-massachusetts', 'kuva-texas', 'kuva-lubbok']) */
      const newSelectedOrgIDs = getFlattenOrgIds(newSelectedOrgWithDescendants);
      state.selectedOrgWithDescendants = newSelectedOrgWithDescendants;
      state.selectedOrgIDs = newSelectedOrgIDs;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(updateOrganization.fulfilled, (state, action) => {
        const { organizations, ...newSelectedOrg } = {
          ...state.selectedOrg,
          ...action.payload
        };

        state.normalizedOrgs[newSelectedOrg.id] = newSelectedOrg;
        state.organizations = organizations;
        state.selectedOrg = newSelectedOrg;
        state.loading = false;
      })
      .addCase(updateOrganization.rejected, state => {
        state.loading = false;
      })
      .addCase(updateOrganization.pending, state => {
        state.loading = true;
      });
  }
});

export const OrganizationActions = {
  ...slice.actions,
  updateOrganization
};

export const OrganizationsReducer = slice.reducer;
