import { useDispatch, useSelector } from "react-redux";

import { getSelectedCalendarDay } from "~/selectors/CameraSelector";
import { CameraActions } from "~/store/slices/cameras";

export const useSelectedCalendarDay = () => {
  const selectedCalendarDay = useSelector(getSelectedCalendarDay);
  const dispatch = useDispatch();

  const setSelectedCalendarDay = day =>
    dispatch(CameraActions.setSelectedCalendarDay(day));

  return {
    selectedCalendarDay,
    setSelectedCalendarDay
  };
};
