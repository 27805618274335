import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  tooltipDiv: {
    border: "solid 1px white",
    background: palette.custom.background.grey
  },
  tooltipTitle: {
    color: palette.custom.grey,
    fontSize: 14,
    marginBottom: spacing(0.5)
  },
  tooltipSpan: {
    whiteSpace: "pre-line",
    fontSize: 16
  }
}));

const overrideStyles = makeStyles()(({ spacing, palette }) => ({
  tooltip: {
    width: 310,
    padding: spacing(2.5),
    border: "solid 1px " + palette.custom.grey,
    background: "rgba(34,34,34,0.9)",
    boxShadow: "0px 1px 2px 1px rgba(34,34,34,0.12)",
    filter: "drop-shadow(0px 0px 20px rgba(18,18,18,0.35))",
    stroke: palette.action.disabled
  }
}));

export { overrideStyles, useStyles };
