import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useState } from "react";

import { Button, DisconnectedTooltipHelperContent } from "../../core";
import {
  scanResultAspectRatio,
  scanResultDimensions
} from "../constants/scan-result-dimensions";

const PointsOfInterestImageProps = {
  connected: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  onRetake: PropTypes.func,
  src: PropTypes.string
};

const Container = styled("div")({
  display: "inline-block"
});

const Image = styled("img")(({ theme }) => ({
  borderRadius: 7,
  aspectRatio: scanResultAspectRatio,
  display: "block",
  width: scanResultDimensions.WIDTH,
  background: theme.palette.action.selected
}));

export const ImageError = styled("div")(({ theme }) => ({
  borderRadius: 7,
  aspectRatio: scanResultAspectRatio,
  width: scanResultDimensions.WIDTH,
  background: theme.palette.action.selected,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[600],

  "& svg": {
    margin: theme.spacing(0, 1)
  }
}));

export const PointsOfInterestImage = ({
  alt,
  connected = false,
  onRetake = noop,
  src,
  ...props
}) => {
  const [error, setError] = useState(false);

  const handleImageError = () => setError(true);

  if (!src || error) {
    return (
      <ImageError>
        <Button
          disabled={!connected}
          variant="outlined"
          size="large"
          onClick={onRetake}
          startIcon={<PhotoCameraIcon />}
          {...(!connected && {
            TooltipProps: {
              placement: "bottom-center",
              title: <DisconnectedTooltipHelperContent />
            }
          })}
        >
          Retake
        </Button>
        <Box mt={2}>to request a new preview image</Box>
      </ImageError>
    );
  }

  return (
    <Container>
      <Image src={src} alt={alt} onError={handleImageError} {...props} />
    </Container>
  );
};

PointsOfInterestImage.propTypes = PointsOfInterestImageProps;
