import { makeStyles } from "@mui/styles";

function getBorderColor({
  isConnected,
  connectedBorderColor,
  defaultColor,
  errorColor,
  isError
}) {
  if (isError) {
    return errorColor;
  } else {
    return isConnected ? connectedBorderColor : defaultColor;
  }
}

const getBackground = ({
  isDefaultStyle,
  isError,
  isConnected,
  connectedBackgroundColor
}) => {
  if (isDefaultStyle) {
    return "none";
  } else if (isError && !isConnected) {
    return "#96313F0D";
  } else if (isConnected) {
    return connectedBackgroundColor;
  } else {
    return "transparent";
  }
};

export const useStyles = makeStyles(({ palette, spacing }) => ({
  wrapper: ({
    isConnected,
    variant = "default",
    isHovered = false,
    isSelected = false,
    isError = false,
    isImgPicQualityFeatureOn = false
  }) => {
    const isDefaultStyle = variant === "default";
    const defaultBgColor = palette.custom.green[50];
    const defaultBorderColor = palette.custom.green[100];

    const oldBackgroundColor =
      isHovered || isSelected ? palette.custom.green[100] : defaultBgColor;
    const newBackgroundColor =
      isHovered || isSelected ? palette.grey[700] : "transparent";
    const connectedBackgroundColor = isImgPicQualityFeatureOn
      ? newBackgroundColor
      : oldBackgroundColor;

    const oldBorderColor =
      isHovered || isSelected ? palette.custom.green[200] : defaultBorderColor;
    const newBorderColor = palette.grey[500];
    const connectedBorderColor = isImgPicQualityFeatureOn
      ? newBorderColor
      : oldBorderColor;

    const borderWidth = isDefaultStyle ? "1px" : Number(isConnected);

    return {
      background: getBackground({
        isDefaultStyle,
        isError,
        isConnected,
        connectedBackgroundColor
      }),
      border: isDefaultStyle
        ? "none"
        : `1px solid ${getBorderColor({
            isConnected,
            connectedBorderColor,
            defaultColor: palette.grey[600],
            errorColor: palette.error.main,
            isError
          })}`,
      borderTopWidth: borderWidth,
      borderRightWidth: borderWidth,
      borderLeftWidth: borderWidth,
      borderRadius: "5px 5px 0 0",
      padding: isDefaultStyle ? 0 : spacing(1, 2),
      transition: "all 0.2s",
      display: "flex",
      alignItems: "center",
      gridGap: isDefaultStyle ? spacing(1) : spacing(2),

      "& .camera-name": {
        fontSize: "1rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
      }
    };
  }
}));
