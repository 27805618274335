import { Button as MaterialButton } from "@mui/material";
import PropTypes from "prop-types";

import { LoadingSpinner } from "../LoadingSpinner";
import { Tooltip } from "../Tooltip";

import { LoaderWrapper, useButtonStyles } from "./Button.styles";

export const Button = ({
  children,
  color,
  loading,
  size,
  TooltipProps,
  disabled,
  ...props
}) => {
  const buttonClasses = useButtonStyles({ color, size });

  const button = (
    <MaterialButton
      {...props}
      size={size}
      classes={buttonClasses}
      disabled={disabled || loading}
    >
      {loading ? (
        <LoaderWrapper>
          <LoadingSpinner size={18} thickness={4} centered />
        </LoaderWrapper>
      ) : (
        children
      )}
    </MaterialButton>
  );

  return (
    <>
      {TooltipProps ? (
        <Tooltip
          placement="bottom"
          enterDelay={500}
          {...TooltipProps}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -6]
                  }
                }
              ]
            }
          }}
        >
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  color: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  TooltipProps: PropTypes.object
};
