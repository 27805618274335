import PropTypes from "prop-types";

import { CenteredContainer, Spinner } from "./styled-components";

export const LoadingSpinner = ({ centered = false, ...props }) => {
  const spinner = (
    <Spinner
      color={props.color || "inherit"}
      size={props.size || 32}
      thickness={props.thickness || 3}
      {...props}
    />
  );

  return centered ? <CenteredContainer>{spinner}</CenteredContainer> : spinner;
};

LoadingSpinner.propTypes = {
  centered: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number
};
