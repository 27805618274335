import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

export const getMoreAlarms = createAsyncThunk(
  "alarms/getMoreAlarms",
  async params => {
    const { data } = await AlarmAPI.getByPagination(params);
    return data;
  }
);
