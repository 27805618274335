import { addMinutes, subMinutes } from "date-fns";
import { cloneDeep, isNil } from "lodash";

export const writeToCache = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));
export const readFromCache = key =>
  JSON.parse(localStorage.getItem(key)) || null;

//TODO: This is a temp solution to remove duplicate images and should happen in the backend
//TODO: should be handled in the backend!!!!
export const filterDuplicates = response => {
  let newArray = [];
  response.forEach(image => {
    let duplicate = false;
    newArray.forEach(newImage => {
      if (image.createdOn === newImage.createdOn) {
        duplicate = true;
      }
    });
    if (!duplicate) {
      newArray.push(image);
    }
  });
  return newArray;
};

export const groupBy = (list, prop) => {
  const indexList = {};
  let index = 0;
  return list.reduce((acc, curr) => {
    const identifier = curr[prop] || 0;
    const pos = indexList[identifier];
    if (pos === undefined) {
      indexList[identifier] = index;
      index++;
      acc.push([]);
    }
    const currPos = indexList[identifier];
    acc[currPos].push(curr);

    return acc;
  }, []);
};

export const getUnique = (alarms, newAlarms) => {
  newAlarms?.map(x =>
    alarms.find(a => a.id === x.id) ? null : alarms.push(x)
  );
  return alarms;
};

export const getAverageWind = frames => {
  if (!frames) return ["N/A", "N/A"];
  let { vx, vy } = getVectors(frames);

  if (vx === "N/A" || vy === "N/A") return [vx, vy];

  return [getAvgSpeed(vx, vy), getAvgDirection(vx, vy)];
};

export const getVectors = frames => {
  const avoidDecimal = 10;
  let len = 0;
  let vx = avoidDecimal;
  let vy = avoidDecimal;

  for (let image of frames) {
    if (
      isNil(image?.telemetry?.wind_speed) ||
      isNil(image?.telemetry?.wind_direction) ||
      image?.telemetry?.wind_speed < 0 ||
      image?.telemetry?.wind_direction < 0
    ) {
      return { vx: "N/A", vy: "N/A" };
    }
    len++;
    const speed = image.telemetry.wind_speed;
    const direction = image.telemetry.wind_direction;
    const angle = direction * (Math.PI / 180);
    const [vxFrame, vyFrame] = [
      speed * Math.cos(angle),
      speed * Math.sin(angle)
    ];
    vx += vxFrame;
    vy += vyFrame;
  }

  vx -= avoidDecimal;
  vy -= avoidDecimal;
  vx = vx === 0 ? 0 : vx / len;
  vy = vy === 0 ? 0 : vy / len;
  return { vx, vy };
};

export const getAvgSpeed = (averageVx, averageVy) =>
  Math.sqrt(averageVx ** 2 + averageVy ** 2);

export const getAvgDirection = (averageVx, averageVy) => {
  const direction = Math.atan2(averageVy, averageVx) * (180 / Math.PI);
  if (direction < 0) return direction + 360;
  return direction;
};

export const getDifference = (array1, array2) => {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.id === object2.id;
    });
  });
};

export const toXYValues = (values, frames) =>
  values.map((y, i) => {
    const frame = frames[i];
    const x = frame?.createdOn ?? i;
    return { x, y };
  });

export const changeValueOfLastFirstX = data => {
  const firstX = data.at(0).x;
  const lastX = data.at(-1).x;
  const first = subMinutes(new Date(firstX), 1).toISOString();
  const last = addMinutes(new Date(lastX), 1).toISOString();

  const updatedData = cloneDeep(data);
  updatedData[0].x = first;
  updatedData[data.length - 1].x = last;
  return updatedData;
};

export const setAnchor = (
  anchorV,
  anchorH,
  transformV = "top",
  transformH = "left"
) => {
  const anchorOrigin = {};
  const transformOrigin = {
    vertical: transformV,
    horizontal: transformH
  };
  if (anchorV) anchorOrigin.vertical = anchorV;
  if (anchorH) anchorOrigin.horizontal = anchorH;
  return { anchorOrigin, transformOrigin };
};

export const formatDuration = duration => {
  if (isNil(duration) || isNaN(duration)) return "N/A";
  if (duration === 1) return `${duration} minute`;

  return `${duration} minutes`;
};
