import { createAsyncThunk } from "@reduxjs/toolkit";
import isNumber from "lodash/fp/isNumber";

import { request } from "~/request";

export const getCapabilityClass = createAsyncThunk(
  "cameras/getCabilityClass",
  async ({ algoApiVersion, deviceId }) => {
    const { data } = await request(
      `${process.env.REACT_APP_KUVA_API_URL}/cloudalgos/${algoApiVersion}/capability/device/${deviceId}`,
      {
        method: "GET"
      }
    );
    return {
      deviceId,
      capabilityClass: isNumber(data?.capability_class)
        ? data?.capability_class
        : null
    };
  }
);
