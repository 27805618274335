import { styled } from "@mui/styles";

export const ActionButton = styled("button")(({ theme }) => ({
  all: "unset",
  padding: theme.spacing(1, 1.5, 0.5, 1.5),
  marginLeft: theme.spacing(1),
  borderRadius: 3,
  transition: "all 0.2s",
  cursor: "pointer",
  minWidth: 46,
  "&:hover": {
    background: theme.palette.grey[800]
  },
  "&:active": {
    background: theme.palette.grey[900],
    opacity: 0.75
  }
}));
