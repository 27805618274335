import { makeStyles, styled } from "@mui/styles";

import { hexToRgba } from "../../utils/";

export const useButtonStyles = makeStyles(({ spacing, palette, shadows }) => ({
  disabled: {
    background: "inherit",
    borderColor: "initial"
  },
  root: ({ color, size }) => ({
    color: palette.custom[color]?.[400] ?? palette.text.secondary,
    padding: size === "xl" ? 19 : null,
    "&:hover": {
      background:
        palette.custom[color]?.[50] ?? hexToRgba(palette.common.white, 0.08)
    },
    "&.MuiButton-outlinedPrimary": {
      color: palette.custom[color]?.[400] ?? palette.text.secondary
    }
  }),
  label: {
    color: "inherit"
  },
  startIcon: {
    color: "inherit",
    marginRight: spacing(1.25)
  },
  outlined: ({ color }) => ({
    border: `1px solid ${palette.custom[color]?.[400] ?? palette.custom.grey}`,
    background: palette.custom[color]?.[50],
    transition: "all 0.25s",
    boxShadow: shadows[0],
    "&:hover": {
      border: `1px solid ${
        palette.custom[color]?.[400] ?? palette.custom.grey
      }`,
      boxShadow: `${hexToRgba(
        palette.common.black,
        0.13
      )} 0px 6px 5px -3px, ${hexToRgba(
        palette.common.black,
        0.09
      )} 0px 8px 10px 1px, ${hexToRgba(
        palette.common.black,
        0.06
      )} 0px 5px 14px 2px`
    },
    "&.Mui-disabled": {
      borderColor: palette.custom[color]?.[400] ?? palette.custom.grey,
      color: palette.custom[color]?.[400] ?? palette.text.secondary,
      opacity: 0.5,
      boxShadow: shadows[0]
    }
  })
}));

export const LoaderWrapper = styled("div")({
  padding: "3px 14px 4px"
});
