import { Tooltip } from "../Tooltip";
import { SectionTitle } from "../SectionTitle";

export const DisconnectedTooltipHelperContent = () => (
  <>
    <SectionTitle small>Disabled</SectionTitle>
    <p>This camera is currently offline.</p>
  </>
);

/**
 * Optionally wraps children in a Tooltip when the camera is disconnected.
 * When the camera is connected, the tooltip is not rendered.
 */
export const DisconnectedTooltipHelper = ({ children, connected }) => {
  return connected ? (
    children
  ) : (
    <Tooltip title={<DisconnectedTooltipHelperContent />}>{children}</Tooltip>
  );
};
