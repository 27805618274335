import { MenuItem, Select as MuiSelect, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/GetApp";
import clsx from "clsx";
import { PropTypes } from "prop-types";
import { useState } from "react";

import { cameraViewTypes } from "@kuva/image-constants";

import { useStyles } from "./styles";

const SelectDownloadType = ({
  handleDownloadGifClick,
  useIcon,
  dropDownText
}) => {
  const classes = useStyles();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const menuItems = [cameraViewTypes.SWIR, cameraViewTypes.RGB];

  const handleSelectChange = event => {
    setIsSelectOpen(false);
    useIcon
      ? handleDownloadGifClick(event, event.target.value)
      : handleDownloadGifClick(event.target.value);
  };
  const handleOpen = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleClickArrowDown = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  return (
    <MuiSelect
      autoWidth={false}
      className={clsx(
        { [classes.iconPlaceholder]: useIcon },
        { [classes.noIconPlaceholder]: !useIcon }
      )}
      MenuProps={{
        classes: { paper: classes.selectMenu },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        autoFocus: false
      }}
      open={isSelectOpen}
      onOpen={handleOpen}
      onClose={handleOpen}
      onChange={handleSelectChange}
      variant="standard"
      disableUnderline
      value=""
      IconComponent={() =>
        useIcon ? (
          <DownloadIcon
            onClick={handleClickArrowDown}
            className={classes.downloadIcon}
          />
        ) : (
          <div
            className={classes.noIconBackground}
            onClick={handleClickArrowDown}
          >
            <Typography>{dropDownText}</Typography>
          </div>
        )
      }
      displayEmpty
    >
      <MenuItem value="" className={classes.disabled} disabled></MenuItem>
      {menuItems.map(item => {
        return (
          <MenuItem className={classes.menuItem} key={item} value={item}>
            {item.toUpperCase()}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
};

SelectDownloadType.propTypes = {
  handleDownloadGifClick: PropTypes.func,
  useIcon: PropTypes.bool,
  dropDownText: PropTypes.string
};
export default SelectDownloadType;
