import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { get } from "~/selectors/CameraSelector";
import { CameraActions } from "~/store/slices/cameras";

export const useGetScans = ({ deviceId }) => {
  const dispatch = useDispatch();
  const { scans, loading } = useSelector(get, shallowEqual);

  const getScans = useCallback(
    async body => {
      dispatch(
        CameraActions.getCameraScans({
          deviceId,
          ...body
        })
      );
    },
    [dispatch, deviceId]
  );

  return {
    getScans,
    scans,
    loading
  };
};
