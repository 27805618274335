import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const removeSerialNumber = createAsyncThunk(
  "cameras/removeSerialNumber",
  async ({ deviceId }) => {
    await CameraAPI.removeSerialNumber({ deviceId });

    return {
      deviceId
    };
  }
);
