import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

/**
 *
 * @param {object} body -values required for updating the cameras attributes
 * @param {function} rejectWithValue -return an action object with the error value.
 * @returns - data for updating the store values
 */
const thunk = async (body, { rejectWithValue }) => {
  try {
    const { data, status } = await CameraAPI.updateDeviceAttributes(body);
    if (!status === 200)
      throw Error("An error occurred saving camera attributes");
    return data;
  } catch (error) {
    throw rejectWithValue(error);
  }
};

export const updateCameraAttributesByDeviceId = createAsyncThunk(
  "camera/updateCameraAttributesByDeviceId",
  thunk
);
