import { getMonth, getYear } from "date-fns";

export const useParseYearMonth = () => {
  const getYearMonthKey = date => {
    const day = new Date(date);
    return `${getYear(day)}-${getMonth(day) + 1}`;
  };
  const getYearMonth = date => {
    const day = new Date(date);
    return [getYear(day), getMonth(day) + 1];
  };

  return {
    getYearMonthKey,
    getYearMonth
  };
};
