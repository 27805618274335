import makeStyles from "@mui/styles/makeStyles";

import { theme } from "../../theme";
import { hexToRgba } from "../../utils/";

const chipColors = {
  default: {
    color: theme.palette.action.disabled,
    border: theme.palette.action.disabled
  },
  warning: {
    color: theme.palette.custom.orange[400],
    border: theme.palette.custom.orange[400],
    background: hexToRgba(theme.palette.custom.orange[400], 0.15)
  }
};

export const useChipStyles = makeStyles(({ spacing }) => ({
  root: ({ color, size }) => ({
    color: chipColors[color].color ?? chipColors.default.color,
    padding: size === "medium" ? spacing(0.2, 2) : null,
    fontSize: size === "medium" ? 16 : null,

    "& .MuiChip-icon": {
      color: chipColors[color].color ?? chipColors.default.color
    }
  }),
  label: {
    color: "inherit"
  },
  icon: {
    fontSize: "inherit"
  },
  outlined: ({ color }) => ({
    border: `1px solid ${
      chipColors[color].border ?? chipColors.default.border
    }`,
    background: chipColors[color]?.background ?? null
  })
}));
