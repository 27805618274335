import { Tree } from "@minoru/react-dnd-treeview";
import PropTypes from "prop-types";
import { useEffect } from "react";

import DragPreview from "../DragPreview";
import OrganizationNode from "../OrganizationNode";
import Placeholder from "../Placeholder";

import { useStyles } from "./styles";

const OrganizationTree = ({ treeData, handleDrop, orgRef, onClickNode }) => {
  const { classes } = useStyles();

  useEffect(() => {
    treeData.sort((a, b) => {
      return a.orgname.localeCompare(b.orgname);
    });
  }, [treeData]);

  return (
    <div className={classes.root}>
      <Tree
        ref={orgRef}
        tree={treeData}
        rootId={0}
        render={(node, { depth, isOpen, onToggle }) => (
          <OrganizationNode
            node={node}
            depth={depth}
            isOpen={isOpen}
            onToggle={onToggle}
            onClickNode={onClickNode}
          />
        )}
        dragPreviewRender={org => <DragPreview organization={org?.item} />}
        onDrop={handleDrop}
        classes={{
          root: classes.treeRoot,
          draggingSource: classes.draggingSource,
          placeholder: classes.placeholderContainer
        }}
        sort={true}
        insertDroppableFirst={false}
        canDrop={(tree, { dragSource, dropTargetId }) => {
          if (dragSource?.parent === dropTargetId) {
            return true;
          }
        }}
        dropTargetOffset={5}
        placeholderRender={(node, { depth }) => (
          <Placeholder node={node} depth={depth} />
        )}
      />
    </div>
  );
};

OrganizationTree.defaultProps = {
  treeData: [],
  orgRef: {}
};

OrganizationTree.propTypes = {
  treeData: PropTypes.array.isRequired,
  handleDrop: PropTypes.func.isRequired,
  orgRef: PropTypes.object.isRequired,
  onClickNode: PropTypes.func.isRequired
};

export default OrganizationTree;
