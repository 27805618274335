/**
 * Clamps the current position to the edges of the image, ensuring that
 * the image does not render beyond the container's bounds.
 *
 * @param {number} prevX - The previous position.
 * @param {number} deltaX - The change in position.
 * @param {React.RefObject} imageRef - Reference to the image element.
 * @param {React.RefObject} containerRef - Reference to the container element.
 *
 * @returns {number} - The clamped position.
 */
export const getClampedPosition = (prevX, deltaX, imageRef, containerRef) => {
  const maxOffset = -(
    imageRef.current.width - containerRef.current.clientWidth
  );
  const clampedPosition = Math.max(Math.min(prevX + deltaX, 0), maxOffset);

  return clampedPosition;
};
