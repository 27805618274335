import { Button } from "@mui/material";

import { styled } from "@kuva/ui-components";

const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(0, 2, 0, 0)};

  & a {
    cursor: pointer;
  }
`;

export { StyledButton };
