import { isNil } from "lodash";
import { useMemo } from "react";

import { convertGasUnit } from "@kuva/units";

import { useSettings } from "~/hooks";
import { changeValueOfLastFirstX, toXYValues } from "~/utils/dataUtils";

export const useQuantification = alarm => {
  const showTshirtSize = isNil(alarm?.leakSource?.rangeMeters);

  const { leakRateUnit } = useSettings();

  const algoResult = { ...alarm?.quantResult?.resultMB, id: "MB" };
  const { data, reversedFrames } = useMemo(() => {
    // add a time value on last and first points
    const first = alarm?.frames?.at(0);
    const last = alarm?.frames?.at(-1);
    let reversedFrames = [];
    if (alarm?.frames) {
      reversedFrames = [...alarm.frames].reverse();
    }
    const results = algoResult?.values;
    if (!results) return { data: [], reversedFrames };
    const values = [
      0,
      ...results.map(v => convertGasUnit(v, leakRateUnit, false)),
      0
    ];
    const tmpData = toXYValues(values, [first, ...reversedFrames, last]);
    const data = changeValueOfLastFirstX(tmpData);

    return { data, reversedFrames };
    // all missing deps come from alarm
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarm, leakRateUnit]);

  const averageRate = convertGasUnit(
    algoResult?.avg || alarm?.quantification?.avg, // in standard cubic meters (Sm3/d)
    leakRateUnit
  );

  const min = convertGasUnit(algoResult?.avgErrNeg, leakRateUnit);
  const max = convertGasUnit(algoResult?.avgErrNeg, leakRateUnit);
  const avgValue = convertGasUnit(algoResult?.avg, leakRateUnit);
  const avg = { min, avg: avgValue, max };
  const leakAvg = getLeakAvg(averageRate, leakRateUnit);

  return {
    data,
    algoResult,
    reversedFrames,
    avg,
    leakAvg,
    showTshirtSize
  };
};

const getLeakAvg = (averageRate, leakRateUnit) => {
  if (averageRate === "0" || averageRate === "N/A" || !averageRate)
    return "N/A";
  return `${averageRate} ${leakRateUnit}`;
};
