import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { selectSignalRConnection } from "../selectors";

export function useSignalRSubscription(
  SignalRService,
  subscriptionName,
  subscriptionType
) {
  const signalRConnection = useSelector(selectSignalRConnection, shallowEqual);
  const dispatch = useDispatch();

  // Check if signalR connection is established
  const hasConnectionStarted = signalRConnection === "started";
  // Check if signalR client is connected to the BE signalR service
  const connection = SignalRService.signalRInstance?.connection;
  const isConnected = connection?.state === "Connected";
  const isSignalRConnected = hasConnectionStarted && isConnected;

  useEffect(() => {
    if (!isSignalRConnected) {
      console.debug(
        `Unable to subscribe to "${subscriptionType}.${subscriptionName}" : SignalR is not connected.`
      );
      return;
    }

    SignalRService.subscribe(connection, subscriptionName, subscriptionType);

    console.debug(`Subscribed to "${subscriptionType}.${subscriptionName}".`);

    // Set new subscription details
    dispatch({
      type: "SET_SUBSCRIPTION_DETAILS",
      subscriptionDetails: {
        subscriptionType,
        subscriptionName
      }
    });

    return () => {
      if (isSignalRConnected) {
        SignalRService.unsubscribePreviousSubscription(
          connection,
          subscriptionName,
          subscriptionType
        );
      }
    };
  }, [isSignalRConnected, subscriptionName, subscriptionType, dispatch]);
}
