import { Avatar, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

const DragPreview = ({ organization }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Avatar
          className={classes.avatar}
          alt={organization?.orgname}
          src={organization?.logoURL}
        />
      </div>
      <Typography className={classes.label} variant="h6">
        {organization?.orgname}
      </Typography>
    </div>
  );
};

DragPreview.defaultProps = {
  organization: {
    orgname: "",
    logoURL: ""
  }
};

DragPreview.propTypes = {
  organization: PropTypes.shape({
    orgname: PropTypes.string.isRequired,
    logoURL: PropTypes.string.isRequired
  }).isRequired
};

export default DragPreview;
