import { parseISO, differenceInMinutes } from "date-fns";

const ERROR_LIST = {
  "-1": true,
  "-2": true,
  "-3": true,
  "-4": true,
  "-5": true
};
export const getError = errorEvent => {
  if (!errorEvent) return false;
  const time = parseISO(errorEvent.time);
  const newTime = new Date();

  if (differenceInMinutes(newTime, time) > 60) return false;
  if (ERROR_LIST[errorEvent.errorCode]) return true;
  return false;
};
