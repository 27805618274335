import Mail from "@mui/icons-material/Mail";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import ValidationSchema from "./ValidationSchema";
import { useStyles } from "./styles";

const EmailVerificationForm = ({ initialValues, handleSubmit, loading }) => {
  const { classes } = useStyles();

  /* Let Formik handle all the Form state including custom fields */
  const userFormik = useFormik({
    enableReinitialize: true, // We should reset the form if the wrapped component props change (using deep equality).
    initialValues,
    onSubmit: values => {
      handleSubmit(values);
    },
    validationSchema: ValidationSchema
  });

  return (
    <div className={classes.root}>
      <form onSubmit={userFormik.handleSubmit}>
        <TextField
          id="email"
          className={classes.textField}
          name="email"
          label="Email"
          type="text"
          variant="outlined"
          placeholder="Email"
          value={userFormik.values?.email}
          onChange={userFormik.handleChange}
          onBlur={userFormik.handleBlur}
          error={userFormik.touched.email && Boolean(userFormik.errors.email)}
          helperText={userFormik.touched.email && userFormik.errors.email}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Mail />
              </InputAdornment>
            )
          }}
        />
        <Button
          className={classes.btnSubmit}
          variant="outlined"
          type="submit"
          color="primary"
          size="small"
          /* Disabled until all the fields are valid and if the fields values have been changed from their initial values */
          disabled={!(userFormik.isValid && userFormik.dirty)}
        >
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            "RESEND VERIFICATION EMAIL"
          )}
        </Button>
      </form>
    </div>
  );
};

EmailVerificationForm.defaultProps = {
  initialValues: {
    email: ""
  },
  loading: false
};

EmailVerificationForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default EmailVerificationForm;
