import { Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { makeStyles, styled } from "@kuva/ui-components";

export const Text = styled(Typography)(({ maxWidth }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  position: "relative",
  transform: "translateY(1px)",
  maxWidth
}));

const useStyles = makeStyles()({
  customTooltip: {
    fontSize: "12px",
    opacity: 0.8,
    background: "gray",
    "& .MuiTooltip-arrow": {
      color: "gray"
    }
  }
});

const TruncatedTextWithTooltip = ({
  children: textContent,
  minWidth,
  breakpoinResolution = 1280 // default to 720p screen resolution
}) => {
  const textRef = useRef(null);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [maxWidth, setMaxWidth] = useState("100%");
  const { classes } = useStyles();
  useEffect(() => {
    const handleResize = () => {
      const typographyElement = textRef.current;
      const screenWidth = window.innerWidth;

      if (screenWidth <= breakpoinResolution) {
        setMaxWidth(minWidth);
        setTooltipVisible(
          typographyElement.scrollWidth > typographyElement.clientWidth
        );
      } else {
        setMaxWidth("100%");
        setTooltipVisible(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Tooltip
      title={isTooltipVisible ? textContent : ""}
      placement="top"
      arrow
      classes={{ tooltip: classes.customTooltip }}
    >
      <Text ref={textRef} maxWidth={maxWidth}>
        {textContent}
      </Text>
    </Tooltip>
  );
};

TruncatedTextWithTooltip.propTypes = {
  textContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minWidth: PropTypes.string.isRequired,
  breakpoinResolution: PropTypes.number,
  children: PropTypes.node
};

export default TruncatedTextWithTooltip;
