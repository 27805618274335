import { Navigation as NavigationIcon } from "@mui/icons-material";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

export const WindCamera = ({ windDirection = 0 }) => {
  const classes = useStyles(windDirection + 180);
  return (
    <div className={classes.doughnut}>
      <span className={classes.camera}>
        <span className={classes.cameraTop} />
        <span className={classes.cameraView} />
        <NavigationIcon className={classes.rotation} />
      </span>
    </div>
  );
};

WindCamera.propTypes = {
  windDirection: PropTypes.number.isRequired
};

WindCamera.defaultProps = {
  windDirection: 0
};

export default WindCamera;
