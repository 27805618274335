import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

import { styled } from "@kuva/ui-components";

import KuvaTooltip from "~/common/components/tootip/KuvaTooltip";

const Root = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "flex-end",
  alignSelf: "center",
  "& .MuiSvgIcon-root": {
    marginLeft: spacing(1),
    marginRight: spacing(2.5)
  }
}));

const TABLE_INFO_TOOLTIP =
  "This table shows all cameras in your organization's fleet. The columns display various items such as the camera name and status. You can customize the table columns using the kebab options, and switch between a general or support view with the toggle for additional telemetric data. Use the search function to quickly find cameras.";

export const TableName = ({
  tableName = "Event List",
  tooltip = TABLE_INFO_TOOLTIP
}) => {
  return (
    <Root>
      <Typography>{tableName}</Typography>
      <KuvaTooltip placement="right-start" title={tableName} text={tooltip}>
        <InfoIcon />
      </KuvaTooltip>
    </Root>
  );
};

TableName.propTypes = {
  tableName: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};
