import isNil from "lodash/isNil";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { PointsOfInterestFrame } from "./PointsOfInterestFrame";
import { FramesContainer } from "./styled-components";

const PointsOfInterestFramesContainerPropTypes = {
  homePosition: PropTypes.number,
  onMoveHome: PropTypes.func,
  onMovePointOfInterest: PropTypes.func,
  onSelectHome: PropTypes.func,
  onSelectPointOfInterest: PropTypes.func,
  pointsOfInterest: PropTypes.array,
  readOnly: PropTypes.bool,
  selectedFrame: PropTypes.number,
  scaleFactor: PropTypes.number,
  touchedFrames: PropTypes.object
};

/**
 * Renders all frames and keeps track of the selected frame. Uses forwardRef
 * since each frame needs access to the container ref.
 */
export const PointsOfInterestFramesContainer = forwardRef((props, ref) => {
  const {
    homePosition,
    onMoveHome = noop,
    onMovePointOfInterest = noop,
    onSelectHome = noop,
    onSelectPointOfInterest = noop,
    pointsOfInterest = [],
    readOnly = false,
    selectedFrame = 0,
    scaleFactor = 0.5,
    touchedFrames = {},
    ...attrs
  } = props;
  const isHomeTouched = touchedFrames[0];

  const handleSelectHome = event => onSelectHome(event);
  const handleSelectPointOfInterest = idx => event => {
    const payload = {
      idx,
      panCenterPosition: pointsOfInterest[idx]
    };

    onSelectPointOfInterest(payload, event);
  };

  return (
    <FramesContainer {...attrs} ref={ref}>
      {!isNil(homePosition) && (
        <PointsOfInterestFrame
          active={selectedFrame === 0}
          forwardedRef={ref}
          offsetDegrees={homePosition}
          onClick={handleSelectHome}
          onMove={onMoveHome}
          readOnly={readOnly}
          scaleFactor={scaleFactor}
          isTouched={isHomeTouched}
          home
        />
      )}

      {pointsOfInterest?.map((offsetDegrees, idx) => {
        const isTouched = touchedFrames[idx + 1];
        return (
          <PointsOfInterestFrame
            active={selectedFrame === idx}
            forwardedRef={ref}
            id={idx + 1}
            key={idx}
            offsetDegrees={offsetDegrees}
            onClick={handleSelectPointOfInterest(idx)}
            onMove={onMovePointOfInterest}
            readOnly={readOnly}
            scaleFactor={scaleFactor}
            isTouched={isTouched}
          />
        );
      })}
    </FramesContainer>
  );
});

PointsOfInterestFramesContainer.displayName = "PointOfInterestFramesContainer";
PointsOfInterestFramesContainer.propTypes =
  PointsOfInterestFramesContainerPropTypes;
