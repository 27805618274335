import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { format } from "date-fns";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles, useSnackbar } from "@kuva/ui-components";

import Loading from "~/common/components/indicators/Loading";
import { updateAbility } from "~/configuration/ability";
import { flags } from "~/constants/feature-flags";
import { AbilityContext } from "~/contextProviders/Can";
import { useOrganization } from "~/hooks";
import { request } from "~/request";
import { UserNotificationActions } from "~/store/slices/user-notifications";
import { getStrapiToken, setAuthToken, setSignalRURL } from "~/utils/api";

const useStyles = makeStyles()(({ palette }) => ({
  title: {
    textAlign: "center",
    color: palette.common.white
  },
  spinnerHold: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    color: palette.common.white
  },
  spinner2: {
    transform: "translate(-50%, -50%)",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));

const AppInitialize = ({
  setAppInitialized,
  setAppInitializing,
  appInitialized,
  setAppError
}) => {
  const { classes } = useStyles();
  const ability = useContext(AbilityContext);

  const { showSnackbar } = useSnackbar();

  const { getAccessTokenSilently, user, isAuthenticated, logout } = useAuth0();
  const dispatch = useDispatch();

  const { setOrganizations } = useOrganization();

  const isTimeoutFeatureOn = useFeatureIsOn(flags.TIMEOUT);

  const initializeApplication = async () => {
    try {
      //1. Get Auth0 token
      const token = await getAccessTokenSilently({});
      const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
      Cookies.set("kuva.auth.jwt", token, { expires: inOneHour });

      setAuthToken(token);

      //2. Get organizations
      const orgData = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/organizations`
      );

      if (orgData.status === 204 || !orgData.data.length) {
        throw new Error("Default organization not found for this user!");
      }

      setOrganizations(orgData.data);

      setSignalRURL(user);

      fetchStrapiToken(user);

      //3. set up user permissions
      updateAbility(ability, user);
      //4. set app as initialized

      setAppInitialized(true);
      setAppInitializing(false);
    } catch (error) {
      console.error("initializeApplication error: ", error);
      setAppError({
        title: "Error!",
        message: error.message ?? "Unknown error",
        code: 123
      });
    }
  };

  const fetchStrapiToken = async user => {
    await getStrapiToken(user);
    const today = format(new Date(), "yyyy-MM-dd");
    dispatch(UserNotificationActions.fetchUserNotificationsByDate(today));
  };

  useEffect(() => {
    setAppInitializing(true);
    initializeApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isAuthenticated || !appInitialized) {
        if (isTimeoutFeatureOn) {
          localStorage.clear();
          showSnackbar("Your session was ended. Please log in again.", {
            variant: "warning"
          });
          setTimeout(() => {
            logout({ returnTo: window.location.origin });
          }, 3000);
        }
      }
    }, 30000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.spinnerHold}>
      <div className={classes.spinner2}>
        <Loading />
      </div>
    </div>
  );
};

AppInitialize.propTypes = {
  setAppInitialized: PropTypes.func.isRequired,
  setAppInitializing: PropTypes.func.isRequired,
  setAppError: PropTypes.func.isRequired,
  appInitialized: PropTypes.bool.isRequired
};

export default AppInitialize;
