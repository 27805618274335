const descendingComparator = (a, b, orderBy) => {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  if (!isNaN(aValue) && !isNaN(bValue)) return bValue - aValue;
  if (!aValue) return -1;
  if (!bValue) return 1;

  return bValue.localeCompare(aValue);
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};
