import { Collapse, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@kuva/ui-components";

import Alert from "~/components/Alert";
import Button from "~/components/Button";
import { getUserNotifications } from "~/selectors/UserNotificationSelector";
import { UserNotificationActions } from "~/store/slices/user-notifications";

import { getActiveNotification } from "./utils";

const useStyles = makeStyles()(() => ({
  root: {
    position: "absolute",
    top: 90, // offset header height
    right: 10,
    zIndex: 2000
  }
}));

const UserNotification = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const selectedUserNotifications = useSelector(
    getUserNotifications,
    shallowEqual
  );
  const activeNotification = getActiveNotification(selectedUserNotifications);

  const handleCloseNotification = () => {
    dispatch(
      UserNotificationActions.setIsReadNotification(activeNotification.key)
    );
  };

  return (
    <Collapse
      className={classes.root}
      key={activeNotification?.key}
      in={Boolean(activeNotification?.id) && !activeNotification?.isRead}
    >
      <Alert
        severity={activeNotification?.type}
        actionButtons={
          <>
            {activeNotification?.url && (
              <Button
                variant="contained"
                type={activeNotification?.type}
                onClick={() =>
                  window.open(activeNotification?.url, "_blank").focus()
                }
              >
                <Typography>View</Typography>
              </Button>
            )}
          </>
        }
        message={activeNotification?.shortMessage}
        onClose={handleCloseNotification}
      />
    </Collapse>
  );
};

export default UserNotification;
