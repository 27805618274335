import makeStyles from "@mui/styles/makeStyles";

const radius = 38;

function degreeToRad(degree) {
  return (degree * Math.PI) / 180;
}

function getX(degree) {
  const x = Math.sin(degreeToRad(-degree)) * radius + 10;
  return x.toFixed(2);
}

function getY(degree) {
  const deg = degree >= 90 && degree <= 270 ? -degree : degree;
  const y = Math.cos(degreeToRad(deg)) * radius + 10;
  return y.toFixed(2);
}

const commonCamera = {
  width: 22,
  display: "block",
  margin: "auto"
};

const commonCircle = {
  borderRadius: 50,
  height: 100,
  width: 100,
  margin: "0 6px",
  border: `2px solid white`,
  display: "flex"
};

const useStyles = makeStyles(({ spacing }) => ({
  doughnut: {
    ...commonCircle,
    background: "rgba(255,255,255,0.2)",
    justifyContent: "center",
    placeItems: "center",
    boxShadow: "0px 0px 20px rgba(18, 18, 18, 0.35)"
  },
  camera: {
    background: "white",
    width: spacing(5),
    height: spacing(3.5),
    borderRadius: 3,
    marginTop: 6
  },
  cameraTop: {
    ...commonCamera,
    background: "white",
    height: spacing(2),
    position: "relative",
    bottom: 8
  },
  cameraView: {
    ...commonCamera,
    width: spacing(3),
    height: spacing(3.5),
    marginTop: -50,
    background: "rgba(255, 119, 0,0.2)",
    transform: "perspective(8px) rotateX(-7deg)",
    border: `solid 1px rgb(255, 119, 0)`,
    borderTop: "transparent"
  },
  rotation: {
    fontSize: 18,
    transform: windDirection => `rotate(${windDirection}deg)`,
    position: "relative",
    translate: windDirection =>
      `${getX(windDirection)}px ${getY(windDirection)}px`
  }
}));

export { useStyles };
