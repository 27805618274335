import StrapiAPI from "./StrapiAPI";

class UserNotificationAPI {
  constructor() {
    this.axiosInstance = StrapiAPI.axiosInstance;
  }

  static instance = null;

  static Instance = () => this.instance ?? new UserNotificationAPI();

  getUserNotifications = startDate => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/all-notifications-by-start-date/${startDate}`,
      "color: #9954E3"
    );
    return this.axiosInstance.get(
      `/all-notifications-by-start-date/${startDate}`
    );
  };
}

export default UserNotificationAPI.Instance();
