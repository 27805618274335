import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
    overflowX: "hidden"
  }
});

export { useStyles };
