import ButtonMUI from "@mui/material/Button";
import PropTypes from "prop-types";

import { styled, theme } from "@kuva/ui-components";

import { notifTypes } from "./Alert";

function Button({ children, variant, type, onClick }) {
  const buttonSeverity = {
    [notifTypes.OUTAGE]: {
      background: theme.palette.warning.main,
      "&:hover": {
        backgroundColor: theme.palette.warning.main
      }
      /* No design for
        case "success":
        case "info":
        case "error":
      */
    },
    [notifTypes.INFORMATIONAL]: {
      background: "#74AE3E",
      "&:hover": {
        backgroundColor: "#74AE3E"
      }
    },
    [notifTypes.RELEASE_NOTES]: {
      background: "#74AE3E",
      "&:hover": {
        backgroundColor: "#74AE3E"
      }
    },
    blue: {
      background: "#008FBE",
      "&:hover": {
        backgroundColor: "#008FBE"
      }
    },
    gray: {
      background: "#A6A6A6",
      "&:hover": {
        backgroundColor: "#A6A6A6"
      }
    }
  };
  const StyledButton = styled(ButtonMUI)(({ severity }) => ({
    minWidth: "100px",
    height: "39px",
    color: "#FFFFFF",
    borderRadius: "4px",
    ...buttonSeverity[severity]
  }));

  return (
    <StyledButton variant={variant} severity={type} onClick={onClick}>
      {children}
    </StyledButton>
  );
}

Button.defaultProps = {
  children: "Click Me!"
};

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["outlined", "contained", "text"]),
  type: PropTypes.oneOf([
    notifTypes.OUTAGE,
    notifTypes.INFORMATIONAL,
    notifTypes.RELEASE_NOTES,
    "blue",
    "gray"
  ])
};

export default Button;
