import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

import {
  AlphaDownIcon,
  AlphaUpIcon,
  NumericDownIcon,
  NumericUpIcon,
  makeStyles
} from "@kuva/ui-components";

import { SortAscIcon, SortDescIcon } from "~/components/Table/SvgIcons";

const useStyles = makeStyles()(({ spacing }) => ({
  iconButton: {
    padding: 0,
    width: 28,
    height: 28
  },
  tableIconButton: {
    paddingBottom: spacing(0.5)
  }
}));

const SortButton = ({ handleClick, manualSetting, sortIcon }) => {
  const { classes, cx } = useStyles();

  const sortIconTypes = {
    alpha: "alpha",
    numeric: "numeric"
  };

  const [sortedAsc, setSortedAsc] = useState(true);

  const onClick = () => {
    handleClick();
    setSortedAsc(!sortedAsc);
  };

  const Icon = () => {
    if (manualSetting !== undefined) {
      if (sortIcon === sortIconTypes.alpha) {
        return manualSetting ? <AlphaDownIcon /> : <AlphaUpIcon />;
      }
      if (sortIcon === sortIconTypes.numeric) {
        return manualSetting ? <NumericDownIcon /> : <NumericUpIcon />;
      }

      return manualSetting ? <SortDescIcon /> : <SortAscIcon />;
    }
    return sortedAsc ? <SortDescIcon /> : <SortAscIcon />;
  };

  return (
    <IconButton
      data-testid="sort-button"
      className={cx(classes.iconButton, {
        [classes.tableIconButton]: sortIcon
      })}
      onClick={onClick}
      size="large"
    >
      {<Icon />}
    </IconButton>
  );
};

SortButton.propTypes = {
  handleClick: PropTypes.func,
  manualSetting: PropTypes.bool,
  sortIcon: PropTypes.string
};

export default SortButton;
