import MaterialTooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

export const useTooltipStyles = makeStyles(({ shadows, spacing, palette }) => ({
  arrow: {
    "&::before": {
      border: `1px solid #444`,
      background: palette.background.default
    }
  },
  tooltip: ({ small }) => ({
    border: `1px solid #444`,
    background: palette.background.default,
    padding: small ? spacing(0.75, 1.5, 0.5) : spacing(2, 2),
    fontSize: 14,
    color: "#ccc",
    lineHeight: 1.7,
    boxShadow: shadows[6],

    "& p:first-of-type": {
      marginTop: !small && spacing(1)
    },

    "& p:last-of-type": {
      marginBottom: !small && spacing(0.25)
    }
  })
}));

export const Tooltip = ({ children, small = false, ...props }) => {
  const classes = useTooltipStyles({ small });

  return (
    <MaterialTooltip
      arrow
      placement="bottom-start"
      classes={classes}
      // defaulted props can be overriden
      {...props}
    >
      {children}
    </MaterialTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  small: PropTypes.bool
};
