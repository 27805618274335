const RGB = "rgb";
const SWIR = "swir";

const cameraViewTypes = {
  RGB,
  SWIR
};

module.exports = {
  cameraViewTypes,
  RGB,
  SWIR
};
