// straight import from utils does not work due to getHaloColor requiring theme
import { hexToRgba } from "./utils/hex-to-rgba";

// Only theme should be exported
const commonColors = {
  white: "#FFF",
  black: "#000",
  disabled: "#A6A6A6",
  primary: "#CE6641",
  success: "#74AE3E"
};
// Please avoid using these colours directly in your components
// The goal is to deprecate these colours in the future
const customColors = {
  grey: "#808080",
  orange: {
    50: "#30292A",
    100: "#503E37",
    200: "#75493F",
    300: "#9F5243",
    400: commonColors.primary,
    500: "#D07F5F",
    600: "#D3967B",
    700: "#D8AC97",
    800: "#DEC0B0",
    900: "#E6D3C9",
    1000: "#EFE5DF"
  },
  brightOrange: {
    50: "#392423",
    100: "#5F2D26",
    200: "#8D3621",
    300: "#C24114",
    400: "#FF5100",
    500: "#F76F2A",
    600: "#F18B52",
    700: "#EEA477",
    800: "#EDBB9A",
    900: "#EFD0BA",
    1000: "#F4E4D8"
  },
  green: {
    50: "#243023",
    100: "#334B2E",
    200: "#446937",
    300: "#598A3C",
    400: commonColors.success,
    500: "#85B359",
    600: "#95BA73",
    700: "#A6C28C",
    800: "#B6CAA3",
    900: "#C7D4BA",
    1000: "#D7DFCF"
  },
  red: {
    50: "#241A1E",
    100: "#452730",
    200: "#6B2E3C",
    300: "#96313F",
    400: "#C72E34",
    500: "#CA4F51",
    600: "#CE6E6E",
    700: "#D38D8C",
    800: "#DAAAA8",
    900: "#E3C5C3",
    1000: "#EDDDDC",
    A100: "#7d2c301A",
    A200: "#7d2c300D"
  },
  blue: {
    400: "#008FBE"
  },
  background: {
    grey: "#2d2d2d"
  },
  border: {
    primary: `0.5px solid ${commonColors.disabled}`,
    faint: `1px solid ${hexToRgba(commonColors.disabled, 0.25)}`
  }
};

export const theme = {
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            color: commonColors.success
          },
          "&.Mui-checked+.MuiSwitch-track": {
            background: commonColors.success
          }
        }
      }
    }
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 1130,
      lg: 1440,
      xl: 1920
    }
  },
  palette: {
    mode: "dark",
    common: {
      black: commonColors.black,
      white: commonColors.white
    },
    primary: {
      main: commonColors.primary, // user interactions, error states, negative outcomes, highlights, "notice me"
      dark: "#372722",
      light: "#D78467",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    secondary: {
      main: "#FF5100", // limited usage, primarily when needed to stand out against the regular orange
      dark: "#B23800",
      light: "#FF7333",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    error: {
      main: "#C72E34", // for when a page uses orange in a way that would cause confusion with errors
      dark: "#C62828",
      light: "#D2575C",
      contrastText: commonColors.white
    },
    warning: {
      main: commonColors.primary,
      dark: "#372722",
      light: "#D78467",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    info: {
      main: "#008FBE",
      dark: "#006485",
      light: "#33A5CB",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    success: {
      main: commonColors.success, // user interactions, success or online states, "all good"
      dark: "#364428",
      light: "#8FBE64",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    text: {
      primary: commonColors.white, // text, titles
      secondary: commonColors.disabled, // text, outlines, separators, disabled content
      disabled: commonColors.disabled,
      icon: commonColors.white
    },
    background: {
      default: "#222",
      paper: "#333"
    },
    divider: hexToRgba(commonColors.disabled, 0.5),
    action: {
      active: commonColors.white,
      hover: "rgba(255, 255, 255, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(255, 255, 255, 0.08)",
      selectedOpacity: 0.08,
      disabled: commonColors.disabled,
      disabledBackground: "#008FBE",
      disabledOpacity: 0.38,
      focus: "rgba(255, 255, 255, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    tonalOffset: 0.2,
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#e5e5e5",
      300: "#d4d4d4",
      400: "#a3a3a3",
      500: "#737373",
      600: "#525252",
      700: "#404040",
      800: "#262626",
      900: "#171717",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
      A900: "#2A2A2A"
    },
    custom: customColors
  },

  typography: {
    htmlFontSize: 12,
    fontFamily: [
      "Roboto",
      "century-gothic",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(","),
    fontSize: 10,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    body1: {
      fontSize: 16
    },
    body2: {
      fontSize: 14
    },
    subtitle1: {
      fontSize: 16
    },
    subtitle2: {
      fontSize: 14
    },
    overline: {
      fontSize: 12
    },
    caption: {
      fontSize: 12
    },
    h6: {
      fontSize: 20
    },
    h5: {
      fontSize: 24
    },
    h4: {
      fontSize: 34
    },
    h3: {
      fontSize: 48
    },
    h2: {
      fontSize: 60
    },
    h1: {
      fontSize: 96
    }
  }
};
