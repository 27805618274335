import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useState } from "react";

import {
  ArrowCircleDownIcon,
  ArrowCircleDownOutlineIcon,
  ArrowCircleUpIcon,
  ArrowCircleUpOutlineIcon
} from "~/components/Table/SvgIcons";

import { swapColumns } from "../table-utils";

import { useVisibilityOptionStyles } from "./styles";

export default function VisibilityOption({
  index,
  field,
  columns,
  setColumns
}) {
  const { classes } = useVisibilityOptionStyles();
  const [expanded, setExpanded] = useState(true);

  const handleClickRemoveCol = () => {
    const newColumns = columns.map(col =>
      col.field === field ? { ...col, columnVisibility: false } : col
    );
    setColumns(newColumns);
  };

  // Keeping two separate functions makes it easier to understand what each of them does
  const handleClickMoveLeft = (columns, index) => {
    const newColumns = swapColumns(columns, index, index - 1);
    setColumns(newColumns);
  };

  const handleClickMoveRight = (columns, index) => {
    const newColumns = swapColumns(columns, index, index + 1);
    setColumns(newColumns);
  };

  return (
    <Box className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="visibility-option-control"
          id="panel1bh-header-visiblity-option"
        >
          <Typography className={classes.heading}>Column Visibility</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box id="visibility-option-control">
            <Box className={classes.arrowButtonsContainer}>
              {index !== 0 ? (
                <ArrowCircleDownIcon
                  className={classes.defaultArrowIcon}
                  onClick={() => handleClickMoveLeft(columns, index)}
                />
              ) : (
                <ArrowCircleUpOutlineIcon className={classes.arrowDisabled} />
              )}

              {index === columns?.length - 1 ? (
                <ArrowCircleDownOutlineIcon className={classes.arrowDisabled} />
              ) : (
                <ArrowCircleUpIcon
                  className={classes.defaultArrowIcon}
                  onClick={() => handleClickMoveRight(columns, index)}
                />
              )}
            </Box>
            <Typography
              className={classes.actionText}
              onClick={handleClickRemoveCol}
            >
              Remove Column
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

VisibilityOption.propTypes = {
  index: PropTypes.number,
  field: PropTypes.string,
  columns: PropTypes.array,
  setColumns: PropTypes.func
};
