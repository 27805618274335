import { useAuth0 } from "@auth0/auth0-react";
import { Button as MUIButton, Paper } from "@mui/material";
import { createRef, useEffect, useState } from "react";

import { Button, makeStyles } from "@kuva/ui-components";

import Loading from "../../common/components/indicators/Loading";
import companyLogo from "../../media/Kuva_PrimaryLogo_Rev.png";
import logo from "../../media/Kuva_Symbol_Rev.png";

const useStyles = makeStyles()(({ palette }) => ({
  root: {
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: "flex",
    justifyContent: "center",
    borderRadius: 0,
    backgroundColor: "#242E3B"
  },
  button: {
    margin: "auto",
    width: "20%",
    height: "auto",
    backgroundColor: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
  image: {
    width: "50%",
    height: "auto",
    display: "block"
  },
  version: {
    position: "absolute",
    bottom: 5,
    right: 5
  },
  spinnerHold: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    color: palette.common.white
  },
  spinner2: {
    transform: "translate(-50%, -50%)",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));

const Landing = () => {
  const { classes } = useStyles();
  const { loginWithRedirect, isLoading } = useAuth0();
  const buttonFocus = createRef();
  const [position, setPosition] = useState(0);
  const [mouseXPercent, setMouseXPercent] = useState(0);
  const [mouseYPercent, setMouseYPercent] = useState(0);
  const disableAnimation = true;

  const rotation = !disableAnimation
    ? { transform: `rotate(${position}deg)` }
    : {};

  const handleClickLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.href
      }
    });
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleClickLogin();
    }
  };

  useEffect(() => {
    if (buttonFocus?.current) buttonFocus.current.focus();
  }, [buttonFocus]);

  const handleMouseMove = e => {
    let logoCenter = [window.innerWidth / 2, window.innerHeight / 2];
    let angle =
      Math.atan2(e.pageX - logoCenter[0], -(e.pageY - logoCenter[1])) *
      (180 / Math.PI);
    setPosition(angle + 90);
    setMouseXPercent(Math.round((e.pageX / window.innerWidth) * 100));
    setMouseYPercent(Math.round((e.pageY / window.innerHeight) * 100));
  };

  if (isLoading) {
    return (
      <div className={classes.spinnerHold}>
        <div className={classes.spinner2}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <Paper
      className={classes.root}
      onMouseMove={disableAnimation ? handleMouseMove : {}}
    >
      <div
        style={{
          background: disableAnimation
            ? "transparent"
            : `radial-gradient(at ${mouseXPercent}% ${mouseYPercent}%, #423f35,#000000)`,
          position: "fixed",
          top: "0px",
          left: "0px",
          height: "100%",
          width: "100%"
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: 50,
          left: 50,
          // opacity: position > 0 && position < 90 ?  1 : (Math.abs(Math.abs(position)-180)/180)
          opacity: 1
        }}
      >
        <img className={classes.image} alt={"name"} src={companyLogo} />
      </div>
      <div
        style={{
          position: "absolute",
          top: 50,
          right: 50,
          display: "flex",
          flexDirection: "row",
          // opacity: position > 90 && position < 180 ?  1 : 0.4
          opacity: 1
        }}
      >
        <Button onClick={handleClickLogin}>Log In</Button>
        <Button href="https://www.kuvasystems.com/"> Learn More </Button>
      </div>
      <MUIButton
        ref={buttonFocus}
        style={rotation}
        className={classes.button}
        onKeyDown={handleKeyDown}
        onClick={handleClickLogin}
        disableFocusRipple={true}
      >
        <img className={classes.image} alt={""} src={logo} />
      </MUIButton>
      {/* hide version number for now until it's useful
      <Typography className={classes.version} variant="caption">
        Version {process.env.REACT_APP_VERSION}
      </Typography> */}
    </Paper>
  );
};

export default Landing;
