import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

const thunk = async payload => await AlarmAPI.getLatestAlarmPerDevice(payload);

export const getLatestAlarmsPerDevice = createAsyncThunk(
  "cameras/getLatestAlarmsPerDevice",
  thunk
);
