const intlFormatNumber = (num, digits = 0, locale = "en-US") => {
  return Number(num).toLocaleString(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  });
};

module.exports = {
  intlFormatNumber
};
