import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  textField: {
    height: "36px",
    width: "100%"
  },
  input: {
    border: "1px solid #A6A6A6",
    color: "#A6A6A6",
    height: "36px"
  }
});

export const SearchBar = ({ requestSearch, searchFields, ...props }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root} {...props}>
      <TextField
        className={classes.textField}
        variant="outlined"
        placeholder="Search"
        onInput={e => requestSearch(e.target.value, searchFields)}
        InputProps={{
          className: classes.input,
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

SearchBar.propTypes = {
  requestSearch: PropTypes.func.isRequired,
  searchFields: PropTypes.arrayOf(PropTypes.string)
};
