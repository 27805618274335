import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const unassignStreamDevice = createAsyncThunk(
  "cameras/unassignStreamDevice",
  async ({ streamId }, thunkAPI) => {
    try {
      const response = await CameraAPI.unassignStreamDevice({ streamId });

      return { stream: response.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);
