import { styled } from "@mui/styles";

export const StyledContentWrapper = styled("div")(
  ({ theme: { spacing, palette }, width }) => ({
    padding: spacing(1, 2),
    border: palette.custom.border.primary,
    backgroundColor: "#222",
    minWidth: 300,
    width: width || "auto",
    borderRadius: 4,
    boxSizing: "border-box"
  })
);
