import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import { useSnackbar } from "@kuva/ui-components";

import { flags } from "~/constants/feature-flags";
import { request } from "~/request";

import companyLogo from "../../media/Kuva_PrimaryLogo_Rev.png";

import EmailVerificationForm from "./components/Form";
import { useStyles } from "./styles";

const EmailVerification = () => {
  const { classes } = useStyles();
  const { showSnackbar } = useSnackbar();
  const { loginWithRedirect, user } = useAuth0();

  const userApiVersion = useFeatureIsOn(flags.USER_API_V2) ? "v2" : "v1";

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: ""
  };

  const sendVerification = async email => {
    setIsLoading(true);

    try {
      const { data } = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/${userApiVersion}/user/send-verification/`,
        {
          method: "POST",
          data: { email: email }
        }
      );
      if (data?.error) throw new Error(data?.error);
      showSnackbar("Email Verification sent! Please check your email.");
    } catch (error) {
      showSnackbar(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = values => {
    sendVerification(values.email);
  };

  const handleContinue = () => {
    loginWithRedirect(/* {
      redirect_uri: window.location.href
    }*/)
      .then(res => {
        console.log("success", res);
      })
      .catch(err => console.error("handleContinue", err));
  };

  useEffect(() => {
    user && window.location.replace("/#/");
  }, [user]);

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <img className={classes.logo} alt={"Company Logo"} src={companyLogo} />
        <Box>
          <Typography variant="subtitle1">Verify Your Email</Typography>
          <Typography variant="body1">
            You need to verify your email to continue. If you have not received
            the verification email, please check your &quot;Spam&quot; folder
          </Typography>
          <Typography variant="body1">or resend the email now.</Typography>
        </Box>
        <Box>
          <EmailVerificationForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            loading={isLoading}
          />
        </Box>
        <Box>
          <Button className={classes.btnContinue} onClick={handleContinue}>
            Continue
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default EmailVerification;
