import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "~/request";

export const updateOrganization = createAsyncThunk(
  "organization/updateOrganization",
  async (payload, { rejectWithValue }) => {
    try {
      const orgResponse = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/organizations`,
        {
          method: "POST",
          data: payload
        }
      );

      const organizationsResponse = await request(
        `${process.env.REACT_APP_KUVA_API_URL}/organization/v2/organizations`
      );

      const { id, parent, orgname, logoURL } = orgResponse.data;

      return {
        id,
        parent,
        orgname,
        logoURL,
        organizations: organizationsResponse.data
      };
    } catch (error) {
      console.error(error);

      throw rejectWithValue(error);
    }
  }
);
