import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const updateCameraLocation = createAsyncThunk(
  "cameras/updateCameraLocation",
  async (payload, thunkAPI) => {
    try {
      const updatedLocation = await CameraAPI.updateCameraLocation(payload);

      return updatedLocation.data.response;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);
