import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

import { useOrganization } from "~/hooks";
import logo from "~/media/Kuva_PrimaryLogo_Rev.png";

import { useStyles } from "./NavBar/styles";

const HomeButton = () => {
  const { classes } = useStyles();
  const { selectedOrg } = useOrganization();

  return (
    <Button
      className={classes.logoLink}
      component={NavLink}
      to={`/${selectedOrg?.id ?? ""}`}
    >
      <img alt="logo" className={classes.logo} src={logo} />
    </Button>
  );
};

export default HomeButton;
