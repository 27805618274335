import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles, styled } from "@mui/styles";

import { Tooltip } from "../Tooltip";

export const InfoSolidIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  marginLeft: theme.spacing(1),
  position: "relative",
  top: 6
}));

export const useTooltipHintStyles = makeStyles(({ spacing, palette }) => ({
  tooltipIcon: {
    cursor: "pointer",
    transition: "0.15s opacity",
    marginLeft: spacing(1),
    position: "relative",
    top: 1,

    "&:hover": {
      opacity: 0.7
    }
  },
  tooltipOutlinedIcon: {
    color: palette.text.secondary,
    cursor: "pointer",
    fontSize: 20,
    padding: spacing(0.25),
    transition: "0.15s opacity",
    position: "relative",
    top: 1,

    "&:hover": {
      opacity: 0.7
    }
  }
}));

export const TooltipHint = ({ outlinedIcon = false, ...props }) => {
  const classes = useTooltipHintStyles();

  return (
    <Tooltip {...props}>
      {outlinedIcon ? (
        <InfoOutlinedIcon className={classes.tooltipOutlinedIcon} />
      ) : (
        <InfoSolidIcon className={classes.tooltipIcon} />
      )}
    </Tooltip>
  );
};
