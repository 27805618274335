import { createAsyncThunk } from "@reduxjs/toolkit";

import { Camera } from "~/models/Camera";
import CameraAPI from "~/apis/CameraAPI";
import { DeviceAttributeActions } from "~/store/slices/device-attribute";

const thunk = async ({ orgId, showArchived }, { dispatch, getState }) => {
  const state = getState();
  const { organizations } = state.organizationState;
  const response = await CameraAPI.get(orgId, showArchived);

  let initialReported = [];
  let deviceStatus = {};

  const cameras = response.data.map(c => {
    if (c.properties?.reported?.camera) {
      const updateObject = {
        deviceId: c.deviceId,
        properties: {
          reported: c.properties?.reported?.camera
        }
      };
      initialReported.push(updateObject);
    }

    const status = { [c.deviceId]: { connectionState: c.connectionState } };
    deviceStatus = { ...deviceStatus, ...status };
    return new Camera(c);
  });

  /* Dispatch only once */
  /*###########################################################################################*/
  // [ModuleTwin] Set initial Reported Attribute - it will be updated via signalR service
  // TODO (Jan): move this to module twin api - dispatch({ type: "SET_INITIAL_REPORTED", reported: initialReported })

  // Set initial device status - it will be updated via signalR service
  dispatch(DeviceAttributeActions.setInitialDeviceStatus(deviceStatus));

  return { orgId, organizations, cameras };
};

export const getCameras = createAsyncThunk("cameras/getCameras", thunk);
