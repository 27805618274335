import { MenuItem } from "@mui/material";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { useOrganization } from "~/hooks";

import { CameraLabel } from "../CameraLabel/CameraLabel";

import { useStyles } from "./styles";

const KuvaCameraList = ({ onSelect = noop, cameras }) => {
  const { classes } = useStyles();
  const { selectedOrg } = useOrganization();

  return (
    <div className={classes.cameras} data-testid="camera-list">
      {cameras.map(camera => {
        return (
          <MenuItem
            component={NavLink}
            key={camera.deviceId}
            to={`/${selectedOrg?.id}/${camera.deviceId}`}
            onClick={() => onSelect(selectedOrg?.id, camera.deviceId)}
          >
            <CameraLabel camera={camera} />
          </MenuItem>
        );
      })}
    </div>
  );
};

KuvaCameraList.propTypes = {
  onSelect: PropTypes.func,
  cameras: PropTypes.array
};

export default KuvaCameraList;
