import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useSettings } from "~/hooks";

export const useFetchSnapshotTable = (
  columns,
  makeColumns,
  actions,
  tableUnits
) => {
  const dispatch = useDispatch();
  const { displayUtc } = useSettings();

  useEffect(() => {
    const generatedColumns = makeColumns({ displayUtc, tableUnits });
    if (isEmpty(columns))
      return dispatch(actions.setTableColums(generatedColumns));

    const needsRenderCell = !columns[0].renderCellComponent;
    if (needsRenderCell) {
      const columnsWithPersistentSettings = columns?.map(item => ({
        ...generatedColumns.find(col => col.field === item.field),
        ...item
      }));
      dispatch(actions.setTableColums(columnsWithPersistentSettings));
    }
  }, [columns, displayUtc, makeColumns, actions, dispatch]);

  useEffect(() => {
    const generatedColumns = makeColumns({ displayUtc, tableUnits });

    return dispatch(actions.setTableColums(generatedColumns));
  }, [displayUtc]);
};
