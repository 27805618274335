import { CircularProgress } from "@mui/material";
import { styled } from "@mui/styles";

export const CenteredContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%"
});

export const Spinner = styled(CircularProgress)({
  opacity: 0.75
});
