import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: { error: false },
  cameraTable: {
    loading: false,
    error: { error: false },
    columns: [],
    units: "imperial",
    sortSetting: {
      columnToSort: "lastEvent",
      sortDirection: "desc"
    }
  }
};

const slice = createSlice({
  name: "camera-general-view",
  initialState,
  reducers: {
    setTableColums: (state, action) => {
      state.cameraTable.columns = action.payload;
    },
    setSortSetting: (state, action) => {
      state.cameraTable.sortSetting = action.payload;
    }
  }
});

export const CameraGeneralViewActions = {
  ...slice.actions
};

export const CameraGeneralViewReducer = slice.reducer;
