import axios from "axios";
class TelemetryAPI {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/telemetry/v1`,
      timeout: 10000
    });
  }

  static instance = null;

  static Instance = () => {
    if (!this.instance) {
      this.instance = new TelemetryAPI();
    }
    return this.instance;
  };

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log("TelemetryAPI url set to: ", `${url}/v1`);
    }
  };

  /* retrieve latest snapshot */
  getTelemetryByOrg = (orgId, source) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/telemetries/${orgId}`,
      "color: #00aa66"
    );
    return this.axiosInstance.get(`/telemetries/${orgId}`, {
      cancelToken: source.token
    });
  };
}

export default TelemetryAPI.Instance();
