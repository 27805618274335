import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { SignalRService } from "~/services";

export const useStartSignalR = setReportedAttributes => {
  const dispatch = useDispatch();

  if (!SignalRService) {
    return;
  }

  useEffect(() => {
    const createSignalRConnection = async () => {
      await SignalRService.createSignalRConnection();
      SignalRService.signalRInstance.connection
        .start()
        .then(() => {
          if (SignalRService.signalRInstance.connection.state === "Connected") {
            dispatch({
              type: "SET_SIGNALR_CONNECTION",
              connection: "started"
            });

            SignalRService.signalRInstance.connection.on(
              "deviceTwinEvent",
              (deviceId, message) => {
                try {
                  const reportedAttributes = JSON.parse(message);
                  if (
                    Object.hasOwn(
                      reportedAttributes.properties?.reported,
                      "scanning"
                    )
                  ) {
                    setReportedAttributes(reportedAttributes);

                    // Set all awaiting action to false
                    // scan action
                    dispatch({
                      type: "SET_SCAN_ACTION_STATUS",
                      deviceAction: {
                        [deviceId]: { loading: false, awaitingResponse: false }
                      }
                    });
                    // illuminate action
                    dispatch({
                      type: "SET_ILLUMINATE_ACTION_STATUS",
                      deviceAction: {
                        [deviceId]: { loading: false, awaitingResponse: false }
                      }
                    });
                  }
                } catch (error) {
                  console.error(`Bad deviceTwin payload: ${message}`);
                }
              }
            );

            SignalRService.signalRInstance.connection.on(
              "telemetryEvent",
              (deviceId, message) => {
                try {
                  let telemetry = JSON.parse(message);
                  dispatch({
                    type: "UPSERT_REPORTED_TELEMETRY",
                    telemetry: { [deviceId]: telemetry }
                  });
                } catch (error) {
                  console.error(`Bad telemetry payload: ${message}`);
                }
              }
            );

            SignalRService.signalRInstance.connection.on(
              "deviceStatusEvent",
              (deviceId, opType) => {
                /* ex. return deviceConnected | deviceDisconnected */
                try {
                  const connectionState =
                    opType === "deviceConnected" ? "Connected" : "Disconnected";
                  const deviceStatus = {
                    [deviceId]: { connectionState: connectionState }
                  };
                  dispatch({
                    type: "UPSERT_REPORTED_DEVICE_STATUS",
                    deviceStatus: deviceStatus
                  });
                } catch (error) {
                  console.error(`Bad deviceStatus payload: ${error}`);
                }
              }
            );
          }
        })
        .catch(e => {
          console.error("SignalRService start error:", e);
        });
    };
    createSignalRConnection();
  }, []);
};
