import { createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import { fetchTelemetrySnapshotByOrg } from "./thunks/get-telemetry-snapshot";

export const initialState = {
  loading: false,
  error: { error: false },
  snapshot: [],
  telemetryTable: {
    loading: false,
    error: { error: false },
    columns: [],
    units: "imperial",
    sortSetting: {
      columnToSort: "created_time",
      sortDirection: "desc"
    }
  }
};

const slice = createSlice({
  name: "camera-details-view",
  initialState,
  reducers: {
    setTableColums: (state, action) => {
      state.telemetryTable.columns = action.payload;
    },
    setSortSetting: (state, action) => {
      state.telemetryTable.sortSetting = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTelemetrySnapshotByOrg.pending, state => {
        state.telemetryTable.loading = true;
      })
      .addCase(fetchTelemetrySnapshotByOrg.fulfilled, (state, action) => {
        // flatten the nested array by concatenating its elements into a new array
        const data = action?.payload?.data;
        const newSnapshotData = data ? [].concat(...data) : [];

        // add required data eg. id
        const transformedSnapshotData = newSnapshotData.map(obj => {
          return { id: obj.device_id, ...obj };
        });

        state.telemetryTable.loading = false;
        state.snapshot = keyBy(transformedSnapshotData, "id");
      })
      .addCase(fetchTelemetrySnapshotByOrg.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action.payload
        };
        state.error = error;
        state.telemetryTable.loading = false;
      });
  }
});

export const CameraDetailsViewActions = {
  ...slice.actions,
  fetchTelemetrySnapshotByOrg
};

export const CameraDetailsViewReducer = slice.reducer;
