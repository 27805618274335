import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useSnackbar as useNotistackSnackBar } from "notistack";

export const defaultSuccessOptions = {
  variant: "success",
  autoHideDuration: 2500,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  action: undefined
};

export const defaultErrorOptions = {
  variant: "error",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  autoHideDuration: null
};

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackBar();

  const action = key => (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const errorOptionsWithAction = { ...defaultErrorOptions, action };

  const showSnackbar = (msg, options = {}) => {
    const defaultOptions =
      options.variant === "error"
        ? errorOptionsWithAction
        : defaultSuccessOptions;

    const {
      variant = defaultOptions.variant,
      vertical = defaultOptions.anchorOrigin.vertical,
      horizontal = defaultOptions.anchorOrigin.horizontal,
      autoHideDuration = defaultOptions.autoHideDuration,
      action = defaultOptions.action
    } = options;

    enqueueSnackbar(msg, {
      variant,
      anchorOrigin: {
        vertical,
        horizontal
      },
      autoHideDuration,
      action
    });
  };

  return { showSnackbar, closeSnackbar };
};
