import { reduce, isEmpty } from "lodash/fp";

const getFlattenOrgIds = org => {
  if (isEmpty(org)) return [];
  return org.children
    ? [org.id, ...org.children.flatMap(getFlattenOrgIds)]
    : org.id;
};
/* Find organization and descendant and return as an Object(organizationWithDescendant). */
function findOrganizationAndDescendant(orgs, id) {
  // recursive func
  let i, temp;
  // loop to parent object
  for (i = 0; i < orgs?.length; i++) {
    if (orgs[i].id === id) {
      return orgs[i];
    }
    // Find children / descendant

    if (orgs[i].children.length > 0) {
      // Find children recursively
      temp = findOrganizationAndDescendant(orgs[i].children, id);
      if (temp) {
        return temp;
      }
    }
  }
  return null;
}

function getChildren(org) {
  let children = [
    {
      ...org,
      /* Tree View Components key(s) */
      parent: org?.parent ?? 0,
      droppable: true
    }
  ];

  if (org.children) {
    if (!(org.children instanceof Array)) {
      org.children = [org.children];
    }
    for (let x of org.children) {
      children = children.concat(getChildren(x));
    }
  }

  /* return flat array */
  /* eg. [{ id: "kuva", parent: 0 , ... }, { id: "kuva-usa", parent: "kuva" , ... }] */
  return children;
}

function createOneLevelOrgsArray(rawOrgs) {
  let orgs = [];
  for (let org of rawOrgs) {
    orgs = orgs.concat(getChildren(org));
  }

  /* Return all orgs as one level array */
  /* eg. [{ id: "kuva", parent: 0 , ... }, { id: "kuva-usa", parent: "kuva" , ... }, ...all orgs and children] */
  return orgs;
}

function normalizeOrgs(rawOrgs) {
  const orgs = createOneLevelOrgsArray(rawOrgs);

  /* Return orgs as a normalized collection */
  return reduce(
    (acc, org) => {
      acc[org.id] = org;
      return acc;
    },
    {},
    orgs
  );
}

export {
  createOneLevelOrgsArray,
  findOrganizationAndDescendant,
  getFlattenOrgIds,
  normalizeOrgs
};
