import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const setSerialNumber = createAsyncThunk(
  "cameras/setSerialNumber",
  async ({ deviceId, serialNumber }) => {
    await CameraAPI.setSerialNumber({ deviceId, serialNumber });

    return {
      deviceId,
      serialNumber
    };
  }
);
