import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const getCameraFovImage = createAsyncThunk(
  "cameras/getCameraFovImage",
  async ({ deviceId, poiDegrees }) => {
    const { data } = await CameraAPI.getFovImage(deviceId, poiDegrees);

    return {
      fovImage: data,
      poiDegrees,
      deviceId
    };
  }
);
