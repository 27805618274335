import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const getCameraPoiNames = createAsyncThunk(
  "cameras/getCameraPoiNames",
  async ({ deviceId }) => {
    const { data } = await CameraAPI.getPoiNames({ streamIds: deviceId });

    return {
      poiNames: data
    };
  }
);
