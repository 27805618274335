import { createAsyncThunk } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import AlarmAPI from "~/apis/AlarmAPI";

//  * @param {string} orgId - filter alarms by organization
//  * @param {number} days - filter alarms in past n days default to 1

const thunk = async ({ orgId, days = 1, cancelToken }) => {
  const { data } = await AlarmAPI.getLatestAlarmCountPerDevice({
    orgId,
    days,
    cancelToken
  });

  return keyBy(data, "deviceId");
};

export const getAlarmsCount = createAsyncThunk(
  "alarms/fetchLatestAlarmsCountPerDevice",
  thunk
);
