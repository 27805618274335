import {
  Close as CloseIcon,
  Info as InfoIcon,
  Warning as WarningIcon
} from "@mui/icons-material";
import AlertMUI from "@mui/material/Alert";
import PropTypes from "prop-types";

import { hexToRgba, styled, theme } from "@kuva/ui-components";

import ReadMore from "./ReadMore";

const alertColors = {
  warning: {
    backgroundColor: "rgb(55, 39, 34)",
    color: theme.palette.warning.main,
    border: `1px solid ${hexToRgba(theme.palette.warning.main, 0.5)}`,
    borderRadius: "4px"
  },
  informational: {
    backgroundColor: "#364428",
    color: theme.palette.success.main,
    border: `1px solid ${hexToRgba(theme.palette.success.main, 0.5)}`,
    borderRadius: "4px"
  },
  releaseNotes: {
    backgroundColor: "#364428",
    color: theme.palette.success.main,
    border: `1px solid ${hexToRgba(theme.palette.success.main, 0.5)}`,
    borderRadius: "4px"
  },
  blue: {
    backgroundColor: "#193D48",
    color: theme.palette.info.main,
    border: `1px solid ${hexToRgba(theme.palette.info.main, 0.5)}`,
    borderRadius: "4px"
  },
  gray: {
    backgroundColor: "#424242",
    color: theme.palette.text.secondary,
    border: `1px solid ${hexToRgba(theme.palette.text.secondary, 0.5)}`,
    borderRadius: "4px"
  }
};

export const notifTypes = {
  RELEASE_NOTES: "Release notes",
  INFORMATIONAL: "Informational",
  OUTAGE: "Outage"
};

// TODO Jan: move to storybook->ui-components
function Alert({ message, severity, onClose, actionButtons }) {
  const alertSeverity = {
    [notifTypes.OUTAGE]: { ...alertColors.warning },
    [notifTypes.INFORMATIONAL]: { ...alertColors.informational },
    [notifTypes.RELEASE_NOTES]: { ...alertColors.releaseNotes },
    blue: { ...alertColors.blue },
    gray: { ...alertColors.gray }
  };

  const closeIconPosition = {
    singleLine: {
      margin: "0 0 0 10px"
    },
    multipleLines: {
      position: "absolute",
      top: 6,
      right: 10
    }
  };

  const StyledAlert = styled(AlertMUI)(({ severity }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 20px",
    gap: "10px",
    maxWidth: "580px",
    minHeight: "42px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "160%",
    position: "relative",
    paddingBottom: "14px",
    "& .MuiAlert-message": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      wordWrap: "break-word"
    },
    ...alertSeverity[severity]
  }));

  const StyledCloseIcon = styled(CloseIcon)(({ length }) => ({
    cursor: "pointer",
    fontSize: 20,
    ...closeIconPosition[length > 100 ? "multipleLines" : "singleLine"]
  }));

  return (
    <StyledAlert
      icon={severity === notifTypes.OUTAGE ? <WarningIcon /> : <InfoIcon />}
      action={
        <>
          {actionButtons}
          <StyledCloseIcon
            onClick={() => onClose(false)}
            length={message?.length}
          />
        </>
      }
      severity={severity}
    >
      <ReadMore>{message}</ReadMore>
    </StyledAlert>
  );
}

Alert.defaultProps = {
  message: "This is an alert message! Check it out!",
  buttonText1: "Click me!"
};

Alert.propTypes = {
  actionButtons: PropTypes.node,
  message: PropTypes.string,
  severity: PropTypes.oneOf([
    notifTypes.OUTAGE,
    notifTypes.INFORMATIONAL,
    notifTypes.RELEASE_NOTES,
    "blue",
    "gray"
  ]),
  onClose: PropTypes.func
};

export default Alert;
