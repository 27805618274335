import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import ValidationSchema from "./ValidationSchema";
import { useStyles } from "./styles";

const OrganizationForm = ({
  initialValues,
  organizations,
  handleSubmit,
  handleDelete,
  loading,
  clear
}) => {
  const { classes } = useStyles();
  /* Let Formik handle all the Form state including custom fields */
  const userFormik = useFormik({
    enableReinitialize: true, // We should reset the form if the wrapped component props change (using deep equality).
    initialValues,
    onSubmit: values => {
      handleSubmit(values);
    },
    validationSchema: ValidationSchema
  });

  const handleClear = () => {
    userFormik.resetForm();
    clear();
  };
  const filteredOrg = organizations.filter(
    org => org.orgname !== userFormik.values?.orgname
  );
  return (
    <div className={classes.root}>
      <Typography variant="h5" sx={{ color: "text.white" }}>
        Organization Administration
      </Typography>
      <Divider style={{ margin: "10px 0 10px 0" }} />
      <form onSubmit={userFormik.handleSubmit}>
        <TextField
          variant="standard"
          className={classes.textField}
          id="parent"
          name="parent"
          label="Parent Organization"
          select
          defaultValue={0}
          value={userFormik.values?.parent}
          onChange={userFormik.handleChange}
          onBlur={userFormik.handleBlur}
          error={userFormik.touched.parent && Boolean(userFormik.errors.parent)}
          helperText={userFormik.touched.parent && userFormik.errors.parent}
        >
          <MenuItem value={0}>None</MenuItem>
          {filteredOrg?.map((org, index) => (
            <MenuItem key={`${org?.id}-${index}`} value={org?.id}>
              {org?.orgname}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="standard"
          style={{ width: "100%" }}
          id="orgname"
          name="orgname"
          label="Organization Name"
          type="text"
          placeholder="Organization Name"
          value={userFormik.values?.orgname}
          onChange={userFormik.handleChange}
          onBlur={userFormik.handleBlur}
          error={
            userFormik.touched.orgname && Boolean(userFormik.errors.orgname)
          }
          helperText={userFormik.touched.orgname && userFormik.errors.orgname}
        />
        <TextField
          variant="standard"
          style={{ width: "100%" }}
          id="logoURL"
          name="logoURL"
          label="Logo URL"
          type="url"
          placeholder="Logo URL"
          value={userFormik.values?.logoURL}
          onChange={userFormik.handleChange}
          onBlur={userFormik.handleBlur}
          error={
            userFormik.touched.logoURL && Boolean(userFormik.errors.logoURL)
          }
          helperText={userFormik.touched.logoURL && userFormik.errors.logoURL}
        />

        <Button
          className={classes.btnSubmit}
          variant="outlined"
          type="submit"
          color="primary"
          size="small"
          disabled={!(userFormik.isValid && userFormik.dirty)}
        >
          {loading ? (
            <CircularProgress size={18} />
          ) : initialValues?.id ? (
            "Save Changes"
          ) : (
            "Create Organization"
          )}
        </Button>
        {(userFormik.dirty || initialValues?.id) && !loading && (
          <Button
            className={classes.btnDefault}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClear}
          >
            Clear
          </Button>
        )}
        {/* Show delete option only if org doesn't have children */}
        {initialValues?.id &&
          (!initialValues?.children ||
            initialValues?.children?.length <= 0) && (
            <Button
              className={classes.btnDelete}
              variant="outlined"
              size="small"
              onClick={() => handleDelete(initialValues)}
            >
              {loading ? <CircularProgress size={18} /> : "Delete"}
            </Button>
          )}
      </form>
    </div>
  );
};

OrganizationForm.defaultProps = {
  initialValues: {},
  organizations: []
};

OrganizationForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  clear: PropTypes.func.isRequired
};

export default OrganizationForm;
