import { Button, Chip } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== "isLabelView"
})(({ theme: { spacing, palette }, isLabelView }) => ({
  textTransform: "none",
  border: palette.custom.border.primary,
  padding: isLabelView
    ? spacing(0.5, 1.25, 0.5, 1.5)
    : spacing(0.5, 4, 0.5, 0.5),
  color: palette.text.secondary,
  minWidth: 300,
  fontSize: 16,
  textAlign: "left",
  display: "flex",
  justifyContent: isLabelView ? "space-between" : "flex-start",
  overflowX: "hidden",
  height: 37
}));

export const ChipContainer = styled("div")({
  display: "flex",
  overflow: "hidden"
});

export const StyledChip = styled(Chip)({
  border: "none",
  height: "auto",
  fontSize: 16
});
