import { makeStyles, styled } from "@mui/styles";

const expandedAbsoluteDimensions = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

export const useContainerStyles = makeStyles(theme => ({
  paper: {
    animation:
      "$pulseAnimation 1.25s cubic-bezier(0.42, 0, 0.58, 1) infinite alternate",
    background: `${theme.palette.grey[800]}55`,
    borderRadius: 3,
    border: `1px solid ${theme.palette.grey[700]}`,
    boxShadow: "0 3px 12px 0 rgba(0, 0, 0, 0.5)",
    overflow: "hidden",
    position: "relative",
    transition: "box-shadow 0.25s",
    userSelect: "none",
    margin: "0 auto",

    "&:hover": {
      boxShadow: "0 6px 18px 0 rgba(0, 0, 0, 0.5)"
    }
  },

  "@keyframes pulseAnimation": {
    from: {
      background: theme.palette.grey[900]
    },
    to: {
      background: theme.palette.grey[800]
    }
  }
}));

export const ArrowControl = styled("div")(({ theme, invertGradient }) => ({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  padding: theme.spacing(0.5, 0),
  position: "relative",
  zIndex: 10,

  "& svg": {
    transform: "scale(1)",
    transition: "transform 0.25s",
    width: 46,
    height: 46,
    filter: "drop-shadow(0 2px 1px rgba(0, 0, 0, 0.4))"
  },

  "&::before": {
    content: "''",
    position: "absolute",
    background: `linear-gradient(
      to ${invertGradient ? "left" : "right"},
      ${theme.palette.grey[900]},
      rgba(0,0,0,0)
    )`,
    opacity: 0.75,
    transition: "opacity 0.25s",
    ...expandedAbsoluteDimensions
  },

  "&:hover": {
    "& svg": {
      transform: "scale(1.3)"
    },
    "&::before": {
      opacity: 0.5
    }
  }
}));

export const Controls = styled("div")(() => ({
  display: "flex",
  position: "absolute",
  ...expandedAbsoluteDimensions
}));

export const FramesContainer = styled("div")(() => ({
  ...expandedAbsoluteDimensions,
  position: "absolute",
  pointerEvents: "none",
  display: "flex"
}));

export const Frame = styled("div")(
  ({ active, panoramaXOffsetDelta, theme, readOnly }) => ({
    boxSizing: "border-box",
    background: active && `${theme.palette.custom.brightOrange[400]}1A`,
    border: `3px solid ${
      active ? theme.palette.custom.brightOrange[400] : theme.palette.grey[500]
    }`,
    borderRadius: 5,
    boxShadow: "0 2px 12px 0 black",
    cursor: readOnly ? "default" : active ? "grab" : "pointer",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    height: "100%",
    pointerEvents: panoramaXOffsetDelta ? "none" : "all", // disable pointer events while dragging the panorama
    position: "absolute",
    transition: "all 0.25s, left 0s, width 0s",
    width: 220,
    opacity: 0.9,
    zIndex: active ? 5 : "auto", // active frame is always on top

    "&:hover": {
      opacity: 1,
      borderColor: active
        ? theme.palette.custom.brightOrange[400]
        : theme.palette.grey[500]
    }
  })
);

export const FrameIdentifierDecoration = styled("div")(
  ({ theme, active, home, readOnly }) => ({
    background: active
      ? theme.palette.custom.brightOrange[400]
      : theme.palette.grey[500],
    borderRadius: "0 0 0 5px",
    display: readOnly ? "none" : "block",
    fontSize: 16,
    fontWeight: "600",
    width: 27,
    height: 27,
    padding: home ? 0 : theme.spacing(0.5, 0, 0, 1.25),
    position: "relative",
    textShadow: `1px 1px 3px ${theme.palette.grey[700]}`,
    transition: "all 0.25s",
    left: 1,

    "& svg": {
      width: 18,
      marginTop: 1,
      marginLeft: 5
    }
  })
);

export const FrameHelpersOverlay = styled("div")(({ readOnly }) => ({
  position: "absolute",
  ...expandedAbsoluteDimensions,
  display: readOnly ? "none" : "flex",
  alignItems: "center",
  justifyContent: "space-around"
}));

export const FrameCenterHighlightHelper = styled("div")(
  ({ theme, active }) => ({
    backgroundColor: `${
      active ? theme.palette.custom.brightOrange[400] : theme.palette.grey[400]
    }30`,
    borderRadius: 64,
    height: "20%",
    width: "27%",
    position: "absolute",
    transition: "all 0.25s"
  })
);

export const FrameGridHelper = styled("div")(({ theme }) => ({
  // vertical grid lines
  width: "33.333%",
  height: "100%",
  justifySelf: "center",
  borderLeft: `1px solid ${theme.palette.custom.brightOrange[400]}65`,
  borderRight: `1px solid ${theme.palette.custom.brightOrange[400]}65`,

  // horizontal grid lines
  "&::before": {
    content: "''",
    display: "block",
    position: "absolute",
    ...expandedAbsoluteDimensions,
    width: "100%",
    top: "33.333%",
    height: "33.333%",
    borderTop: `1px solid ${theme.palette.custom.brightOrange[400]}65`,
    borderBottom: `1px solid ${theme.palette.custom.brightOrange[400]}65`
  }
}));

export const Image = styled("img")(({ imageLoaded }) => ({
  display: "block",
  height: "100%",
  opacity: imageLoaded ? 1 : 0
}));

export const ImageError = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  color: theme.palette.grey[500],
  background: theme.palette.grey[800],
  fontSize: 14,

  "& svg": {
    fill: theme.palette.grey[600],
    transform: "scale(2)",
    marginBottom: theme.spacing(2.5)
  }
}));

export const PanoramaDragArea = styled("div")(() => ({
  flexGrow: 1,
  height: "100%"
}));
