import { Chip as MaterialChip } from "@mui/material";
import PropTypes from "prop-types";

import { useChipStyles } from "./Chip.styles";

export const Chip = ({ label, color, size, ...props }) => {
  const chipClasses = useChipStyles({ color, size });

  return <MaterialChip {...props} classes={chipClasses} label={label} />;
};

Chip.defaultProps = {
  color: "default",
  size: "medium",
  variant: "outlined"
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["default", "warning"]),
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["filled", "outlined"])
};
