import { Search as SearchIcon } from "@mui/icons-material";
import { InputBase, InputAdornment } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

const SearchBar = styled("div")(({ theme }) => ({
  borderRadius: 8,
  border: "1px solid " + theme.palette.text.secondary,
  margin: theme.spacing(0.75, 0),
  padding: theme.spacing(0.5, 1),
  background: theme.palette.background.paper
}));

export const MenuSearchBar = ({ textFilter, handleSearchChange }) => {
  return (
    <SearchBar>
      <InputBase
        data-cy="filter-search"
        value={textFilter}
        onChange={handleSearchChange}
        placeholder="Search"
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </SearchBar>
  );
};

MenuSearchBar.propTypes = {
  textFilter: PropTypes.string,
  handleSearchChange: PropTypes.func.isRequired
};
