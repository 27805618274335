export const getSelectedContainer = state =>
  state.applicationSettings.selectedResultsContainer;

export const getShowNonDetections = state =>
  state.applicationSettings.showNonDetection;

export const getShowArchived = state => state.applicationSettings.showArchived;

export const getDisplayUtc = userId => state =>
  state.applicationSettings[userId]?.displayUtc;

export const selectImperialUnit = userId => state =>
  state.applicationSettings[userId]?.imperialUnits; // deprecated

export const selectUserAccountSettings = userId => state =>
  state.applicationSettings[userId];
