const { isNil } = require("lodash");

const getFloatingNumber = (value = null, to = 2) =>
  parseFloat(value).toFixed(to);

const getWindDirection = (windDirection, zeros = 0) =>
  isNil(windDirection) ||
  windDirection < 0 ||
  windDirection > 360 ||
  isNaN(windDirection)
    ? "N/A"
    : `${getFloatingNumber(windDirection, zeros)}°`;

const getWindSpeed = (windSpeed, unit = "", zeros = 0) =>
  isNil(windSpeed) || windSpeed < 0 || isNaN(windSpeed)
    ? "N/A"
    : `${getFloatingNumber(windSpeed, zeros)} ${unit}`;

module.exports = {
  getWindSpeed,
  getWindDirection,
  getFloatingNumber
};
