import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

export default function MultipleSelect({
  orgUsers,
  orgRecipients,
  handleRecipientsOnChange
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        id="emails-outlined"
        value={orgRecipients}
        onChange={(e, selectedEmails) =>
          handleRecipientsOnChange([...selectedEmails])
        }
        multiple
        disableCloseOnSelect
        options={orgUsers}
        getOptionLabel={option => option.email}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Email"
            placeholder="Email"
          />
        )}
      />
    </div>
  );
}

MultipleSelect.propTypes = {
  handleRecipientsOnChange: PropTypes.func,
  orgRecipients: PropTypes.array,
  orgUsers: PropTypes.array
};
