import { createSlice } from "@reduxjs/toolkit";

import { prepareNotificationObject } from "~/common/components/UserNotification/utils";

import { fetchUserNotificationsByDate } from "./thunks/get-user-notifications";

const initialState = {
  loading: false,
  error: { error: false },
  notifications: []
};

const slice = createSlice({
  name: "userNotifications",
  initialState,
  reducers: {
    setIsReadNotification: (state, action) => {
      state.notifications[action.payload].isRead = true;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserNotificationsByDate.pending, state => {
        state.loading = true;
      })
      .addCase(fetchUserNotificationsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = prepareNotificationObject({
          notifications: action.payload?.data,
          state: state.notifications
        });
      })
      .addCase(fetchUserNotificationsByDate.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action.payload
        };
        state.error = error;
        state.loading = false;
      });
  }
});

export const UserNotificationActions = {
  ...slice.actions,
  fetchUserNotificationsByDate
};
export const UserNotificationReducer = slice.reducer;
