import Expand from "@mui/icons-material/Add";
import Shrink from "@mui/icons-material/Remove";
import { Avatar, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

const OrganizationNode = ({ node, depth, isOpen, onToggle, onClickNode }) => {
  const { classes } = useStyles();
  const indent = depth * 24;

  const handleToggle = e => {
    e.stopPropagation();
    onToggle(node.id);
  };

  return (
    <div
      className={classes.root}
      style={{ paddingInlineStart: indent }}
      onClick={() => onClickNode(node)}
    >
      <div
        className={`${classes.expandIconWrapper} ${
          isOpen ? classes.isOpen : ""
        }`}
      >
        {node.droppable && (
          <div onClick={handleToggle}>{isOpen ? <Shrink /> : <Expand />}</div>
        )}
      </div>
      <div>
        <Avatar
          className={classes.avatar}
          alt={node?.text}
          src={node?.logoURL}
        />
      </div>
      <div className={classes.labelGridItem}>
        <Typography variant="body1">{`${node?.orgname}`}</Typography>
      </div>
    </div>
  );
};

OrganizationNode.defaultProps = {
  node: {},
  isOpen: false
};

OrganizationNode.propTypes = {
  node: PropTypes.object.isRequired,
  depth: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClickNode: PropTypes.func.isRequired
};

export default OrganizationNode;
