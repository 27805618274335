import PropTypes from "prop-types";

import { useStyles } from "./styles";

const Placeholder = ({ depth }) => {
  const { classes } = useStyles();
  const left = depth * 24;
  return <div className={classes.root} style={{ left }} />;
};

Placeholder.defaultProps = {
  depth: 0
};

Placeholder.propTypes = {
  depth: PropTypes.number.isRequired
};

export default Placeholder;
