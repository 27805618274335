import { createAsyncThunk } from "@reduxjs/toolkit";

import TelemetryAPI from "~/apis/TelemetryAPI";

const thunk = async (orgId, source) => {
  const response = await TelemetryAPI.getTelemetryByOrg(orgId, source);
  return response;
};

export const fetchTelemetrySnapshotByOrg = createAsyncThunk(
  "telemetry/fetchTelemetrySnapshotByOrg",
  thunk
);
