import axios from "axios";

class StrapiAPI {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_STRAPI_API_URL_LOCAL}/api`,
      timeout: 30000
    });
  }

  static instance = null;

  static Instance = () => this.instance ?? new StrapiAPI();

  // Allow strapi header to be initialized after login
  setStrapiToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_STRAPI_ENV !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/api`;
      console.log("StrapiAPI url set to: ", `${url}/api`);
    }
  };
}

export default StrapiAPI.Instance();
