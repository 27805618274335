import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedView: "general"
};

const slice = createSlice({
  name: "camera-list",
  initialState,
  reducers: {
    setSelectedView: (state, action) => {
      state.selectedView = action.payload;
    }
  }
});

export const CameraPageActions = {
  ...slice.actions
};

export const CameraPageReducer = slice.reducer;
