import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledBox = styled("div")(({ width }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "start",
  width
}));

export const StyledIconButton = styled(IconButton)(
  ({ theme: { spacing, palette }, error }) => ({
    padding: 0,
    margin: spacing(0, 1),
    color: error ? palette.custom.orange[400] : palette.text.secondary,
    "&:hover": {
      color: "white"
    }
  })
);

export const LabelWrapper = styled(Typography)(({ fontSize, width }) => ({
  display: "flex",
  alignItems: "center",
  fontSize,
  width,
  height: 40,
  color: "#9FA4AB"
}));

export const Asterisk = styled("span")(({ theme: { palette } }) => ({
  color: palette.custom.orange[400]
}));

export const StyledErrorMessage = styled(Typography)(
  ({ theme: { spacing, palette } }) => ({
    fontSize: 12,
    color: palette.custom.orange[400],
    marginTop: spacing(-0.5),
    height: 15
  })
);
