import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useState } from "react";

import { UnitNames, units as unit } from "@kuva/units";

import { useSettings } from "~/hooks";

import { useUnitsOptionStyles } from "./styles";

export const TemperatureUnitOption = ({ units, handleUnitsChange }) => {
  const { setTemperatureUnit } = useSettings();
  const { classes } = useUnitsOptionStyles();
  const [expanded, setExpanded] = useState(true);

  const handleChange = (_event, isExpanded) => setExpanded(isExpanded);

  const handleRadioChange = e => {
    setTemperatureUnit(e.target.value);
    handleUnitsChange(e);
  };

  return (
    <Box className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="temperature-control"
          id="panel1bh-header-temp-unit"
        >
          <Typography className={classes.heading}>Temperature</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <FormControl
              id="temperature-control"
              variant="standard"
              component="fieldset"
            >
              <RadioGroup
                aria-label="temperature"
                name="units.temperature"
                value={units}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  className={classes.formControlLabel}
                  value={UnitNames.FAHRENHEIT}
                  control={
                    <Radio
                      className={classes.checkbox}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      name={UnitNames.FAHRENHEIT}
                    />
                  }
                  label={unit.TEMPERATURE.FAHRENHEIT}
                />
                <FormControlLabel
                  className={classes.formControlLabel}
                  value={UnitNames.CELCIUS}
                  control={
                    <Radio
                      className={classes.checkbox}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      name={UnitNames.CELCIUS}
                    />
                  }
                  label={unit.TEMPERATURE.CELCIUS}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

TemperatureUnitOption.propTypes = {
  handleUnitsChange: PropTypes.func,
  units: PropTypes.string
};
