import { useDispatch } from "react-redux";

import { AlarmsActions } from "~/store/slices/alarms";

export const useAlarmActions = () => {
  const dispatch = useDispatch();

  const updateAlarmParams = newParams =>
    dispatch(AlarmsActions.setAlarmParams({ ...newParams }));

  return { updateAlarmParams };
};
