import { useState } from "react";

import { useSnackbar } from "@kuva/ui-components";

import { downloadCSV } from "~/components/Table/TableMenu/utils";
import { EXPORT_TIME_FORMATS } from "~/constants/csv-export";

export const useCSVDownload = () => {
  const [timeFormat, setTimeFormat] = useState(EXPORT_TIME_FORMATS.EASY_READ);
  const { showSnackbar } = useSnackbar();

  const downloadAsCSV = ({ rows, columns, sortSetting, filename }) => {
    const downloadSuccess = downloadCSV(
      { rows, columns, sortSetting, timeFormat },
      filename
    );

    downloadSuccess
      ? showSnackbar("CSV download was successful.")
      : showSnackbar("Failed to download CSV file.", {
          variant: "error"
        });
  };

  return { timeFormat, setTimeFormat, downloadAsCSV };
};
