import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const replaceStreamDevice = createAsyncThunk(
  "cameras/replaceStreamDevice",
  async ({ streamId, deviceId }, thunkAPI) => {
    try {
      const response = await CameraAPI.replaceStreamDevice({
        streamId,
        deviceId
      });

      return { stream: response.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);
