import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

/**
 *
 * @param {object} payload - event exceeds time and updated notified user emails
 * @param {object} getState - stores state
 * @returns {object}
 */
const thunk = async (payload, { getState }) => {
  const notifications = getState().alarmState.notifiedUsers;
  const selectedOrg = getState().organizationState.selectedOrg.id;
  if (!notifications.id) {
    const { data } = await AlarmAPI.createEmailNotificationSubscribers({
      subjectId: selectedOrg,
      type: "organization",
      ...payload
    });
    return data;
  }

  const { id, subjectId, type } = notifications;

  const { data } = await AlarmAPI.updateEmailNotificationSubscribers({
    id,
    subjectId,
    type,
    ...payload
  });

  return data;
};

export const updateEmailNotificationSubscribers = createAsyncThunk(
  "alarms/updateEmailNotificationSubscribers",
  thunk
);
