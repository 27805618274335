import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const setPanZeroOffset = createAsyncThunk(
  "cameras/setPanZeroOffset",
  async ({ deviceId, offset }) => {
    const convertedOffset = parseInt(offset);
    await CameraAPI.panZero(deviceId, convertedOffset, true);

    return {
      deviceId,
      offset: convertedOffset
    };
  }
);
