import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";

import { TextField } from "../core/TextField";

import { useStyles } from "./SearchBar.styles";

export const SearchBar = ({ requestSearch, placeholder, ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      variant="outlined"
      placeholder={placeholder ? placeholder : "Search"}
      onInput={e => requestSearch(e.target.value)}
      InputProps={{
        className: classes.input,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

SearchBar.propTypes = {
  requestSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};
