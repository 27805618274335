import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";

import { makeStyles } from "../../";

const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export const SnackbarNotification = ({ message, severity = "error" }) => {
  const { classes } = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={true}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <div>
          <Alert
            severity={severity}
            onClose={handleClose}
            className={classes.message}
          >
            {message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
};

SnackbarNotification.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.string
};
