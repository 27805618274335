import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    height: "calc(100vh - 64px)",
    backgroundColor: "#333333",
    margin: "1rem",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto"
  },
  treeRoot: {
    maxHeight: "100%",
    maxWidth: "400px"
  },
  draggingSource: {
    opacity: 0.3
  },
  placeholderContainer: {
    position: "relative"
  },
  dropTarget: {
    backgroundColor: "#e8f0fe"
  },
  overrides: {
    ul: {
      listStyle: "none"
    }
  }
});

export { useStyles };
