export const sortByProp = ({ arr, props, isDate = false, order = "asc" }) => {
  if (!arr) {
    return [];
  }
  return arr.slice().sort((current, next) => {
    const currentValue = getValue({ obj: current, props, isDate });
    const nextValue = getValue({ obj: next, props, isDate });
    const isAscending = order === "asc";
    if (isDate) {
      return isAscending
        ? getDescValue(currentValue, nextValue)
        : getAscValue(currentValue, nextValue);
    } else {
      return isAscending
        ? getAscValue(currentValue, nextValue)
        : getDescValue(currentValue, nextValue);
    }
  });
};

const getAscValue = (currentValue, nextValue) =>
  currentValue < nextValue ? -1 : currentValue > nextValue ? 1 : 0;
const getDescValue = (currentValue, nextValue) =>
  currentValue > nextValue ? -1 : currentValue < nextValue ? 1 : 0;

const getValue = ({ obj, props, isDate }) => {
  const prop = props.find(prop => obj[prop]);
  const value = obj[prop];
  if (isDate) {
    return value ? new Date(value).getTime() : 0;
  }
  const isString = typeof value === "string";
  return isString ? value.trim().toLowerCase() : value;
};
