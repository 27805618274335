import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme: { spacing } }) => ({
    "& .MuiToggleButton-root": {
      minWidth: "110px",
      padding: `${spacing(1)} ${spacing(2.5)}`
    }
  })
);

export const StyledToggleButton = styled(ToggleButton)(
  ({ theme: { palette } }) => ({
    minWidth: 80,
    border: palette.custom.border.primary,
    color: palette.text.secondary,
    transition: `color 0.3s, background-color 0.3s`,

    "&.MuiToggleButton-root.Mui-selected, &.MuiToggleButton-root.Mui-selected:hover":
      {
        color: palette.text.primary,
        backgroundColor: palette.primary.main,
        cursor: "default"
      },
    ":hover": {
      color: palette.text.primary
    }
  })
);
