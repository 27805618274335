import { styled } from "@mui/styles";
import PropTypes from "prop-types";

import { ArchivedIcon, DisconnectedIcon } from "../../icons";
import { getHaloColor } from "../../utils";

const StyledKuvaHalo = styled("span")(() => ({
  boxSizing: "border-box",
  borderRadius: 20,
  height: 16,
  width: 16,
  display: "inline-block",
  border: "3.5px solid",
  marginRight: 6,
  flexShrink: 0
}));

const StyledIcon = styled("span")(() => ({
  marginLeft: -2,
  marginRight: 6,
  marginTop: 6
}));

export const KuvaHalo = ({
  isArchived,
  isError,
  isConnected,
  capabilityClass,
  isImgPicQualityFeatureOn,
  isScanning
}) => {
  if (isArchived) {
    return (
      <StyledIcon>
        <ArchivedIcon />
      </StyledIcon>
    );
  } else if (!isConnected) {
    return (
      <StyledIcon>
        <DisconnectedIcon />
      </StyledIcon>
    );
  } else {
    return (
      <StyledKuvaHalo
        data-testid={isError ? "error-hoop" : "connected-hoop"}
        style={{
          borderColor: getHaloColor({
            isError,
            isConnected,
            capabilityClass,
            isImgPicQualityFeatureOn,
            isScanning
          })
        }}
      />
    );
  }
};

KuvaHalo.propTypes = {
  isArchived: PropTypes.bool,
  isError: PropTypes.bool,
  isConnected: PropTypes.bool,
  capabilityClass: PropTypes.number,
  isImgPicQualityFeatureOn: PropTypes.bool,
  isScanning: PropTypes.bool
};

KuvaHalo.displayName = "KuvaHalo";
