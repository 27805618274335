import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import MuiTableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

export const TableBody = ({
  rows,
  columns,
  order,
  orderBy,
  tableSort,
  getComparator,
  bodyCellAlign = "left"
}) => {
  return (
    <MuiTableBody>
      {tableSort(rows, getComparator(order, orderBy)).map((row, _index) => {
        return (
          <TableRow key={row.id}>
            {columns?.map(col => {
              return col?.columnVisibility ? (
                <TableCell key={col?.field} align={bodyCellAlign}>
                  {col?.renderCellComponent ? (
                    <col.renderCellComponent row={row} />
                  ) : (
                    row[col?.field]
                  )}
                </TableCell>
              ) : null;
            })}
          </TableRow>
        );
      })}
    </MuiTableBody>
  );
};

TableBody.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  tableSort: PropTypes.func,
  getComparator: PropTypes.func,
  bodyCellAlign: PropTypes.oneOf(["left", "right", "center"])
};
