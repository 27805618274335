import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(({ palette }, { selected }) => ({
  root: {
    height: "100%",
    "& span:first-of-type": {
      textDecoration: selected
        ? `${palette.primary.main} underline 2px`
        : "none",
      textUnderlineOffset: "4px"
    }
  },
  button: {
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    opacity: ".7",
    color: "white",
    fontSize: 14,
    textTransform: "none",
    "&:hover": {
      backgroundColor: palette.action.hover
    }
  }
}));

const NavBarButton = ({ navlink, text, selected, testid }) => {
  const { classes } = useStyles({ selected });
  return (
    <NavLink className={classes.root} to={navlink}>
      <Button
        variant="contained"
        className={classes.button}
        data-testid={testid}
      >
        <span>{text}</span>
      </Button>
    </NavLink>
  );
};

NavBarButton.propTypes = {
  navlink: PropTypes.string,
  selected: PropTypes.bool,
  text: PropTypes.string,
  testid: PropTypes.string
};

export default NavBarButton;
