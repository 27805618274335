import { isNil, isNumber } from "lodash";

import { theme } from "../theme";

export const getHaloColor = ({
  isError,
  isConnected,
  isArchived,
  capabilityClass,
  isImgPicQualityFeatureOn = false,
  isScanning = false
}) => {
  if (isImgPicQualityFeatureOn) {
    if (isConnected && !isArchived && capabilityClass >= 1)
      return theme.palette.success.main;
    if (
      isConnected &&
      !isArchived &&
      isNumber(capabilityClass) &&
      capabilityClass <= 0.5
    )
      return theme.palette.error.main;
    if (isConnected && !isArchived && isNil(capabilityClass) && isScanning)
      return theme.palette.success.main;

    return theme.palette.action.disabled;
  } else {
    if (isError && isConnected) return theme.palette.primary.main;
    if (isConnected && !isArchived) return theme.palette.success.main;

    return theme.palette.action.disabled;
  }
};
