import { AppBar as MaterialAppBar } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export const ActionsSpacer = styled("div")(({ theme }) => ({
  width: theme.spacing(1)
}));

export const AppBar = styled(MaterialAppBar)(({ theme }) => ({
  background: "#333",
  boxShadow: "none",
  color: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.grey[900]}`,
  borderRadius: theme.spacing(1, 1, 0, 0)
}));

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  padding: "4px 0 3px 0"
}));

export const useTabStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minWidth: "auto !important",
    color: palette.common.white,
    padding: spacing(1.5, 1.25),
    opacity: 0.6,
    marginRight: spacing(1.5),
    transition: "opacity 0.2s",
    textShadow: "1px 1px 0 rgba(0, 0, 0, 0.15)",
    fontWeight: 300,
    "&:hover": {
      opacity: 1
    },
    "&.Mui-selected": {
      color: palette.common.white
    }
  },
  selected: {
    opacity: 1,
    cursor: "default"
  }
}));

export const useTabContainerStyles = makeStyles(({ spacing, palette }) => ({
  scrollable: {
    marginLeft: -spacing(5)
  },
  scrollButtons: {
    zIndex: 10,
    background: "#333",
    opacity: 1
  },
  indicator: {
    height: "3px !important",
    bottom: "6px !important",
    backgroundColor: palette.custom.orange[400]
  }
}));
