import axios from "axios";
class DeviceAPI {
  constructor() {
    this.baseTime = new Date();
    this.getFramesCallStack = [];
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/device/v1`,
      timeout: 10000
    });
  }
  static instance = null;

  static Instance = () => this.instance ?? new DeviceAPI();

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log("DeviceAPI url set to: ", `${url}/v1`);
    }
  };
}

export default DeviceAPI.Instance();
