import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { formatDistance } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { useSearch } from "@kuva/ui-components";

import { flags } from "~/constants/feature-flags";
import {
  getNormalizeLatestAlarm,
  selectOrgCameras
} from "~/selectors/CameraSelector";
import { sortByProp } from "~/utils/sort-by-prop";

export const CAMERA_STATUSES = ["Connected", "Errors", "Disconnected"];

export const useCameraFilter = () => {
  const streamIdIsEnabled = useFeatureIsOn(flags.STREAM_ID_MANAGEMENT_TAB);

  const orgCameras = useSelector(selectOrgCameras, shallowEqual);
  const streams = useSelector(state => state.cameraState.streams);
  const latestAlarms = useSelector(getNormalizeLatestAlarm, shallowEqual);

  const cameras = useMemo(
    () =>
      orgCameras.map(camera => {
        const start = latestAlarms[camera.deviceId]?.start || camera.lastEvent;
        return {
          ...camera,
          lastEvent: start,
          lastEventLabel: start
            ? formatDistance(new Date(start), new Date())
            : ""
        };
      }),
    [orgCameras, latestAlarms]
  );

  const [camerasToDisplay, setCamerasToDisplay] = useState(cameras);
  const [filteredCameras, setFilteredCameras] = useState(cameras);

  const [statuses, setStatuses] = useState(CAMERA_STATUSES);

  const { filteredArray: filteredCamerasBySearch, handleSearch } = useSearch(
    filteredCameras,
    "name"
  );

  const [sortBy, setSortBy] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const sortProps = {
    props: [sortBy],
    isDate: sortBy === "lastEvent",
    order: sortOrder
  };

  // Filter through streams if enabled
  // Sorted in default order
  useEffect(() => {
    if (!streamIdIsEnabled) {
      filterBasedOnStatus(CAMERA_STATUSES);
      return;
    }
    const deviceIds = Object.keys(streams.byDeviceId);
    const filtered = cameras.filter(({ deviceId }) =>
      deviceIds.includes(deviceId)
    );
    setCamerasToDisplay(filtered);
    filterBasedOnStatus(CAMERA_STATUSES, filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameras, streams, streamIdIsEnabled]);

  const handleReverseSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setFilteredCameras(filteredCameras.slice().reverse());
  };

  const handleSort = value => {
    setFilteredCameras(
      sortByProp({
        arr: filteredCameras,
        props: [value],
        isDate: value === "lastEvent",
        order: sortOrder
      })
    );
    setSortBy(value);
  };

  const handleStatusUpdate = status => {
    const newStatuses = statuses.includes(status)
      ? statuses.filter(s => s !== status)
      : [...statuses, status];
    setStatuses(newStatuses);
    filterBasedOnStatus(newStatuses);
  };

  const handleClearStatus = () => {
    filterBasedOnStatus([]);
    setStatuses([]);
  };

  const filterBasedOnStatus = (
    newStatuses,
    camerasToFilter = camerasToDisplay
  ) => {
    if (!newStatuses.length) {
      setFilteredCameras([]);
    }
    const filtered = camerasToFilter.filter(camera =>
      newStatuses.includes(camera.connectionState)
    );
    setFilteredCameras(sortByProp({ arr: filtered, ...sortProps }));
  };

  return {
    filteredCameras: filteredCamerasBySearch,
    statuses,
    handleClearStatus,
    handleStatusUpdate,
    sortBy,
    handleSort,
    handleReverseSort,
    handleSearch
  };
};
