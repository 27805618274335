// const DEFAULT_ORG = "kuva";
const DEFAULTED_DOMAINS = ["app", "development", "staging"];

const DOMAIN_REGEX = /https?:\/\/(.*?)\./;
const DOMAIN_INDEX = 1;

const extractDomain = url => url.match(DOMAIN_REGEX)?.[DOMAIN_INDEX];

export const getSubDomain = url => {
  const domain = extractDomain(url);
  if (!domain || DEFAULTED_DOMAINS.includes(domain)) return null; // DEFAULT_ORG; // disabled until we fully implement subdomains
  return domain;
};
