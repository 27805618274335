import { Autocomplete as MUIAutocomplete } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

import { TextField } from "../TextField";

export const useAutocompleteStyles = makeStyles(theme => ({
  root: ({ color }) => ({
    "& .MuiInputLabel-root": {
      color: theme.palette.custom[color]?.[400] ?? theme.palette.text.secondary
    },
    "& .MuiOutlinedInput-notchedOutline": {
      padding: theme.spacing(1),
      borderColor: theme.palette.custom[color]?.[400] ?? theme.palette.grey[700]
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.custom[color]?.[400] ?? theme.palette.custom.grey
    },
    "& .MuiAutocomplete-input": {
      borderColor:
        theme.palette.custom[color]?.[400] ?? theme.palette.custom.grey
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.custom[color]?.[400] ?? theme.palette.grey[400],
      borderWidth: 0.5
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.custom.red[400]
    }
  })
}));

export function Autocomplete({
  color,
  error,
  helperText,
  label,
  name,
  size,
  value,
  variant,
  ...props
}) {
  const classes = useAutocompleteStyles({ color, size });

  return (
    <MUIAutocomplete
      {...props}
      className={classes.root}
      value={value}
      renderInput={params => (
        <TextField
          name={name}
          {...params}
          label={label}
          variant={variant}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}

Autocomplete.propTypes = {
  color: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string
};
