import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  nameContainer: {
    display: "flex"
  },
  cameraName: {
    textOverflow: "ellipsis",
    maxWidth: 150,
    overflow: "hidden"
  },
  root: {
    position: "static",
    backgroundColor: "black",
    height: spacing(10),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundImage: "none"
  },
  logoButton: {
    width: "100%",
    height: "100%"
  },
  logo: {
    height: 50,
    display: "block",
    transform: "scale(0.75)"
  },
  logoLink: {
    padding: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: palette.action.hover
    }
  },
  navBarDivider: {
    margin: spacing(3.75, 2.5),
    height: "auto"
  },
  buttonDivLeft: {
    whiteSpace: "nowrap",
    display: "flex",
    "& a": {
      textDecoration: "none",
      cursor: "auto"
    },
    "& .MuiButton-root": {
      padding: spacing(3.75, 2.5)
    }
  },
  buttonDiv: {
    whiteSpace: "nowrap",
    display: "flex",
    margin: spacing(1.25),
    marginLeft: "auto",
    justifyContent: "flex-end",
    "& a": {
      textDecoration: "none",
      cursor: "auto"
    }
  },
  dropdownButton: {
    background: "transparent",
    height: "100%",
    opacity: ".7",
    color: "white",
    fontSize: 14,
    padding: spacing(0, 1),
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: palette.action.hover
    }
  },
  avatar: {
    height: spacing(2.5),
    width: spacing(2.5),
    marginRight: spacing(0.625)
  },
  navBarDividerSm: {
    margin: spacing(1.875, 2.5),
    height: "auto"
  },
  btnLeft: {
    padding: spacing(0),
    marginRight: spacing(0.625)
  },
  btnRight: {
    alignSelf: "center"
  },
  superUser: {
    display: "inline-block",
    margin: "auto 0",
    marginLeft: spacing(3),
    background: "#2b2508",
    color: "#ffef49",
    fontSize: 11,
    padding: spacing(0.5, 1, 0.25, 1),
    borderRadius: 3,
    border: "1px solid #beae0e"
  },
  profileButton: {
    margin: spacing(0, 2, 0, 0),
    "& a": {
      cursor: "pointer"
    }
  },
  dropdown: {
    "& .MuiPaper-root": {
      maxWidth: "200px",
      border: "0.5px solid white",
      borderRadius: "5px",
      padding: spacing(0.5, 1.25),
      backgroundColor: palette.common.black
    },
    "& a": {
      color: palette.common.white,
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: 400
    },
    "& button": {
      color: palette.common.white,
      textTransform: "none",
      width: "100%",
      justifyContent: "left",
      "&:hover": {
        backgroundColor: palette.action.hover
      }
    }
  },
  orgDropdown: {
    "& .MuiPaper-root": {
      maxWidth: "none",
      maxHeight: "80vh",
      backgroundImage: "none"
    }
  },
  versionNumber: {
    fontSize: 11,
    color: palette.custom.grey,
    padding: spacing(1.25)
  }
}));

export { useStyles };
