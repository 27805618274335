import PropTypes from "prop-types";

import { StyledToggleButton, StyledToggleButtonGroup } from "./Toggle.styles";

export const Toggle = ({
  handleToggleChange = () => {},
  values = [],
  value,
  ...props
}) => {
  return (
    <StyledToggleButtonGroup
      {...props}
      exclusive
      value={value}
      onChange={handleToggleChange}
    >
      {values.map(val => (
        <StyledToggleButton key={val} value={val}>
          {val}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

Toggle.propTypes = {
  handleToggleChange: PropTypes.func,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired
};
