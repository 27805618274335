import { createAsyncThunk } from "@reduxjs/toolkit";

import AlarmAPI from "~/apis/AlarmAPI";

/**
 * Returns the n latest alarms for a given org
 * @param {string} orgId - the org id for alarm to be fetched
 * @param {number} page   - optional page to fetch alarms from, defaults to 1
 * @param {number} pagesize   - optional page size to fetch alarms from
 * @param {string} deviceIds   - optional comma separated list of device ids to fetch alarms from
 * @param {bool} frames   - optional to return the frames per alarm
 *
 */

const thunk = async payload => {
  const { data } = await AlarmAPI.getAlarmByOrg(payload);
  return data;
};

export const getLatestAlarms = createAsyncThunk(
  "alarms/getLatestAlarms",
  thunk
);
