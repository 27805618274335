import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    backgroundColor: "#333333",
    margin: "1rem",
    padding: "1rem",
    "& .MuiButton-outlined.Mui-disabled": {
      border: "1px solid #696969"
    }
  },
  btnSubmit: {
    minHeight: "38px",
    minWidth: "160px",
    margin: "20px 10px 0 0"
  },
  btnDefault: {
    minHeight: "38px",
    minWidth: "160px",
    margin: "20px 10px 0 0"
  },
  btnDelete: {
    minHeight: "38px",
    minWidth: "160px",
    margin: "20px 10px 0 0",
    color: "#FF4500",
    border: "0.5px solid #FF4500"
  },
  textField: {
    width: "100%",
    fontSize: "16px",
    label: {
      fontSize: "16px"
    }
  }
});

export { useStyles };
