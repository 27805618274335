import { makeStyles } from "@kuva/ui-components";

import { textMinWidth } from "./Table";

export const useTableStyles = makeStyles()(({ spacing, palette }) => ({
  root: {
    maxHeight: "75vh",
    position: "sticky",
    width: "100%",
    overflowX: "auto",
    "& .MuiTableCell-root": {
      padding: "0 10px 0 10px", // offset padding
      minWidth: textMinWidth,
      height: "44px",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "25px",
      color: "#A6A6A6",
      border: "0.5px solid #A6A6A6", // offset border
      backgroundColor: "#222222",
      verticalAlign: "middle"
    },
    "& .MuiTableCell-head": {
      borderTop: "unset",
      backgroundColor: "unset"
    },
    "& .MuiTableCell-head:first-of-type": {
      borderLeft: "unset"
    },
    "& .MuiTableCell-head:last-child": {
      borderRight: "unset"
    },
    "& .MuiTypography-body1": {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "25px",
      color: "#A6A6A6"
    }
  },
  table: {
    minWidth: 650
  },
  tableHeader: {
    background: palette.background.paper,
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  cameraBtn: {
    margin: 0,
    padding: 0,
    paddingLeft: spacing(1),
    width: "100%",
    height: "100%",
    textTransform: "none",
    justifyContent: "flex-start"
  },
  clickableRow: {
    cursor: "pointer"
  },
  cameraNameRenderCell: {
    margin: 0,
    padding: 0
  }
}));

export const useTableHeaderCellStyles = makeStyles()(({ spacing }) => ({
  root: {
    fontSize: "14px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      verticalAlign: "middle"
    }
  },
  headerContentContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  sectionContainer: {
    display: "flex"
  },
  headerName: {
    width: "auto",
    cursor: "pointer",
    textTransform: "uppercase"
  },
  unitsOfMeasurement: {
    fontSize: "10px",
    marginLeft: spacing(1)
  },
  sortIcon: {
    cursor: "pointer",
    marginRight: spacing(1),
    "& svg": {
      verticalAlign: "middle"
    }
  },
  moreVertIcon: {
    marginRight: spacing(-1) // offset icon width
  }
}));
