import { createSlice } from "@reduxjs/toolkit";

import { OrganizationActions } from "~/store/slices/organizations";

export const dashboardViewModes = Object.freeze({
  map: "Map",
  detail: "Detail"
});

const initialState = {
  viewMode: dashboardViewModes.map,
  selectedCamera: null
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setSelectedCamera: (state, action) => {
      state.selectedCamera = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(OrganizationActions.setSelectedOrg, state => {
      state.selectedCamera = null;
    });
  }
});

export const DashboardActions = {
  ...slice.actions
};

export const DashboardReducer = slice.reducer;
