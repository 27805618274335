import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const retakePointOfInterestPreview = createAsyncThunk(
  "cameras/retakePointOfInterestPreview",
  async ({ deviceId, position }) => {
    // @todo The camera does not respond back to the platform with an
    // error/success message yet, so for now we're just sending the request
    // and refreshing the image. This should be changed so that we refetch the
    // panorama image once the panorama scan is finished.
    await CameraAPI.previewPoi(deviceId, position);
    const { data: fovImage } = await CameraAPI.getFovImage(deviceId, position);

    return {
      fovImage,
      deviceId
    };
  }
);
