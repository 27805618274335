import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { makeStyles } from "@kuva/ui-components";

import { WarningIcon } from "~/components/Table/SvgIcons";

import { CustomFieldContainer } from "../styled-components";

const useStyles = makeStyles()({
  icons: {
    margin: "6.5px 5px 0px 0px"
  }
});

export const LatencyField = ({ latency, status, ...props }) => {
  const { classes } = useStyles();
  const LatencyIcon = {
    error: <WarningIcon className={classes.icons} />
  };
  return (
    <CustomFieldContainer status={status} {...props}>
      {LatencyIcon[status]}
      <Box>{latency}</Box>
    </CustomFieldContainer>
  );
};

LatencyField.propTypes = {
  latency: PropTypes.string,
  status: PropTypes.string
};
