import { useAuth0 } from "@auth0/auth0-react";

export const useUserInfo = () => {
  const { user } = useAuth0();
  const userId = user?.sub;
  const roles = user["kcc/roles"];
  const userOrgs = user["kcc/orgs"];
  const isSuperuser = roles?.includes("superuser");
  const isAdmin = roles?.includes("admin");

  return {
    user,
    userId,
    userOrgs,
    roles,
    isSuperuser,
    isAdmin
  };
};
