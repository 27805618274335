import { useCallback, useState } from "react";

export const useCopyClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback(async text => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error("Error copying to clipboard:", error.message);
    }
  }, []);

  return [isCopied, copyToClipboard];
};
