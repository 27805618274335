import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(({ spacing, palette }) => ({
  formControlLabel: {
    marginLeft: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: 14
    },
    "& .Mui-checked": {
      color: "white"
    }
  },
  allBtn: {
    fontSize: 12,
    color: palette.action.disabled,
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0
  },
  searchBar: {
    marginTop: spacing(1),
    marginBottom: spacing(0.5),
    width: "100%"
  }
}));
