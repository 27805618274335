import { Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { overrideStyles, useStyles } from "./styles";

const KuvaTooltip = ({ title = "", text, placement = "right", children }) => {
  const { classes } = useStyles();
  const { classes: overrideClasses } = overrideStyles();

  return (
    <Tooltip
      classes={overrideClasses}
      placement={placement}
      title={
        <>
          <Typography className={classes.tooltipTitle}>{title}</Typography>
          <Typography className={classes.tooltipSpan}>{text}</Typography>
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

KuvaTooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

export default KuvaTooltip;
