import {
  InputAdornment as MaterialInputAdornment,
  TextField as MaterialTextField
} from "@mui/material";
import { styled } from "@mui/styles";

export const StyledInputAdornment = styled(MaterialInputAdornment)(
  ({ theme }) => ({
    display: "inline-block",
    height: 35,
    maxHeight: "none",
    padding: theme.spacing(1),
    margin: "0 !important",
    boxSizing: "border-box",
    color: theme.palette.grey[500],
    "& p": {
      fontSize: 14
    }
  })
);

export const InputAdornment = props => <StyledInputAdornment {...props} />;

export const StyledTextField = styled(MaterialTextField)(({ theme }) => ({
  root: {
    all: "unset",
    background: "transparent",
    color: theme.palette.grey[50],
    marginBottom: theme.spacing(0.5),
    boxSizing: "border-box",

    "& .MuiInput-underline:after": {
      borderColor: theme.palette.grey[400]
    },

    "& .MuiOutlinedInput-root": {
      padding: 0,
      margin: 0,

      "& fieldset": {
        border: `1px solid ${theme.palette.grey[600]}`
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[400]
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      },
      "&.Mui-disabled, &.Mui-disabled fieldset": {
        borderColor: `${theme.palette.grey[600]} !important`,
        color: theme.palette.grey[400]
      }
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: theme.spacing(1, 0, 0, 0),
      color: theme.palette.custom.red[400]
    },
    "& .Mui-error.MuiOutlinedInput-root fieldset": {
      borderColor: `${theme.palette.custom.red[400]} !important`
    }
  },
  fieldset: {
    transition: "border 0.3s ease-out"
  }
}));

export const TextField = props => <StyledTextField {...props} />;
