import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(({ spacing, palette }) => ({
  iconPlaceholder: {
    marginTop: -5,
    marginBottom: -5,
    marginLeft: spacing(-1),
    marginRight: spacing(-1)
  },
  noIconPlaceholder: {
    whiteSpace: "nowrap",
    fontSize: 14,
    padding: spacing(1.25, 0),
    marginLeft: spacing(-2.75)
  },
  selectMenu: {
    color: palette.grey[400],
    background: `${palette.grey[900]}EE`
  },
  selectedMenuItem: {
    color: palette.action.disabled,
    "&.MuiListItem-root.Mui-selected": {
      background: palette.background.default
    },
    "&.MuiListItem-root.Mui-selected:hover": {
      background: palette.background.paper
    }
  },
  menuItem: {
    fontSize: 12,
    color: palette.action.disabled,
    "&.MuiListItem-root:hover": {
      background: `${palette.background.paper}66`
    }
  },
  downloadIcon: {
    top: "calc(50% - 12px)",
    right: 0,
    position: "absolute",
    marginRight: spacing(1),
    "&:hover": {
      cursor: "pointer"
    }
  },
  noIconBackground: {
    margin: spacing(-1.375, 0, -1.375, -2.25),
    padding: spacing(1.375, 8.25, 1.375, 2.25),
    textAlign: "left",
    "&:hover": {
      background: `${palette.background.paper}EE`,
      borderRadius: spacing(0.5),
      cursor: "pointer"
    }
  },
  disabled: {
    display: "none"
  }
}));

export { useStyles };
