import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./device-attribute-initial-state";

const slice = createSlice({
  name: "deviceAttribute",
  initialState,
  reducers: {
    setInitialReported: (state, action) => {
      state.reported = action.reported;
    },
    upsertReportedDeviceTwin: (state, action) => {
      const deviceId = action.payload.deviceId;
      const toUpdate = state.reported.find(cam => cam.deviceId === deviceId);

      const reported = action.payload?.properties
        ? action.payload?.properties.reported
        : action.payload?.reported;
      reported.scanning = reported.scanning ?? false;

      // Support new format
      const newReportedAtrr = { deviceId, ...reported };
      const updated = toUpdate
        ? { ...toUpdate, ...newReportedAtrr }
        : newReportedAtrr;

      state.reported = [
        ...state.reported.filter(prev => prev.deviceId !== deviceId),
        updated
      ];
    },
    upsertReportedTelemetry: (state, action) => {
      state.telemetry = { ...state.telemetry, ...action.payload };
    },
    upsertReportedDeviceStatus: (state, action) => {
      state.deviceStatus = { ...state.deviceStatus, ...action.payload };
    },

    setInitialDeviceStatus: (state, action) => {
      state.deviceStatus = action.payload;
    },

    setScanActionStatus: (state, action) => {
      state.deviceAction.scan = {
        ...state.deviceAction.scan,
        ...action.payload
      };
    },
    setIlluminateActionStatus: (state, action) => {
      state.deviceAction.illuminate = {
        ...state.deviceAction.illuminate,
        ...action.payload
      };
    }
  }
});

export const DeviceAttributeActions = {
  ...slice.actions
};

export const DeviceAttributeReducer = slice.reducer;
