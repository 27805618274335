import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const setPointsOfInterestConfig = createAsyncThunk(
  "cameras/setPointsOfInterestConfig",
  async ({ deviceId, panCenters = [], dwellFrames = 1 }) => {
    const convertedPanCenters = panCenters.map(panCenter =>
      parseInt(panCenter)
    );

    await CameraAPI.configPoi(deviceId, dwellFrames, convertedPanCenters);

    return {
      deviceId,
      panCenters: convertedPanCenters
    };
  }
);
