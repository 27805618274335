import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const deletePointOfInterest = createAsyncThunk(
  "cameras/deletePointOfInterest",
  async ({ deviceId, position, panCenters }) => {
    const filteredPanCenters = panCenters.filter(
      panCenter => panCenter !== position
    );

    await CameraAPI.configPoi(deviceId, 1, filteredPanCenters);

    try {
      await CameraAPI.deletePoiName({ poi: position, id: deviceId });
    } catch (error) {
      // This endpoint returns a 304 status when there's no data to delete,
      // but that should probably be a 204.
      if (error.response.status !== 304) {
        throw error;
      }
    }

    return {
      deviceId,
      position,
      panCenters: filteredPanCenters
    };
  }
);
