import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { requestSearch } from "~/components/SearchBar";
import { paginate } from "~/components/Table";

export const usePagination = (allRows, itemsPerPage) => {
  const [page, setPage] = useState(1);
  const [filteredRows, setFilteredRows] = useState([]);
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const goToPage = page => setPage(page);

  const handleChangePage = page => {
    updateRows(page);
    goToPage(page);
  };

  const updateRows = useCallback(
    page => {
      setPaginatedRows(paginate(filteredRows, page, itemsPerPage));
    },
    [setPaginatedRows, itemsPerPage, filteredRows]
  );

  const doSearch = debounce((searchValue, searchFields) => {
    const tmpFilteredRows = requestSearch({
      searchValue,
      rows: allRows,
      searchFields: searchFields || Object.keys(allRows[0])
    });

    goToPage(1);
    setFilteredRows(tmpFilteredRows);
    setLoading(false);
  }, 300);

  const handleSearch = (searchValue, searchFields) => {
    setLoading(true);
    doSearch(searchValue, searchFields);
  };

  useEffect(() => {
    setFilteredRows(allRows);
  }, [allRows, setFilteredRows]);

  useEffect(() => {
    updateRows(page);
  }, [page, allRows, updateRows]);

  const total = filteredRows.length;

  return {
    page,
    total,
    filteredRows,
    handleSearch,
    paginatedRows,
    goToPage,
    setFilteredRows,
    handleChangePage,
    loading
  };
};
