import { Popover as MaterialPopover } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

import { MenuSearchBar } from "./MenuSearchBar";

const Popover = styled(MaterialPopover)(({ theme: { spacing, palette } }) => ({
  "& > .MuiPaper-root": {
    minWidth: "125px",
    width: ({ width }) => width,
    border: "0.5px solid white",
    borderRadius: "5px",
    padding: spacing(0.5, 1.25),
    backgroundColor: palette.common.black
  },
  "& a": {
    color: palette.common.white,
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: 400
  },
  "& button": {
    color: palette.common.white,
    textTransform: "none",
    width: "100%",
    justifyContent: "left",
    "&:hover": {
      backgroundColor: palette.action.hover
    }
  }
}));

export const PopoverMenu = ({
  textFilter,
  handleSearchChange,
  width,
  children,
  ...props
}) => {
  return (
    <Popover width={width} {...props}>
      {handleSearchChange && (
        <MenuSearchBar
          textFilter={textFilter}
          handleSearchChange={handleSearchChange}
        />
      )}
      {children}
    </Popover>
  );
};

PopoverMenu.propTypes = {
  textFilter: PropTypes.string,
  handleSearchChange: PropTypes.func,
  width: PropTypes.string,
  children: PropTypes.node
};
