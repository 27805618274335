export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").toLowerCase();
}

export const requestSearch = ({ searchValue, rows, searchFields }) => {
  const isSearchValueEmpty = !searchValue.trim();
  if (isSearchValueEmpty) return rows;

  const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
  const filteredRows = rows.filter(row => {
    return searchFields.some(field => {
      return searchRegex.test(row[field]?.toString());
    });
  });

  return filteredRows;
};
