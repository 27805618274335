import { makeStyles } from "@kuva/ui-components";

export const useExportOptionStyles = makeStyles()(({ spacing }) => ({
  root: {
    marginBottom: spacing(1.5),
    background: "#222",
    "& .MuiAccordionDetails-root": {
      display: "inherit",
      padding: "0px 0px"
    },
    "& .MuiButtonBase-root": {
      justifyContent: "start"
    },
    "& .MuiIconButton-edgeEnd": {
      marginLeft: "-14px"
    },
    ".MuiAccordionSummary-root.Mui-expanded": {
      minHeigth: "unset"
    },
    "& .MuiTypography-body1": {
      fontSize: "14px"
    },
    "& .MuiFormControlLabel-root": {
      height: "36px"
    }
  },
  accordion: {
    background: "inherit"
  },
  accordionSummary: {
    minHeight: "20px",
    maxHeight: "20px",
    "& .MuiAccordionSummary-content": {
      flexGrow: "unset"
    },
    "&.Mui-expanded": {
      minHeight: "20px",
      maxHeight: "20px"
    }
  },
  heading: {
    fontSize: "14px",
    marginLeft: "-16px",
    flexShrink: 0
  },
  exportToggle: {
    width: "100%",
    marginTop: "10px",
    maxWidth: "280px"
  },
  actionButton: {
    borderRadius: "5px !important"
  }
}));
