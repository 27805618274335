import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { useMemo, useRef } from "react";

import { multiSelectItemsType } from "../types";

import { ChipContainer, StyledButton, StyledChip } from "./styles";

export const MultiSelectTrigger = ({
  popoverId,
  onClick,
  open,
  label,
  values = [],
  onDelete = noop,
  width = "auto"
}) => {
  const chipContainerRef = useRef(null);
  const totalValues = values.length;
  const isLabelView = totalValues === 0;

  const numToDisplay = useMemo(() => {
    if (isLabelView) return 0;
    if (totalValues < 2) return totalValues;
    if (width === "auto") return 2;

    let accumulatedWidth = 0;
    let elementsCount = 0;
    for (const { value } of values) {
      const estimatedChildWidth = value.length * 7 + 50;
      if (accumulatedWidth + estimatedChildWidth < width - 60) {
        elementsCount++;
        accumulatedWidth += estimatedChildWidth;
      } else {
        break;
      }
    }
    return elementsCount;
  }, [isLabelView, totalValues, values, width]);

  const hiddenNumOfVals = totalValues - numToDisplay;

  return (
    <StyledButton
      aria-describedby={popoverId}
      onClick={onClick}
      endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      isLabelView={isLabelView}
      sx={{
        width,
        ".MuiButton-endIcon": {
          position: "absolute",
          right: 10
        }
      }}
    >
      {isLabelView ? (
        label
      ) : (
        <ChipContainer ref={chipContainerRef}>
          {values.slice(0, numToDisplay).map(({ value, chipValue, label }) => (
            <StyledChip
              key={value}
              label={chipValue || label}
              variant="outlined"
              onDelete={() => onDelete(value)}
              deleteIcon={<CloseOutlinedIcon />}
            />
          ))}
        </ChipContainer>
      )}
      {hiddenNumOfVals > 0 && (
        <StyledChip
          key={"remaining"}
          label={`+${hiddenNumOfVals}`}
          variant="outlined"
        />
      )}
    </StyledButton>
  );
};

MultiSelectTrigger.propTypes = {
  popoverId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
  label: PropTypes.string.isRequired,
  values: multiSelectItemsType,
  onDelete: PropTypes.func,
  width: PropTypes.number
};
