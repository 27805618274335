export const getAlarmState = state => state.alarmState;

export const getAlarmById = id => state =>
  state.alarmState?.alarms.find(al => al.id === id);

export const getLoading = state => state.alarmState?.loading;

export const getAlarms = state => state.alarmState.alarms;

export const getAlarmsCount = state => state.alarmState.alarmsCount;

export const get = state => state.alarmState;

export const getNotifiedUsers = state => state.alarmState.notifiedUsers;

export const getLatestAlarms = state => state.alarmState.latestAlarms;

export const getActiveAlarm = state => state.alarmState?.activeAlarm.alarm;

export const getAlarmParams = state => state.alarmState.alarmParams;
