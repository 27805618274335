import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const Loading = ({ size = 32 }) => (
  <CircularProgress
    color="inherit"
    size={size}
    style={{ opacity: 0.6 }}
    thickness={3}
  />
);

Loading.propTypes = {
  size: PropTypes.number
};

export default Loading;
