import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(({ palette }) => ({
  textField: {
    height: "36px",
    width: "100%",

    "> .MuiInputAdornment-root": {
      color: palette.common.white
    },

    "&.MuiInputBase-root": {
      color: palette.common.white
    }
  },
  input: {
    border: palette.custom.border.primary,
    fontSize: 14,
    height: "36px"
  }
}));
