import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/apis/CameraAPI";

export const setPointOfInterestName = createAsyncThunk(
  "cameras/setPointOfInterestName",
  async ({ deviceId, id, name, position }) => {
    const payload = {
      id,
      name,
      poi: parseInt(position),
      streamId: deviceId
    };

    // @todo Handle 304s when name already exists
    await CameraAPI.upsertPoiName(payload);

    return {
      deviceId,
      poi: payload
    };
  }
);
