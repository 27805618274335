import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useState } from "react";

import { Button, theme } from "@kuva/ui-components";

import { EXPORT_TIME_FORMATS } from "~/constants/csv-export";

import { useExportOptionStyles } from "./styles";

const TimestampFormatRadio = props => (
  <Radio
    size="small"
    sx={{
      color: theme.palette.text.secondary,
      "&.Mui-checked": {
        color: theme.palette.text.icon
      }
    }}
    {...props}
  />
);

export const ExportOption = ({
  timeFormat,
  setTimeFormat,
  handleDownloadToCSV
}) => {
  const { classes } = useExportOptionStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Box className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="export-content"
          id="export-header"
        >
          <Typography className={classes.heading}>Export Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxWidth: 280 }}>
            {handleDownloadToCSV && (
              <>
                <FormControl>
                  <FormLabel id="export-timestamp-format" sx={visuallyHidden}>
                    Export Timestamp Format
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="export-timestamp-format"
                    name="timestamp-format"
                    row
                    value={timeFormat}
                    onChange={event => setTimeFormat(event.target.value)}
                  >
                    <FormControlLabel
                      value={EXPORT_TIME_FORMATS.ISO_8601}
                      control={<TimestampFormatRadio />}
                      label="ISO 8601"
                    />
                    <FormControlLabel
                      value={EXPORT_TIME_FORMATS.EASY_READ}
                      control={<TimestampFormatRadio />}
                      label="Easy Read"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  onClick={() => handleDownloadToCSV(timeFormat)}
                  variant="outlined"
                  sx={{ width: "auto !important", marginTop: 1 }}
                >
                  Export to CSV
                </Button>
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

ExportOption.propTypes = {
  timeFormat: PropTypes.string,
  setTimeFormat: PropTypes.func,
  handleDownloadToCSV: PropTypes.func
};
