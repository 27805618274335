import { Tab as MaterialTab } from "@mui/material";
import PropTypes from "prop-types";

import { useTabStyles } from "./styled-components";

export const Tab = ({ hidden = false, label, ...props }) => {
  const classes = useTabStyles();

  return (
    <MaterialTab
      {...props}
      classes={classes}
      style={{ display: hidden ? "none" : "block" }}
      label={label}
      disableRipple
    />
  );
};

Tab.propTypes = {
  hidden: PropTypes.bool,
  label: PropTypes.string
};

Tab.displayName = "TabbedNavigation.Tab";
