import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { setPoiNames } from "~/actions/cameraActions";
import CameraAPI from "~/apis/CameraAPI";
import { getNormalizePoiNames } from "~/selectors/CameraSelector";

export const usePoiNaming = setPoiList => {
  const dispatch = useDispatch();
  const { deviceId: streamId } = useParams();
  const nameList = useSelector(getNormalizePoiNames, shallowEqual);
  const callPoiNames = async cancelToken => {
    try {
      const res = await CameraAPI.getPoiNames({
        streamIds: streamId,
        cancelToken
      });
      const nameList = res.data;
      concatNameToPoiList(nameList);

      if (res.status === 304) throw new Error(res.error);
      return { status: true };
    } catch (error) {
      console.error(error, "error on getPoiNames");
      return { status: false, message: error.message };
    }
  };

  const getPoiNamesFromList = useCallback(async (streamIds, cancelToken) => {
    try {
      const res = await CameraAPI.getPoiNames({ streamIds, cancelToken });

      if (!res) return await dispatch(setPoiNames([]));

      if (res.status === 304) throw new Error(res.error);
      const nameList = res.data;
      const objList = nameList?.reduce((acc, curr) => {
        // streamId === deviceId for now
        acc[`${curr.streamId}-${curr.poiId}`] = curr;
        return acc;
      }, {});

      await dispatch(setPoiNames(objList));
    } catch (error) {
      console.error(error, "error on getPoiNamesFromList");
      return { status: false, message: error.message };
    }
  }, []);

  const upsertPoiName = async poiObj => {
    try {
      const { position: poi, id, name } = poiObj;
      const res = await CameraAPI.upsertPoiName({ streamId, poi, id, name });
      const newId = res.data.id;

      setPoiList(poiList =>
        poiList.map(p => {
          if (p.id === newId || poi === p.position)
            return { ...p, name, poi, id: newId };
          return p;
        })
      );

      return { status: true };
    } catch (error) {
      let message = error.message;
      if (message.includes("304"))
        message = "Name already used for this stream";

      return { status: false, message };
    }
  };

  const deletePoiName = async ({ poiNameToDelete, hasChanged }) => {
    try {
      const { position: poi, id } = poiNameToDelete;
      if (!id) return false;

      await CameraAPI.deletePoiName({ poi, id });
      if (!hasChanged) {
        setPoiList(poiList =>
          poiList.map(p => {
            if (p.id === id) {
              delete p.id;
              delete p.name;
            }
            return p;
          })
        );
      }

      return { status: true };
    } catch (error) {
      console.error(error, "error on deletePoiName");
      return { status: false, message: error.message };
    }
  };

  const concatNameToPoiList = nameList =>
    setPoiList(poiList =>
      poiList.map(p => {
        const nameObj = nameList.find(n => p.position === Number(n.poiId));
        if (nameObj) return { ...p, name: nameObj.name, id: nameObj.id };
        return p;
      })
    );

  return {
    callPoiNames,
    upsertPoiName,
    deletePoiName,
    concatNameToPoiList,
    getPoiNamesFromList,
    nameList
  };
};
