import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

import { TooltipHint } from "../TooltipHint";

const Container = styled("div", {
  shouldForwardProp: props => props !== "small" && props !== "textTransform"
})(({ theme, small, textTransform }) => ({
  color: small ? theme.palette.action.disabled : theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  fontSize: small ? 14 : 16,
  fontWeight: 300,
  opacity: 0.85,
  textTransform
}));

export const SectionTitle = ({
  children,
  TooltipProps,
  small,
  textTransform = "capitalize"
}) => {
  return (
    <Container small={small} textTransform={textTransform}>
      {children}
      {TooltipProps && (
        <Box ml={small}>
          <TooltipHint
            outlinedIcon={small}
            placement={small ? "bottom" : "right-start"}
            {...TooltipProps}
          />
        </Box>
      )}
    </Container>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node,
  TooltipProps: PropTypes.object,
  small: PropTypes.bool,
  textTransform: PropTypes.string
};
