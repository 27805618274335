/**
 * Calculates the position of an element in pixels from a given number of
 * degrees. Assumes that 0º is at the center.
 *
 * @param {number} width - The width of the parent element in pixels.
 * @param {number} degrees - The number of degrees to convert to pixels.
 *
 * @returns {number} - The calculated position in pixels.
 */
export const calculatePositionFromDegrees = (width, degrees) => {
  const width180 = width * 0.5;
  const degreesPerPixel = 360 / width;

  return width180 + degrees / degreesPerPixel;
};

/**
 * Calculates the number of degrees from a given position in pixels.
 *
 * @param {number} width - The width of the parent element in pixels.
 * @param {number} position - The position in pixels to convert to degrees.
 *
 * @returns {number} - The calculated number of degrees.
 */
export const calculateDegreesFromPosition = (width, position) => {
  const degreesPerPixel = 360 / width;
  return position * degreesPerPixel;
};
