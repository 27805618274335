import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";

import { SearchBar } from "../../search-bar";
import { multiSelectPropsType } from "../types";

import { useStyles } from "./styles";

export const MultiSelectPopoverContent = ({
  title,
  searchPlaceholder,
  multiSelectProps
}) => {
  const classes = useStyles();

  const {
    onSelect,
    onClearAll,
    onSelectAll,
    isSearchable,
    selectedItems,
    hasSelected,
    otherItems,
    hasOtherItems,
    handleSearch,
    showClearAll
  } = multiSelectProps;

  const renderItems = itemsToRender => (
    <FormGroup>
      {itemsToRender?.map(({ value, label, selected }) => (
        <FormControlLabel
          className={classes.formControlLabel}
          key={value}
          value={value}
          control={<Checkbox checked={selected} />}
          label={label}
          onChange={onSelect}
        />
      ))}
    </FormGroup>
  );

  const clearAllBtn = (
    <Button size="small" onClick={onClearAll} className={classes.allBtn}>
      clear all
    </Button>
  );

  const selectAllBtn = (
    <Button size="small" onClick={onSelectAll} className={classes.allBtn}>
      select all
    </Button>
  );

  return (
    <>
      <Typography variant="subtitle2">{title}</Typography>
      {isSearchable && hasSelected && (
        <>
          <Typography variant="subtitle2">Selected</Typography>
          {clearAllBtn}
          {renderItems(selectedItems)}
        </>
      )}
      {isSearchable && (
        <>
          <Typography variant="subtitle2">Available</Typography>
          <SearchBar
            className={classes.searchBar}
            requestSearch={searchValue => handleSearch(searchValue)}
            placeholder={searchPlaceholder}
          />
        </>
      )}
      {hasOtherItems && (
        <>
          {showClearAll ? clearAllBtn : selectAllBtn}
          {renderItems(otherItems)}
        </>
      )}
    </>
  );
};

MultiSelectPopoverContent.propTypes = {
  title: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  multiSelectProps: multiSelectPropsType
};
